import React from 'react'
import { withRouter, NavLink as Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import Logo from 'components/Logo'

import Navigation from './Navigation'

import style from './style.module.scss'

Header.propTypes = {
  showNav: PropTypes.bool,
}

Header.defaultProps = {
  showNav: true,
}

function Header(props) {
  const { showNav, location } = props
  const isInSignupFlow =
    location.pathname.startsWith('/signup') ||
    location.pathname.startsWith('/signin')

  return (
    <header className={style.wrapper}>
      <div className={style.inner}>
        <Link to='/'>
          <Logo />
        </Link>
        {showNav && (
          <div className={style.nav}>
            <Navigation
              className={style.menu}
              isInSignupFlow={isInSignupFlow}
            />
            {!isInSignupFlow && (
              <React.Fragment>
                <Link to='/signup' className={style.primaryLinkDesktop}>
                  Try for free
                </Link>
                <Link to='/signup' className={style.primaryLinkMobile}>
                  Free trial
                </Link>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </header>
  )
}

export default withRouter(Header)
