import React from 'react'
import PropTypes from 'prop-types'

import Label from '../Label'

StateLabel.propTypes = {
  country: PropTypes.string,
}

function StateLabel(props) {
  const { country, ...rest } = props

  let text

  if (country === 'CA') {
    text = 'Province'
  } else if (country === 'US' || !country) {
    text = `State`
  } else {
    text = `Region`
  }

  return <Label {...rest}>{text}</Label>
}

export default StateLabel
