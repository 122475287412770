import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'
import Text from 'components/Text'

import style from './style.module.scss'

Footer.propTypes = { className: PropTypes.string }

function Footer(props) {
  const { className } = props
  return (
    <footer className={classNames(style.wrapper, className)}>
      <div className={style.copyright}>
        &copy;{new Date().getFullYear()} Pet Notify&reg;
      </div>
      <Link
        className={style.link}
        href='https://www.petnotify.com/privacy-policy'
      >
        Privacy Policy
      </Link>
      <Link
        className={style.link}
        href='https://www.petnotify.com/terms-of-use'
      >
        Terms of Use
      </Link>
    </footer>
  )
}

export default Footer
