import React, { useContext } from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

Notification.propTypes = {
  isShowingNotification: PropTypes.bool,
  onClearNotification: PropTypes.func,
  notification: PropTypes.shape({ message: PropTypes.string }),
}

function Notification(props) {
  const { isShowingNotification, onClearNotification, notification } = props
  const { message } = notification || {}
  const { color } = useContext(ColorContext)

  return (
    <div className={style.wrapper}>
      <CSSTransition
        in={isShowingNotification}
        timeout={300}
        classNames={{
          enter: style.wrapperEnter,
          enterActive: style.wrapperEnterActive,
          exit: style.wrapperExit,
          exitActive: style.wrapperExitActive,
        }}
        onExited={onClearNotification}
      >
        <div className={classNames(style.notification, style[color])}>
          {message}
        </div>
      </CSSTransition>
    </div>
  )
}

export default Notification
