import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Text.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
}

Text.defaultProps = {
  variant: `secondary`,
}

function Text(props) {
  const { children, className, variant } = props
  return (
    <p className={classNames(style.wrapper, style[variant], className)}>
      {children}
    </p>
  )
}

export default React.memo(Text)
