import React from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text'
import style from './style.module.scss'

Note.propTypes = {
  children: PropTypes.string,
}

function Note(props) {
  const { children } = props

  return (
    <Text variant='note2' className={style.wrapper}>
      {children}
    </Text>
  )
}

export default Note
