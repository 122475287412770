import React from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text'
import style from './style.module.scss'

Empty.propTypes = {
  children: PropTypes.node,
}

function Empty(props) {
  const { children } = props

  return (
    <Text variant='empty' className={style.wrapper}>
      {children}
    </Text>
  )
}

export default Empty
