import React from 'react'
import PropTypes from 'prop-types'

import { Button, ErrorText, Form, Input, Label } from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import style from './style.module.scss'

InsuranceForm.propTypes = {
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
}

function InsuranceForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values

  async function handleSubmit(data) {
    const { provider, policyNumber } = data

    if (!provider || provider === '') {
      throw new Error(`Insurance Provider required`)
    }

    if (!policyNumber || policyNumber === '') {
      throw new Error(`Policy Number required`)
    }

    await onSubmit(data)
  }

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} Insurance</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='insurance-provider'>Insurance Provider</Label>
            <Input
              name='provider'
              value={values.provider}
              onChange={onChange}
              id='insurance-provider'
            />

            <Label htmlFor='insurance-policyNumber'>Policy Number</Label>
            <Input
              name='policyNumber'
              value={values.policyNumber}
              onChange={onChange}
              id='insurance-policyNumber'
            />

            <Label htmlFor='insurance-policyType'>Policy Type</Label>
            <Input
              placeholder='Optional'
              name='policyType'
              value={values.policyType}
              onChange={onChange}
              id='insurance-policyType'
            />

            <Label htmlFor='insurance-providerNumber'>
              Provider Phone Number
            </Label>
            <Input
              placeholder='Optional'
              name='providerNumber'
              value={values.providerNumber}
              onChange={onChange}
              id='insurance-providerNumber'
            />

            <Label htmlFor='insurance-website'>Website</Label>
            <Input
              placeholder='Optional'
              name='website'
              value={values.website}
              onChange={onChange}
              id='insurance-website'
            />

            <Label htmlFor='insurance-login'>Login</Label>
            <Input
              placeholder='Optional'
              name='login'
              value={values.login}
              onChange={onChange}
              id='insurance-login'
            />

            <Label htmlFor='insurance-password'>Password</Label>
            <Input
              placeholder='Optional'
              name='password'
              value={values.password}
              onChange={onChange}
              id='insurance-password'
            />

            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add Insurance`}
            </Button>
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default InsuranceForm
