import useUser from 'UserApp/hooks/useUser';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import logo from './images/logo.svg';
import style from './style.module.scss';
import classNames from 'classnames';
import { API_URL } from 'config';
import useNotification from 'UserApp/hooks/useNotification';
import Checkbox from 'sharedComponents/Checkbox';
import Error from 'sharedComponents/Error';
import Loader from 'sharedComponents/Loader';

const UpdatedTerms = () => {
  const { setNotification } = useNotification();
  const [userData, setUserData] = useState({});
    const { user } = useUser();
    const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setUserData(user);
  }, [user]);
    
    // console.log(`USERDATA :: `,userData)

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      // Check if the checkbox is ticked
      if (!isChecked) {
        throw new setError('Please verify that you understand the Terms of Use & Privacy Policy.');
        }
        
        const response = await fetch(`${API_URL}/api/users/updated-terms`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data:{userId: userData?.id, policyAcceptance: isChecked} }),
          });
  
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const data = await response.json();
      
      // Simulating API call or other logic here
      setIsLoading(false);
      setNotification('Privacy policy accepted');
      history.push('/');

    } catch (error) {
      setIsLoading(false);
        console.error(error);
        throw error
    //   setNotification('Failed to agree updated terms');
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (error) {
      setError(null); // Clear error when user interacts with the checkbox
    }
  };

  return (
    <div>
      <img
        src={logo}
        className={classNames(style.wrapper)}
        alt='Pet Notify'
        height='37'
      />
      <h2 className={classNames(style.text)}>Review Terms of Use & Privacy Policy</h2>
      <p className={classNames(style.message)}>
        Pet Notify has recently updated our <a className={classNames(style.anchor)} href="https://www.petnotify.com/terms-of-use">Terms of Use</a> and <a className={classNames(style.anchor)} href="https://www.petnotify.com/privacy-policy">Privacy Policy</a>. These updates reflect our commitment to transparency and protecting your privacy.<br />
      </p>
      <p className={classNames(style.message)}>
        To continue using Pet Notify’s services, please review the updated documents and agree to the new terms below.      
      </p>
      <div className={classNames(style.checkbox)}>
        <input
          type="checkbox"
          id="terms-agree"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="terms-agree">
          I have read and agree to the Terms of Use and Privacy Policy of Pet Notify.
        </label>
        </div>
        <div className={classNames(style.error)}>
            {error && <Error>{error}</Error>}
        </div>
        <div className={classNames(style.container)}>
        {isLoading ? <Loader/> : (<button
          className={classNames(style.button2)}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Submit
        </button>)}      
      </div>
    </div>
  );
};

export default UpdatedTerms;
