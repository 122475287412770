import React from 'react'
import { Redirect } from 'react-router'

import useCaptureSignupParams from 'UserApp/hooks/useCaptureSignupParams'
import Loader from 'sharedComponents/Loader'

function RedemptionScreen() {
  const isCapturingSignupParams = useCaptureSignupParams(location.search)

  if (isCapturingSignupParams) {
    return <Loader />
  }

  return <Redirect to='/signup/premium' />
}

export default RedemptionScreen
