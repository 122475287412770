import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import numeral from 'numeral'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import api from 'services/pet-notify-api'
import HeaderOne from 'sharedComponents/HeaderOne'
import Form from 'sharedComponents/Form'
import Button from 'sharedComponents/Button'
import Label from 'sharedComponents/Label'
import Input from 'sharedComponents/Input'
import FieldGroup from 'sharedComponents/FieldGroup'
import RadioButton from 'sharedComponents/RadioButton'
import StripeInput from 'sharedComponents/StripeInput'
import PaymentMethodIcons from 'sharedComponents/PaymentMethodIcons'
import ErrorText from 'sharedComponents/Error'
import Loader from 'sharedComponents/Loader'
import Text from 'sharedComponents/Text'
import countries from 'lib/countries'
import Dropdown from 'sharedComponents/Dropdown'
import { upgrade } from 'UserApp/store/user/actions'
import { set as setNotification } from 'store/notification/actions'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'
import { Checkbox, StateLabel } from 'components/Forms'
import style from './style.module.scss'
import PromoCode from '../../components/InlinePromoCode'

type Props = {
  onClose: () => void
  petCount: number
}

function UpgradeToPremium(props: Props) {
  const dispatch = useDispatch()
  const promoCode = useSelector(
    (state) => state.promoCode && state.promoCode.code,
  )
  const history = useHistory()
  const [pricing, setPricing] = useState()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const { petCount } = props

  useEffect(() => {
    if (petCount === 0) {
      dispatch(
        setNotification(
          `You must add a pet to your Pet Vault before upgrading to premium.`,
        ),
      )
      history.push('/')
      return
    }

    api.orders.pricing({ promoCode }).then((result) => {
      setPricing(result)
      setIsLoading(false)
    })
  }, [petCount, promoCode])

  async function handleSubmit(values: UpgradePlan) {
    const { zip, subscriptionPlan, policyAcceptance } = values

    if (!zip || zip === '') {
      throw new Error('Billing ZIP code required')
    }

    if (!policyAcceptance) {
      throw new Error('Please verify you understand the User Agreement and Privacy Policy')
    }

    setIsProcessing(true)

    const cardNumberElement = elements.getElement(CardNumberElement)

    const { token, error } = await stripe.createToken(cardNumberElement, {
      address_zip: zip,
    })

    if (error) {
      setIsProcessing(false)
      throw error
    }

    try {
      await dispatch(
        upgrade({
          token: token.id,
          isAnnualPlan: subscriptionPlan === 'year',
          promoCode,
          stripeSubscriptionPlan:
            pricing.subscriptionPlans[subscriptionPlan].id,
          ...values,
          policyAcceptance,
        }),
      )
      setIsProcessing(false)
      history.push('/settings/upgrade/success')
    } catch (e) {
      setIsProcessing(false)
      throw e
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <HeaderOne>Upgrade to premium</HeaderOne>
      <Text className={style.text}>
        Premium includes Intellitag protection for all household pets
      </Text>
      <Form<UpgradePlan>
        initialValues={{ subscriptionPlan: 'month', country: 'US' }}
        onSubmit={handleSubmit}
      >
        {({ onChange, values, error }) => {
          const tax =
            values.state === 'WA'
              ? pricing.subscriptionPlans[values.subscriptionPlan].amount * 0.1
              : 0
          return (
            <>
              <div className={style.plans}>
                <RadioButton
                  id='subscriptionPlan-year'
                  name='subscriptionPlan'
                  value='year'
                  checked={values.subscriptionPlan === 'year'}
                  onChange={onChange}
                  variant='small'
                  className={style.radioButton}
                >
                  <strong>
                    {numeral(pricing.subscriptionPlans.year.amount / 12).format(
                      '$0.00',
                    )}
                    /mo
                  </strong>{' '}
                  <div>
                    paid annually (save{' '}
                    {pricing.subscriptionPlans.year.annualDiscountPercentOff ||
                      45}
                    %)
                  </div>
                </RadioButton>
                <RadioButton
                  id='subscriptionPlan-month'
                  name='subscriptionPlan'
                  value='month'
                  checked={values.subscriptionPlan === 'month'}
                  onChange={onChange}
                  variant='small'
                  className={style.radioButton}
                >
                  <strong>
                    {numeral(pricing.subscriptionPlans.month.amount).format(
                      '$0.00',
                    )}
                    /mo
                  </strong>{' '}
                  <div>paid monthly</div>
                </RadioButton>
              </div>
              <Label>
                Credit card number
                <StripeInput
                  component={CardNumberElement}
                  onChange={onChange}
                />
              </Label>

              <FieldGroup>
                <div>
                  <Label>
                    Expiration (mm/yy)
                    <StripeInput
                      component={CardExpiryElement}
                      onChange={onChange}
                    />
                  </Label>
                </div>
                <div>
                  <Label>
                    Security code (CVV)
                    <StripeInput
                      component={CardCvcElement}
                      onChange={onChange}
                    />
                  </Label>
                </div>
              </FieldGroup>
              <FieldGroup>
                <div>
                  <Label htmlFor='country'>Country</Label>
                  <Dropdown
                    name='country'
                    options={countries}
                    id='country'
                    value={values.country}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <StateLabel
                    htmlFor='address-state'
                    country={values.country}
                  />
                  {values.country === 'US' || values.country === 'CA' ? (
                    <Dropdown
                      includeBlank
                      name='state'
                      options={
                        values.country === 'US' ? usStates : canadianProvinces
                      }
                      id='address-state'
                      value={values.state}
                      onChange={onChange}
                    />
                  ) : (
                    <Input
                      type='text'
                      name='state'
                      id='address-state'
                      value={values.state}
                      onChange={onChange}
                    />
                  )}
                </div>
              </FieldGroup>
              <FieldGroup>
                <div>
                  <Label htmlFor='zip'>
                    {values.country === 'US' ? `Zip code` : `Postal Code`}
                  </Label>
                  <Input
                    name='zip'
                    value={values.zip}
                    onChange={onChange}
                    id='zip'
                  />
                </div>
              </FieldGroup>
              <div className={style.orderSection}>
                <dl className={style.orderLineItem}>
                  <dt className={style.orderItem}>
                    Premium subscription ({values.subscriptionPlan}ly)
                  </dt>
                  <dd className={style.orderItemPrice}>
                    {numeral(
                      pricing.subscriptionPlans[values.subscriptionPlan]
                        .preDiscountedAmount,
                    ).format('$0.00')}
                  </dd>
                </dl>
                {pricing.subscriptionPlans[values.subscriptionPlan]
                  .annualDiscount && (
                  <dl className={style.orderLineItem}>
                    <dt className={style.orderItem}>Discount</dt>
                    <dd className={style.orderItemPrice}>
                      {numeral(
                        pricing.subscriptionPlans[values.subscriptionPlan]
                          .annualDiscount,
                      ).format('$0.00')}
                    </dd>
                  </dl>
                )}
                <dl className={style.orderLineItem}>
                  <dt className={style.orderItem}>Tax</dt>
                  <dd className={style.orderItemPrice}>
                    {numeral(tax).format('$0.00')}
                  </dd>
                </dl>
                <dl
                  className={classNames(style.orderLineItem, style.billedToday)}
                >
                  <dt className={style.orderItem}>Total billed today</dt>
                  <dd className={style.orderItemPrice}>
                    {numeral(
                      pricing.subscriptionPlans[values.subscriptionPlan]
                        .amount + tax,
                    ).format('$0.00')}
                  </dd>
                </dl>
              </div>

              <PromoCode
                isAnnualPlanSelected={values.subscriptionPlan === 'year'}
              />
              <Text className={style.text}>
                All prices are in USD. You will be charged{' '}
                {values.subscriptionPlan === 'month' ? (
                  <>
                    {numeral(
                      pricing.subscriptionPlans.month.amount + tax,
                    ).format('$0.00')}{' '}
                    every 30 days
                  </>
                ) : (
                  <>
                    {numeral(
                      pricing.subscriptionPlans.year.amount + tax,
                    ).format('$0.00')}{' '}
                    annually
                  </>
                )}{' '}
                while your subscription is active. You may downgrade to our
                "Free" plan or cancel at anytime.
              </Text>
              <PaymentMethodIcons />

              {/* <Text className={style.bottomText}>Terms of Use: <a href="https://www.petnotify.com/terms-of-use">https://www.petnotify.com/terms-of-use</a></Text>   */}
              {/* <Text className={style.bottomText}>Privacy Policy: <a href="https://www.petnotify.com/privacy-policy"> https://www.petnotify.com/privacy-policy</a></Text>   */}
              <div className={style.policyContainer}>
                <Checkbox
                  name="policyAcceptance"
                  value={values.policyAcceptance}
                  onChange={onChange}
                  id="signup-policy-acceptance"
                />
                <Text className={style.linkText}>
                  By signing up I agree to Pet Notify's{' '}
                  <a href="https://www.petnotify.com/terms-of-use" className={style.link}>
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href="https://www.petnotify.com/privacy-policy" className={style.link}>
                    Privacy Policy
                  </a>
                </Text>
              </div>


              {error && <ErrorText>{error}</ErrorText>}

              {isProcessing ? <Loader /> : <Button>Upgrade to premium</Button>}
            </>
          )
        }}
      </Form>
    </>
  )
}

export default UpgradeToPremium
