import React from 'react'

import HeaderThree from 'components/Headers/HeaderThree'
import Layout from 'UserApp/components/Layout'

import AlarmBanner from 'UserApp/containers/Alarm/Banner'
import AlarmHistory from 'UserApp/containers/Alarm/History'

import style from './style.module.scss'

function AlarmGoingOffScreen() {
  return (
    <Layout banner={() => <AlarmBanner />}>
      <HeaderThree className={style.subHeader}>Alarm history</HeaderThree>
      <AlarmHistory />
    </Layout>
  )
}

export default AlarmGoingOffScreen
