import React from 'react'
import PropTypes from 'prop-types'

import Label from '../Label'
import style from './style.module.scss'

Checkbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  value: false,
}

function Checkbox(props) {
  const { name, value, onChange, label, id } = props

  function handleChange(event) {
    const { name, checked } = event.target
    onChange({ name, value: checked })
  }

  return (
    <div className={style.wrapper}>
      <input
        className={style.checkbox}
        type='checkbox'
        name={name}
        id={id}
        checked={value}
        onChange={handleChange}
      />
      <Label htmlFor={id} className={style.label}>
        {label}
      </Label>
    </div>
  )
}

export default Checkbox
