import React from 'react'

import HeaderOne from 'sharedComponents/HeaderOne'
import Button from 'sharedComponents/Button'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

type Props = {
  // isMobile: boolean
  setPaymentRedirect: (redirect: boolean) => void
}

function Success(props: Props) {
  const { isMobile, setPaymentRedirect } = props

  const handleClose = () => {
    setPaymentRedirect(true)
  }

  return (
    <>
      <HeaderOne className={style.header}>
        Welcome to Pet Notify premium.
      </HeaderOne>

      <Text className={style.text}>
        Thank you for choosing Pet Notify premium. You can now continue to
        activate your pet’s Intellitag or order a new one.
      </Text>

      <div className={style.buttons}>
        <Button className={style.button} to='/' variant='hollow'>
          Back to Pet Vault
        </Button>

        <Button
          className={style.button}
          // to='/pets/activate-intellitag/lost-pet-instructions'
          onClick={() => handleClose()}
        >
          Continue Intellitag activation
        </Button>
      </div>
    </>
  )
}

export default Success
