import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import Layout from 'sharedComponents/Layout/Split'
import Loader from 'sharedComponents/Loader'
import Form from 'sharedComponents/Form'
import Button from 'sharedComponents/Button'
import Label from 'sharedComponents/Label'
import Checkbox from 'sharedComponents/Checkbox'
import Error from 'sharedComponents/Error'
import Input from 'sharedComponents/Input'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import MobileHeaderImage from '../../MobileHeaderImage'
import style from './style.module.scss'
import { RECAPTCHA_SITE_KEY } from 'config'
import mixPanel, { EVENTS } from 'services/mix-panel'
import { Dropdown, StateLabel } from 'components/Forms'
import countries from 'lib/countries'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'

type Props = {
  onSubmit: (userInfo: SignupUserInfo) => Promise<void>
  isLoading: boolean
  initialValues?: SignupUserInfo
}

function SignupUserInfoScreen(props: Props) {
  const { onSubmit, isLoading, initialValues, onCaptcha } = props
  return (
    <>
      <Layout>
        <MobileHeaderImage step={1} />
        <HeaderOne className={style.title}>Welcome to Pet Notify!</HeaderOne>
        <Text className={style.text}>Create your free account.</Text>
        <Form<SignupUserInfo>
          onSubmit={onSubmit}
          initialValues={initialValues}
          className={style.form}
        >
          {({ onChange, values, error }) =>
            isLoading ? (
              <Loader />
            ) : (
              <>
                <Label htmlFor='signup-first-name'>First Name</Label>
                <Input
                  type='text'
                  name='firstName'
                  id='signup-first-name'
                  value={values.firstName}
                  onChange={onChange}
                />
                <Label htmlFor='signup-last-name'>Last Name</Label>
                <Input
                  type='text'
                  name='lastName'
                  id='signup-last-name'
                  value={values.lastName}
                  onChange={onChange}
                />

                <Label htmlFor='signup-email'>Email</Label>
                <Input
                  type='email'
                  name='email'
                  id='signup-email'
                  value={values.email}
                  onChange={onChange}
                />

                <Label htmlFor='signup-confirm-email'>Confirm email</Label>
                <Input
                  type='email'
                  name='confirmEmail'
                  id='signup-confirm-email'
                  value={values.confirmEmail}
                  onChange={onChange}
                  />
                  
                <Label htmlFor='address-country'>Country</Label>
                <Dropdown
                  name='country'
                  options={[{ label: 'Select country', value: '' }, ...countries]}
                  value={values.country || ''}
                  onChange={onChange}
                />

                <StateLabel htmlFor='address-state' country={values.country} />
                {values.country === 'US' || values.country === 'CA' ? (
                  <Dropdown
                    includeBlank
                    name='state'
                    options={values.country === 'US' ? usStates : canadianProvinces}
                    value={values.state}
                    onChange={onChange}
                  />
                ) : (
                  <Input
                    type='text'
                    name='state'
                    id='address-state'
                    value={values.state}
                    onChange={onChange}
                  />
                )}

                <Label htmlFor='address-city'>City</Label>
                <Input
                  name='city'
                  value={values.city}
                  onChange={onChange}
                  id='address-city'
                />

                <Label htmlFor='address-zip'>Zip</Label>
                <Input
                  type='text'
                  name='zip'
                  id='address-zip'
                  value={values.zip}
                  onChange={onChange}
                />
                {/* <Checkbox
                  name='agree'
                  value={values.agree}
                  onChange={onChange}
                  label='I understand Pet Notify is only available in the US and Canada'
                  id='signup-agree'
                /> */}

                <div className={style.captcha}>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onCaptcha}
                  />
                </div>

                {/* <Text className={style.bottomText}>Terms of Use: <a href="https://www.petnotify.com/terms-of-use">https://www.petnotify.com/terms-of-use</a></Text>   */}
                {/* <Text className={style.bottomText}>Privacy Policy: <a href="https://www.petnotify.com/privacy-policy"> https://www.petnotify.com/privacy-policy</a></Text>   */}
                <div className={style.policyContainer}>  
                  <Checkbox
                    name='policyAcceptance'
                    value={values.policyAcceptance}
                    onChange={onChange}
                    label=''
                    id='signup-policy-acceptance'
                  />
                  <Text className={style.linkText}>
                  By signing up I agree to Pet Notify's{' '}
                  <a href="https://www.petnotify.com/terms-of-use" className={style.link}>
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a href="https://www.petnotify.com/privacy-policy" className={style.link}>
                    Privacy Policy
                  </a>
                  </Text>
                </div>

                <Checkbox
                  name='smsPermission'
                  value={values.smsPermission}
                  onChange={onChange}
                  label='By signing up, you agree to receive SMS alerts related to your Pet Notify account and services. You can opt out at any time by replying STOP.'
                  id='singup-sms-permission'
                />
                  
                {error && <Error>{error}</Error>}

                <Button
                  type='submit'
                  onClick={() =>
                    mixPanel.track(EVENTS.FREE_CREATE_ACCOUNT_CLICK)
                  }
                >
                  Create account
                </Button>
              </>
            )
          }
        </Form>
      </Layout>
      <img
        src='https://pubads.g.doubleclick.net/activity;xsp=4691457;ord=[session id]?'
        width='1'
        height='1'
        border='0'
      />
    </>
  )
}

export default SignupUserInfoScreen
