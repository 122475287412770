import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Notification from 'containers/Notification'

import Header from '../Header'
import Footer from './Footer'

FullLayout.propTypes = {
  children: PropTypes.node,
  showNav: PropTypes.bool,
}

function FullLayout(props) {
  const { children, showNav } = props

  return (
    <React.Fragment>
      <Notification />
      <Header showNav={false} />
      <div className={style.wrapper}>{children}</div>

      <Footer />
    </React.Fragment>
  )
}

export default FullLayout
