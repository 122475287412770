import React from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

type Props = {
  code: string
  discounts: string
  isDisabled: boolean
}

function PromoCodeDetails(props: Props) {
  const { code, discounts, isDisabled } = props

  return (
    <div
      className={classNames(style.wrapper, { [style.isDisabled]: isDisabled })}
    >
      <div aria-label='promo-code'>Promo: {code}</div>
      <div aria-label='promo-code-discount'>{discounts}</div>
    </div>
  )
}

export default PromoCodeDetails
