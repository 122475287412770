import { APPLY_PROMO_CODE, REMOVE } from './actions'
import { COMPLETE_SIGNUP } from '../signup/actions'
import { ORDER_SUBMITTED } from '../intellitagOrder/actions'

const localVersion = window.localStorage.getItem('promoCode')

export default function reducer(
  state = localVersion ? JSON.parse(localVersion) : null,
  action,
) {
  const updatedState = ((action) => {
    const { payload, type } = action

    switch (type) {
      case APPLY_PROMO_CODE:
        return {
          affiliate: payload,
          code: payload.promoCode,
        }
      case REMOVE:
      case ORDER_SUBMITTED:
      case COMPLETE_SIGNUP:
        return null
      default:
        return state
    }
  })(action)

  window.localStorage.setItem('promoCode', JSON.stringify(updatedState))
  return updatedState
}
