type SizeOption = {
  value: string
  label: string
  subTitle: string
}

const sizeOptions: SizeOption[] = [
  { value: 'small', label: 'Small', subTitle: '.95”' },
  { value: 'medium', label: 'Medium', subTitle: '1.10”' },
  { value: 'large', label: 'Large', subTitle: '1.25”' },
]

export default sizeOptions
