import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

Dropdown.propTypes = {
  className: PropTypes.string,
  includeBlank: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Dropdown.defaultProps = {
  value: '',
}

function Dropdown(props) {
  const {
    className,
    includeBlank,
    name,
    onChange,
    options,
    value,
    placeholder,
    id,
  } = props

  useEffect(() => {
    if (!value) {
      onChange({
        name,
        value: options.find((option) => !option.disabled).value,
      })
    }
  }, [])

  const { color } = useContext(ColorContext)

  const handleChange = (event) => {
    onChange({ name, value: event.target.value })
  }

  return (
    <select
      className={classNames(style.wrapper, className, style[color])}
      name={name}
      value={value || ''}
      onChange={handleChange}
      id={id}
    >
      {includeBlank && <option />}
      {options.map((option) => {
        if (typeof option === 'string') {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        } else {
          const { value, label, disabled } = option

          return (
            <option key={value} value={value} disabled={disabled}>
              {label}
            </option>
          )
        }
      })}
    </select>
  )
}

export default React.memo(Dropdown)
