import React, { useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  mustMatch: PropTypes.string,
  className: PropTypes.string,
  invalid: PropTypes.boolean,
}

Input.defaultProps = {
  type: 'text',
  value: '',
}

function Input(props) {
  const { value, onChange, mustMatch, className, invalid, ...rest } = props
  const { color } = useContext(ColorContext)

  function handleChange(event) {
    const { name, value, validity, dataset } = event.target
    const isValid = validity.valid && (!mustMatch || mustMatch === value)

    onChange({ name, value, isValid, dataset })
  }

  return (
    <input
      className={classNames(style.wrapper, className, style[color], {
        [style.invalid]: invalid,
      })}
      value={value || ''}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default React.memo(Input)
