import React from 'react'

import Link from 'sharedComponents/Link'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

function Footer() {
  return (
    <footer className={style.wrapper}>
      <Text className={style.copyright}>
        &copy;{new Date().getFullYear()} Pet Notify&reg;
      </Text>
      <Link
        className={style.link}
        href='https://www.petnotify.com/privacy-policy'
      >
        Privacy Policy
      </Link>
      <Link
        className={style.link}
        href='https://www.petnotify.com/terms-of-use'
      >
        Terms of Use
      </Link>
    </footer>
  )
}

export default Footer
