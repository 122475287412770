import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ContactType } from 'types'

import { fetchAll } from 'UserApp/store/contacts/actions'
import { selectAllEmergencyAndApproved } from 'UserApp/store/contacts/selectors'
import AlarmContacts from 'UserApp/components/Alarm/Contacts'

class AlarmContactsContainer extends React.PureComponent {
  static propTypes = {
    fetchAll: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
  }

  state = {
    isLoading: true,
  }

  async componentDidMount() {
    const { fetchAll } = this.props

    await fetchAll()

    this.setState({ isLoading: false })
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
  })

  render() {
    const props = this.generateProps()

    return <AlarmContacts {...props} />
  }
}

export default connect(
  (state) => ({
    contacts: selectAllEmergencyAndApproved(state),
  }),
  { fetchAll },
)(AlarmContactsContainer)
