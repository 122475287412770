import React, { useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import HeaderOne from 'components/Headers/HeaderOne'
import Text from 'components/Text'
import { Button } from 'components/Forms'
import style from './style.module.scss'
import Checkbox from 'sharedComponents/Checkbox'
import Error from 'sharedComponents/Error'
import useNotification from 'UserApp/hooks/useNotification'
import api from 'services/pet-notify-api'

// SmsPermission.propTypes = {
//   onConfirm: PropTypes.func,
// }

function SmsPermission(props) {
  // const { onConfirm  } = props
  const [smsPermission, setSmsPermission] = useState(false);
  const [error, setError] = useState('');
  const { setNotification } = useNotification()
  const history = useHistory()

  const handleChange = ({value}) => {
    setSmsPermission(value);
    if (error) {
      setError(''); // Clear the error when checkbox value changes
    }
  };

  const handleSubmit = async () => {
    if (!smsPermission) {
      setError('You must agree to receive SMS alerts to proceed.');
      return;
    }
    try{
      await api.users.allowSmsConsent({smsPermission})
      history.push('/')
      setNotification('Consent to SMS alerts submitted')
    }catch(error){
      // console.error('Error submitting SMS consent:', error);
      setError('Failed to submit SMS consent. Please try again.');
    }
  };

  return (
    <div className={classNames(style.wrapper, style.expired)}>
      <HeaderOne className={style.header}>Consent to SMS Alerts</HeaderOne>

      <Text className={style.text}>
      Hello it's Pet Notify! We are requesting your approval to receive SMS alerts to keep your pet(s) safe and notify you if your lost pet is found.
      </Text>

      <Checkbox
        name='smsPermission'
        value={smsPermission}
        onChange={handleChange}
        label='I agree'
        id='singup-sms-permission'
      />
                  
      {error && <Error>{error}</Error>}

      <Button onClick={handleSubmit}  variant='secondary' className={style.button}>
        Submit
      </Button>
    </div>
  )
}

export default SmsPermission
