export const APPLY_PROMO_CODE = 'PROMO_CODE/APPLY'
export const REMOVE = 'PROMO_CODE/REMOVE'

export function applyPromoCode(promoCode: string) {
  return async (dispatch, _getState, api) => {
    const affiliate = await api.affiliates.fetch(promoCode)

    dispatch({
      type: APPLY_PROMO_CODE,
      payload: affiliate,
    })

    return affiliate
  }
}

export function removePromoCode() {
  return {
    type: REMOVE,
  }
}
