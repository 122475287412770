import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'

type Props = React.ComponentPropsWithRef<'textarea'>

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ className, rows = 5, ...rest }: Props, ref) => {
    return (
      <textarea
        className={classNames(style.wrapper, className)}
        rows={rows}
        {...rest}
        ref={ref}
      />
    )
  },
)

export default TextArea
