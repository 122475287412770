import React, { useState } from 'react'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js'
import { parseISO, format } from 'date-fns'
import numeral from 'numeral'
import { pascalize } from 'humps'

import Layout from 'sharedComponents/Layout/Split'
import Loader from 'sharedComponents/Loader'
import Error from 'sharedComponents/Error'
import Button from 'sharedComponents/Button'
import Input from 'sharedComponents/Input'
import formatMonths from 'lib/format-months'
import { Form, Label, StripeInput, FieldGroup } from 'components/Forms'

import HeaderOne from 'components/Headers/HeaderOne'
import HeaderThree from 'sharedComponents/HeaderThree'
import RadioButton from 'sharedComponents/RadioButton'
import Text from 'sharedComponents/Text'
import Link from 'components/Link'
import PromoCode from '../../../../components/InlinePromoCode'
import Steps from 'UserApp/components/Steps'
import style from './style.module.scss'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import MobileHeaderImage from '../../MobileHeaderImage'
import PremiumFeaturesModal from 'UserApp/components/PremiumFeaturesModal'
import mixPanel, { EVENTS } from 'services/mix-panel'
import Checkbox from 'sharedComponents/Checkbox'

type Props = {
  isLoading: boolean
  isProcessing: boolean
  breakdown: Breakdown
  paymentRequest: any
  onSubmit: () => void
  affiliate?: Affiliate
}

function SignupPaymentScreen(props: Props) {
  const {
    onSubmit,
    isProcessing,
    isLoading,
    breakdown,
    affiliate,
    paymentRequest,
    signup,
    subscriptionPlan,
    onChangeSubscriptionPlan,
  } = props

  if (isLoading) {
    return <Loader />
  }

  const { order, subscriptionPlans } = breakdown

  function handleChangeSubscriptionPlan(element) {
    onChangeSubscriptionPlan(element.value)
  }

  const [isShowingModal, setIsShowingModal] = useState(false)

  return (
    <Layout>
      <MobileHeaderImage step={4} />
      <Steps currentStep={4} totalSteps={4} />
      <HeaderOne>Payment</HeaderOne>
      <div className={style.orderSection}>
        <div className={style.summary}>
          <Text>{signup.user && signup.user.email}</Text>
          <Link
            to={`/signup/premium?plan=${subscriptionPlan}ly`}
            className={style.link}
          >
            Edit email
          </Link>
        </div>

        <div className={style.subscriptionSection}>
          <HeaderThree className={style.subscriptionTitle}>
            Choose your premium plan
          </HeaderThree>
          <Text className={style.subscriptionText}>
            Your plan includes Pet Notify Intellitag monitoring for unlimited
            household pets.
          </Text>

          <Link
            onClick={() => setIsShowingModal(true)}
            className={style.subscriptionLink}
          >
            What else does Premium include?
          </Link>
          <RadioButton
            id='subscriptionPlan-month'
            name='subscriptionPlan'
            value='month'
            checked={subscriptionPlan === 'month'}
            onChange={handleChangeSubscriptionPlan}
          >
            Monthly plan{'  '}
            <strong>
              {numeral(subscriptionPlans.month.amount).format('$0.00')}/mo
            </strong>
          </RadioButton>
          <RadioButton
            id='subscriptionPlan-year'
            name='subscriptionPlan'
            value='year'
            checked={subscriptionPlan === 'year'}
            onChange={handleChangeSubscriptionPlan}
          >
            Annual plan
            <strong>
              {numeral(subscriptionPlans.year.amount / 12).format('$0.00')}/mo
            </strong>{' '}
            (save {subscriptionPlans.year.annualDiscountPercentOff || 45}%)
          </RadioButton>
        </div>

        <div className={style.summary}>
          <div>
            <HeaderThree className={style.headerThree}>
              Intellitag order
            </HeaderThree>
          </div>
          <Link to='/signup/premium/intellitag' className={style.link}>
            Edit order
          </Link>
        </div>
        {order.intellitags.map((intellitag) => (
          <dl className={style.orderLineItem} key={intellitag.name}>
            <dt className={style.orderItem}>
              <p className={style.orderItemTitle}>Intellitag</p>
              <p className={style.orderItemDescription}>
                Size: {pascalize(intellitag.size)}
                <br />
                Name: {intellitag.nameOnTag}
                {intellitag.phone && (
                  <>
                    <br />
                    Phone: {intellitag.phone}
                  </>
                )}
              </p>
            </dt>
            <dd className={style.orderItemPrice}>{numeral(order.intellitagCost/100).format('$0.00')}</dd>
          </dl>
        ))}

        {order.items.map((item) => (
          <dl className={style.orderLineItem} key={item.label}>
            <dt className={style.orderItem}>{item.label}</dt>
            <dd className={style.orderItemPrice}>
              {item.label === 'Shipping' && item.cost === 0
                ? 'Free'
                : numeral(item.cost).format('$0.00')}
            </dd>
          </dl>
        ))}
      </div>

      <PromoCode
        canRemove={false}
        isAnnualPlanSelected={subscriptionPlan === 'year'}
      />

      {paymentRequest && (
        <div className={style.paymentButtons}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}

      <Form onSubmit={onSubmit} className={style.form}>
        {({ onChange, values, error }) => (
          <>
            <Label>
              Credit card number
              <StripeInput component={CardNumberElement} onChange={onChange} />
            </Label>

            <FieldGroup>
              <div>
                <Label>
                  Expiration (mm/yy)
                  <StripeInput
                    component={CardExpiryElement}
                    onChange={onChange}
                  />
                </Label>
              </div>
              <div>
                <Label>
                  Security code (CVV)
                  <StripeInput component={CardCvcElement} onChange={onChange} />
                </Label>
              </div>
            </FieldGroup>

            <div className={style.zipCodeField}>
              <Label htmlFor='zip'>ZIP code</Label>
              <Input
                name='zip'
                value={values.zip}
                onChange={onChange}
                id='zip'
              />
            </div>
            <PaymentMethodIcons />

            {/* <Text className={style.bottomText}>Terms of Use: <a href="https://www.petnotify.com/terms-of-use">https://www.petnotify.com/terms-of-use</a></Text>  
            <Text className={style.bottomText}>Privacy Policy: <a href="https://www.petnotify.com/privacy-policy"> https://www.petnotify.com/privacy-policy</a></Text>  
            <Text className={style.bottomText}>By signing up I agree to Pet Notify's User Agreement and Privacy Policy</Text>   */}
            <div className={style.policyContainer}>  
              <Checkbox
                name='policyAcceptance'
                value={values.policyAcceptance}
                onChange={onChange}
                label=''
                id='signup-policy-acceptance'
              />
              <Text className={style.linkText}>
                By signing up I agree to Pet Notify's{' '}
                <a href="https://www.petnotify.com/terms-of-use" className={style.link}>
                Terms of Use
                </a>{' '}
                and{' '}
                <a href="https://www.petnotify.com/privacy-policy" className={style.link}>
                  Privacy Policy
                </a>
              </Text>
            </div>

            <Checkbox
              name='smsPermission'
              value={values.smsPermission}
              onChange={onChange}
              label="By creating your Pet Notify account, you agree to receive SMS alerts to keep your pet safe. We’ll notify you immediately if your lost pet is found, needs care or there's an emergency. You can opt out at any time by replying STOP."
              id='singup-sms-permission'
            />

            {error && <Error>{error}</Error>}

            {isProcessing ? (
              <Loader />
            ) : (
              <Button
                type='submit'
                onClick={() => mixPanel.track(EVENTS.CONFIRM_PAYMENT_CLICK)}
              >
                Confirm payment
              </Button>
            )}
          </>
        )}
      </Form>

      {/* <Text className={style.disclaimer}>
        In confirming payment you agree to our{' '}
        <Link href='https://www.petnotify.com/terms-of-use'>Terms of Use</Link>,
        and{' '}
        <Link
          className={style.link}
          href='https://www.petnotify.com/privacy-policy'
        >
          Privacy Statement
        </Link>
        . You may modify or cancel your plan at anytime.
      </Text> */}
      {isShowingModal && (
        <PremiumFeaturesModal onClose={() => setIsShowingModal(false)} />
      )}
    </Layout>
  )
}

export default SignupPaymentScreen
