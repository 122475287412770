import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

SelectionOption.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  isSelected: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
}

function SelectionOption(props) {
  const { title, subTitle, isSelected, name, value, onChange, children } = props

  function handleClick() {
    onChange({ name, value })
  }

  function handleKeyPress(event) {
    if (event.charCode === 13 || event.charCode === 32) {
      const { currentTarget } = event
      currentTarget.click()
    }
  }

  return (
    <div
      className={classNames(style.wrapper, {
        [style.isSelected]: isSelected,
      })}
      tabIndex='0'
      role='button'
      onClick={handleClick}
      onKeyPress={handleKeyPress}
    >
      <div className={style.title}>{title}</div>
      <div className={style.subTitle}>{subTitle}</div>
      {children && <div className={style.children}>{children}</div>}
    </div>
  )
}

export default SelectionOption
