import React, { useState } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Form.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.func,
}

Form.defaultProps = {
  values: {},
}

function Form(props) {
  const { onSubmit, children, defaultValues } = props
  const [values, setValues] = useState(defaultValues || {})
  const [error, setError] = useState('')

  async function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    setError('')

    try {
      await onSubmit(values)
    } catch (error) {
      setError(error.message)
    }
  }

  function handleChange({ name, value }) {
    setValues((values) => {
      const updatedValues = {
        ...values,
      }

      if (typeof value !== 'undefined') {
        updatedValues[name] = value
      }

      return updatedValues
    })
  }

  return (
    <form onSubmit={handleSubmit} className={style.wrapper}>
      {children({
        values,
        onChange: handleChange,
        error,
      })}
    </form>
  )
}

export default Form
