import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import SignupSuccessScreen from 'UserApp/screens/Signup/Success'
import PremiumSignupShippingScreen from 'UserApp/containers/SignupScreen/Premium/Shipping'
import PremiumSignupPaymentScreen from 'UserApp/containers/SignupScreen/Premium/Payment'
import SignupChoosePlanScreen from 'UserApp/screens/Signup/ChoosePlan'
import FreeSignupUserInfoScreen from 'UserApp/containers/SignupScreen/Free/UserInfo'
import PremiumSignupUserInfoScreen from 'UserApp/containers/SignupScreen/Premium/UserInfo'
import PremiumSignupIntellitagScreen from 'UserApp/containers/SignupScreen/Premium/Intellitag'
import RedemptionScreen from './Redemption'

function SignupScreen() {
  return (
    <Switch>
      <Route path='/signup/success' component={SignupSuccessScreen} />
      <Route
        path='/signup/premium/intellitag'
        component={PremiumSignupIntellitagScreen}
      />
      <Route
        path='/signup/premium/shipping'
        component={PremiumSignupShippingScreen}
      />
      <Route
        path='/signup/premium/payment'
        component={PremiumSignupPaymentScreen}
      />
      <Route
        path='/signup/premium'
        exact
        component={PremiumSignupUserInfoScreen}
      />
      <Route path='/signup/redemption' exact component={RedemptionScreen} />
      <Route path='/signup/free' exact component={FreeSignupUserInfoScreen} />
      <Route path='/signup' exact component={SignupChoosePlanScreen} />
      <Redirect to='/signup' />
    </Switch>
  )
}

export default SignupScreen
