import React from 'react'
import { format, isPast } from 'date-fns'
import PropTypes from 'prop-types'

import TaskList from '../List'
import { TaskType } from 'types'

TasksListedByDueDate.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape(TaskType)),
}

function TasksListedByDueDate(props) {
  const { tasks, ...rest } = props

  const groupedTasks = tasks.reduce(
    (acc, task) => {
      const { startTime } = task

      if (isPast(startTime)) {
        acc.overdue.push(task)
      } else {
        acc.upcoming.push(task)
      }

      return acc
    },
    { overdue: [], upcoming: [] },
  )

  return (
    <>
      {groupedTasks.overdue.length > 0 && (
        <TaskList tasks={groupedTasks.overdue} header='Overdue' {...rest} />
      )}
      {groupedTasks.upcoming.length > 0 && (
        <TaskList tasks={groupedTasks.upcoming} header='Upcoming' {...rest} />
      )}
    </>
  )
}

export default TasksListedByDueDate
