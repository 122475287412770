import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from 'components/Link'
import Text from 'components/Text'
import { IOS_APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from 'config'

import appleAppStoreLogo from './images/apple-app-store-logo.png'
import googlePlayStoreLogo from './images/google-play-store-logo.png'
import mixpanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'

Footer.propTypes = { className: PropTypes.string }

function Footer(props) {
  const { className } = props

  function handleAppleAppStoreClick() {
    mixpanel.track(EVENTS.APPLE_APP_STORE)
  }

  function handleGooglePlayStoreClick() {
    mixpanel.track(EVENTS.GOOGLE_PLAY_STORE)
  }

  return (
    <footer className={classNames(style.wrapper, className)}>
      <div className={style.logos}>
        <a
          href={IOS_APP_STORE_LINK}
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleAppleAppStoreClick}
        >
          <img
            src={appleAppStoreLogo}
            height='34'
            className={style.appStoreLogo}
          />
        </a>
        <a
          href={GOOGLE_PLAY_STORE_LINK}
          target='_blank'
          rel='noopener noreferrer'
          onClick={handleGooglePlayStoreClick}
        >
          <img
            src={googlePlayStoreLogo}
            height='34'
            className={style.appStoreLogo}
          />
        </a>
      </div>
      <Text className={style.text} variant='small'>
        Download our iOS or Android mobile app to manage your account anytime,
        anywhere.
      </Text>
      <Text className={style.copyright}>
        &copy;{new Date().getFullYear()} Pet Notify&reg;
      </Text>
      <div className={style.links}>
        <Link
          className={style.link}
          href='https://www.petnotify.com/privacy-policy'
        >
          Privacy Policy
        </Link>
        <Link
          className={style.link}
          href='https://www.petnotify.com/terms-of-use'
        >
          Terms of Use
        </Link>
      </div>
    </footer>
  )
}

export default Footer
