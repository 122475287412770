import React, { useRef, useState } from 'react'
import { Button, Loader, Text } from '@pet-notify/ui'
import PropTypes from 'prop-types'

import PetPhoto from 'components/Pets/Photo'
import style from './style.module.scss'
import api from 'services/pet-notify-api'

PhotoUpload.propTypes = {
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.object,
}

function PhotoUpload(props) {
  const { name, value, onChange } = props
  const [isLoading, setIsLoading] = useState(false)

  function handleSelect(event) {
    const { files } = event.target
    const [file] = files
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      onChange({ name: 'tempPhoto', value: reader.result })
      // this.setState({ src: reader.result })
    })
    reader.readAsDataURL(file)
  }

  const inputRef = useRef(null)

  function handleUploadButtonClick(event) {
    event.stopPropagation()
    inputRef.current.click()
  }

  async function handleRemove() {
    setIsLoading(true)
    try {
      await api.photos.remove(value.id)

      setIsLoading(false)

      onChange({ name, value: null })
    } catch (e) {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={style.wrapper}>
      {value && <PetPhoto src={value.sizes.large.src} />}
      <input
        ref={inputRef}
        type='file'
        name='photo'
        accept='.jpg,.jpeg,.gif,.png'
        onChange={handleSelect}
        className={style.file}
      />
      {value ? (
        <div className={style.buttonGroup}>
          <Button type='button' variant='redHollow' onClick={handleRemove}>
            Remove Photo
          </Button>
          <Button type='submit'>Save</Button>
        </div>
      ) : (
        <Button
          type='button'
          variant='hollow'
          onClick={handleUploadButtonClick}
        >
          Upload Photo
        </Button>
      )}
      <Text className={style.note}>JPG, PNG or GIF supported.</Text>
    </div>
  )
}

export default PhotoUpload
