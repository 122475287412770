export default [
  ``,
  `Dog`,
  `Cat`,
  `Bird`,
  `Small animal`,
  `Reptile`,
  `Horse`,
  `Other`,
]
