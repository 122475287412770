import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { TABS } from 'UserApp/containers/Pets/constants'
import style from './style.module.scss'

Tabs.propTypes = {
  currentForm: PropTypes.string,
  onChangeForm: PropTypes.func,
}

function Tabs(props) {
  const { currentForm, onChangeForm } = props

  return (
    <nav className={style.wrapper}>
      {TABS.map(({ form, title }) => (
        <button
          className={classNames(style.tab, {
            [style.activeTab]: currentForm === form,
          })}
          key={title}
          onClick={() => onChangeForm(form)}
        >
          {title}
        </button>
      ))}
    </nav>
  )
}

export default Tabs
