import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Layout from 'components/Layout/FullLayout'
import photo from './images/laptop-with-dog.jpg'
import { reactivateAccount } from 'UserApp/store/user/actions'
import { Header, Text, Button, Link, Loader } from '@pet-notify/ui'
import style from './style.module.css'

function AccountInactiveScreen() {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  async function handleReactivate() {
    setIsLoading(true)
    await dispatch(reactivateAccount())
    history.push('/')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Layout showNavigation={false}>
        <div className={style.image}>
          <img src={photo} alt='' />
        </div>

        <Header as='h1' size='xxlarge' mb='8'>
          Your account is inactive
        </Header>
        <Text mb='6'>
          Reactivate your account to continue to use Pet Notify. All of your
          previously entered pet information has been saved, so you’ll be up and
          running in no time.
        </Text>
        <Text mb='6'>
          If you have any questions or concerns about your subscription please
          contact us at{' '}
          <Link href='mailto:support@petnotify.com'>support@petnotify.com</Link>
          .
        </Text>

        <div className={style.buttons}>
          <Button onClick={handleReactivate}>Reactivate Account</Button>
          <Button to='/signout' variant='secondary' color='red'>
            Sign out
          </Button>
        </div>
      </Layout>
    </>
  )
}
export default AccountInactiveScreen
