import React, { ReactNode } from 'react'

import style from './style.module.scss'

type Props = {
  children?: ReactNode
  subTitle?: string
}

function Label(props: Props & React.HTMLProps<HTMLLabelElement>) {
  const { children, subTitle, ...rest } = props

  return (
    <div className={style.wrapper}>
      <label className={style.label} {...rest}>
        {children}
      </label>
      {subTitle && <div className={style.subTitle}>{subTitle}</div>}
    </div>
  )
}

export default Label
