import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'

type Props = React.HTMLProps<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ className, ...rest }: Props, ref) => {
    return (
      <input
        className={classNames(style.wrapper, className)}
        {...rest}
        ref={ref}
      />
    )
  },
)

export default Input
