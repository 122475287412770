import {
  SET_PLAN,
  SAVE_USER_DETAILS,
  CREATE_TEMP_USER,
  SAVE_INTELLITAGS,
  SAVE_SHIPPING_ADDRESS,
  COMPLETE_SIGNUP,
} from './actions'

const localVersion = window.localStorage.getItem('signup')

export default function reducer(
  state = localVersion
    ? JSON.parse(localVersion)
    : {
        user: { sendNewsAndUpdates: true },
      },
  action,
) {
  const updatedState = ((action) => {
    const { payload, type } = action

    switch (type) {
      case SET_PLAN:
        return {
          ...state,
          plan: payload,
        }
      case CREATE_TEMP_USER:
      case SAVE_USER_DETAILS:
        return {
          ...state,
          user: payload,
        }
      case SAVE_INTELLITAGS:
        return {
          ...state,
          intellitags: payload,
        }
      case SAVE_SHIPPING_ADDRESS:
        return {
          ...state,
          shippingAddress: payload,
        }
      case COMPLETE_SIGNUP:
        return { ...payload }
      default:
        return state
    }
  })(action)

  window.localStorage.setItem('signup', JSON.stringify(updatedState))
  return updatedState
}
