import { SET, REMOVE } from './actions'

export default function reducer(state = null, action = {}) {
  const { payload, type } = action

  switch (type) {
    case SET:
      return {
        ...payload,
      }
    case REMOVE:
      return null
    default:
      return state
  }
}
