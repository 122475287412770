import React from 'react'
import {
  Header,
  Label,
  FieldGroup,
  Text,
  Button,
  Input,
  Dropdown,
  StateLabel,
  Loader,
  Fields,
} from '@pet-notify/ui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import countries from 'lib/countries'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'
import PhoneInput from '../PhoneInput'
import type { ReplaceIntellitagOrder } from 'UserApp/services/replace-intellitag-orders/types'
import { useUpdateReplaceIntellitagOrder } from 'UserApp/hooks/api/replace-intellitag-order'
import style from '../style.module.scss'

const schema = yup.object({
  address: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('Zip is required'),
  phone: yup.string().required('Phone is required'),
})

type FormData = {
  address: string
  country: string
  address2: string
  city: string
  state: string
  zip: string
  phone: string
}

type Props = {
  onSuccess: () => void
  replaceIntellitagOrder: ReplaceIntellitagOrder
}

function ShippingAddressForm({ replaceIntellitagOrder, onSuccess }: Props) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: replaceIntellitagOrder.shippingAddress,
    resolver: yupResolver(schema),
  })

  const updateMutation = useUpdateReplaceIntellitagOrder(
    replaceIntellitagOrder.id,
  )

  function onSubmit(data: FormData) {
    updateMutation.mutate(
      { shippingAddress: data },
      {
        onSuccess,
      },
    )
  }

  const country = watch('country')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header className={style.header}>
        Shipping for Intellitag replacement
      </Header>
      <Fields>
        <Text className={style.text}>
          Your Intellitag will arrive within 5-7 days.
        </Text>

        <FieldGroup>
          <Label htmlFor='address'>Address</Label>
          <Input type='text' id='address' {...register('address')} />
        </FieldGroup>

        {errors.address && (
          <Text variant='error'>{errors.address.message}</Text>
        )}

        <FieldGroup>
          <Label htmlFor='address2'>Apartment / Suite</Label>
          <Input type='text' id='address2' {...register('address2')} />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='city'>City</Label>
          <Input type='text' id='city' {...register('city')} />
        </FieldGroup>

        {errors.city && <Text variant='error'>{errors.city.message}</Text>}

        <FieldGroup>
          <Label htmlFor='country'>Country</Label>
          <Dropdown options={countries} id='country' {...register('country')} />
        </FieldGroup>

        {errors.country && (
          <Text variant='error'>{errors.country.message}</Text>
        )}

        <FieldGroup>
          <StateLabel htmlFor='state' country={country} />
          {country === 'US' || country === 'CA' ? (
            <Dropdown
              options={country === 'US' ? usStates : canadianProvinces}
              id='state'
              {...register('state')}
            />
          ) : (
            <Input type='text' id='state' {...register('state')} />
          )}
        </FieldGroup>

        {errors.state && <Text variant='error'>{errors.state.message}</Text>}

        <FieldGroup>
          <Label htmlFor='zip'>
            {country === 'US' ? `Zip` : `Postal Code`}
          </Label>
          <Input type='text' id='zip' {...register('zip')} />
        </FieldGroup>

        {errors.zip && <Text variant='error'>{errors.zip.message}</Text>}

        <FieldGroup>
          <Label htmlFor='phone'>Phone</Label>
          <PhoneInput id='phone' name='phone' control={control} />
        </FieldGroup>

        {errors.phone && <Text variant='error'>{errors.phone.message}</Text>}
      </Fields>
      {updateMutation.isLoading ? (
        <Loader />
      ) : (
        <Button type='submit'>Continue</Button>
      )}
    </form>
  )
}

export default ShippingAddressForm
