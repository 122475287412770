import React from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'

import { FULL_DATE_AND_TIME } from 'constants/dateFormats'
import Link from 'components/Link'
import { TaskType } from 'types'

import style from './style.module.scss'

TaskItem.propTypes = {
  ...TaskType,
}

function TaskItem(props) {
  const { id, name, repeat, isDone, onCheck, contact, completedAt, startTime } =
    props

  return (
    <li
      className={classNames(style.wrapper, {
        [style.isDone]: isDone,
        [style.repeat]: !isDone && !!repeat,
      })}
    >
      <input
        type='checkbox'
        checked={isDone}
        disabled={isDone}
        onChange={() => onCheck(id, !isDone)}
        className={style.checkbox}
        id={`task-${id}`}
      />

      <label htmlFor={`task-${id}`} aria-label={`Mark ${name} completed`} />
      <div>
        <div>
          {isDone ? (
            name
          ) : (
            <Link to={`/tasks/${id}`} className={style.link}>
              {name}
            </Link>
          )}
        </div>

        {contact && (
          <div className={style.assignedTo}>Assigned to {contact.name}</div>
        )}

        <div className={style.dateTime}>
          {isDone
            ? `Completed ${
                completedAt ? format(completedAt, FULL_DATE_AND_TIME) : ''
              }`
            : `Due on ${format(startTime, FULL_DATE_AND_TIME)}`}
        </div>
      </div>
    </li>
  )
}

export default TaskItem
