import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Props = BaseProps<'p'> & {
  variant?: 'default' | 'note' | 'error' | 'bold' | 'empty'
}

function Text({ className, variant = 'default', as = 'p', ...rest }: Props) {
  return (
    <Base
      className={classNames(style.wrapper, style[variant], className)}
      as={as}
      {...rest}
    />
  )
}

export default Text
