import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Title.propTypes = {
  children: PropTypes.node,
}

function Title(props) {
  const { children } = props

  return <div className={style.title}>{children}</div>
}

Value.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function Value(props) {
  const { children, className } = props

  return <div className={classNames(style.value, className)}>{children}</div>
}

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function Row(props) {
  const { children, className } = props

  return <div className={classNames(style.wrapper, className)}>{children}</div>
}

export { Title, Value }

export default Row
