import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  Button,
  FieldGroup,
  Header,
  Input,
  Label,
  StripeInput,
  Loader,
  Link,
  Text,
  Fields,
} from '@pet-notify/ui'

import useNotification from 'UserApp/hooks/useNotification'
import api from 'lib/api'
import PaymentMethodIcons from 'UserApp/components/PaymentMethodIcons'
import style from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import API from 'services/pet-notify-api'
import { renewSubscription } from 'UserApp/store/user/actions'

type FormData = {
  token: any
  zip: string
}

type Props = {
  userStatus: string
  subscriptionInterval: string
  onSuccess: () => void
  onChangePlan?: () => void
}

function PaymentForm({ userStatus, subscriptionInterval, onSuccess, onChangePlan }: Props) {
    //New added code to confirm the payment when payment info is changed for past-due user
    const dispatch = useDispatch()
    const promoCode = useSelector(
      (state) => state.promoCode && state.promoCode.code,
    )
    const history = useHistory()
    const [pricing, setPricing] = useState()
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    //
  const stripe = useStripe()
  const elements = useElements()
  const { setNotification } = useNotification()
  const { register, ...form } = useForm<FormData>()

    // New code added to get the plan pricing
    useEffect(() => {

      API.orders.pricing({ promoCode }).then((result) => {
        setPricing(result)
        setIsLoading(false)
      })
    }, [promoCode])
    //

  const updatePayment = useMutation(
    (data: FormData) => api.patch('/api/users/me/subscription/payment', data),
    {
      onSuccess: () => {
        setNotification(`Payment information updated`)
        onSuccess()
      },
    },
  )

  const handleSubmit = form.handleSubmit(async (values) => {
    const { zip } = values
    const cardNumberElement = elements.getElement(CardNumberElement)

    if (!zip || zip === '') {
      throw new Error('Billing ZIP code required')
    }

    setIsProcessing(true)

    const { token, error } = await stripe.createToken(cardNumberElement, {
      address_zip: zip,
    })

    if (error) {
      setIsProcessing(false)
      throw error.message
    }

    if (userStatus === 'past-due')
    {
      try {
        await dispatch(
          renewSubscription({
            tokken: token.id,
            isAnnualPlan: subscriptionInterval === 'year',
            promoCode,
            stripeSubscriptionPlan:
              pricing.subscriptionPlans[subscriptionInterval].id,
            subscriptionInterval,
            ...values,
          }),
        )
        setIsProcessing(false)
        history.push('/settings/upgrade/success')
      } catch (e) {
        setIsProcessing(false)
        throw e
      }
    }
    else
    {
      updatePayment.mutate({ zip, token: token.id })
    }
  })

  if (updatePayment.isLoading) {
    return <Loader />
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header as='h3' mb='4' size='xlarge'>
        Payment information
      </Header>
      {onChangePlan && (
        <Text mb='6'>
          <Link onClick={onChangePlan}>
            Want to go back and change your plan?
          </Link>
        </Text>
      )}

      <Fields>
        <FieldGroup>
          <Label>
            Credit card number
            <StripeInput as={CardNumberElement} />
          </Label>
        </FieldGroup>

        <FieldGroup className={style.sideBySideFieldGroup}>
          <Label>
            Expiration (mm/yy)
            <StripeInput as={CardExpiryElement} />
          </Label>
          <Label>
            Security code (CVV)
            <StripeInput as={CardCvcElement} />
          </Label>
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='zip'>ZIP code</Label>
          <Input
            className={style.zipInput}
            id='zip'
            {...register('zip', { required: true })}
          />
        </FieldGroup>

        <PaymentMethodIcons />
      </Fields>

      <Button type='submit' disabled={form.formState.isSubmitting}>
        Finish
      </Button>
    </form>
  )
}

export default PaymentForm
