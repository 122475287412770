import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom'
import { Modal, Loader, Text, Link, Header } from '@pet-notify/ui'
import queryString from 'query-string'
import { useSelector } from 'react-redux'

import { selectShippingAddress } from 'UserApp/store/user/selectors'
import IntellitagForm from './IntellitagForm'
import ShippingAddressForm from './ShippingAddressForm'
import Checkout from './Checkout'
import Success from './Success'
import {
  useCreateReplaceIntellitagOrder,
  useReplaceIntellitagOrder,
} from 'UserApp/hooks/api/replace-intellitag-order'
import style from './style.module.scss'

function ReplaceIntellitagOrderModal() {
  const history = useHistory()
  const location = useLocation()
  const hasSubscription = useSelector((state) => state.user.hasSubscription)
  const isCanceled = useSelector((state) => state.user.subscription.isCanceled)
  const shippingAddress = useSelector(selectShippingAddress)
  const queryParams = queryString.parse(location.search)
  const [id, setId] = useState(
    localStorage.getItem('replace-intellitag-order-id'),
  )

  const query = useReplaceIntellitagOrder(id, {
    onError: () => {
      setId(undefined)
    },
  })

  const createMutation = useCreateReplaceIntellitagOrder()

  useEffect(() => {
    if (!id) {
      createMutation.mutate(
        {
          intellitagId:
            queryParams.intellitagId && String(queryParams.intellitagId),
          shippingAddress,
        },
        {
          onSuccess: (data) => {
            localStorage.setItem('replace-intellitag-order-id', data.id)
            setId(data.id)
          },
        },
      )
    }
  }, [id])

  function handleClose() {
    history.push('/')
  }

  if (!hasSubscription || isCanceled) {
    return (
      <Modal onClose={handleClose}>
        <Header mb='6'>Intellitag replacement</Header>
        <Text>
          Intellitag is not offered under our free plan. To order a new or
          replacement Intellitag you first will have to{' '}
          <Link as={RouterLink} to='/settings/upgrade'>
            Upgrade to Premium
          </Link>
        </Text>
      </Modal>
    )
  }

  return (
    <Modal
      onClose={handleClose}
      className={classNames(style.wrapper, {
        [style.successModal]:
          location.pathname === `/replace-intellitag/success`,
      })}
    >
      {query.isLoading || !query.data ? (
        <Loader />
      ) : (
        <Switch>
          <Route path='/replace-intellitag/success'>
            <Success />
          </Route>
          <Route path='/replace-intellitag/checkout'>
            <Checkout
              replaceIntellitagOrder={query.data}
              onSuccess={() => {
                history.push('/replace-intellitag/success')
              }}
            />
          </Route>
          <Route path='/replace-intellitag/shipping'>
            <ShippingAddressForm
              replaceIntellitagOrder={query.data}
              onSuccess={() => {
                history.push('/replace-intellitag/checkout')
              }}
            />
          </Route>
          <Route path='/replace-intellitag'>
            <IntellitagForm
              replaceIntellitagOrder={query.data}
              onSuccess={() => {
                history.push('/replace-intellitag/shipping')
              }}
            />
          </Route>
        </Switch>
      )}
    </Modal>
  )
}

export default ReplaceIntellitagOrderModal
