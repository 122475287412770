import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import pluralize from 'pluralize'

import possessive from 'lib/possessive'
import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Text from 'components/Text'
import { Button, ErrorText } from 'components/Forms'

import style from './style.module.scss'

CheckOnPetsScreen.propTypes = {
  alarm: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  response: PropTypes.string,
  token: PropTypes.string,
  user: PropTypes.object,
}

CheckOnPetsScreen.defaultProps = {
  user: {},
}

function CheckOnPetsScreen(props) {
  const {
    alarm,
    error,
    isLoading,
    onAccept,
    onDecline,
    response,
    token,
    user,
  } = props

  if (response === 'accepted') {
    return <Redirect to={`/c/${token}/vault/${alarm.id}`} push />
  }

  if (response === 'declined') {
    return <Redirect to={`/c/${token}/declined`} push />
  }

  if (isLoading) {
    return <Loader />
  }

  const { firstName, lastName, petCount } = user
  const name = possessive(`${firstName} ${lastName}`)

  return (
    <Layout showNav={false}>
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <React.Fragment>
          <HeaderOne className={style.header}>
            Are you available to care for {name} {pluralize(`Pet`, petCount)}?
          </HeaderOne>
          <Text className={style.text}>
            {possessive(firstName)} Pet Notify Alarm is going off, are you
            available to care for the pets until {firstName} is located? If so
            we will provide you {possessive(firstName)} last known location and
            access to the Pet Vault to care for the family{' '}
            {pluralize(`pet`, petCount)}.
          </Text>
          <Button onClick={onAccept} className={style.button} small>
            Yes, I’m available
          </Button>
          <Button onClick={onDecline} variant='hollowRed' small>
            No, I’m unavailable
          </Button>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default CheckOnPetsScreen
