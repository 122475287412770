import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'
import { set as setNotification } from 'store/notification/actions'

import VerifyAccountScreen from 'UserApp/screens/VerifyAccount'

class VerifyAccountScreenContainer extends React.PureComponent {
  static propTypes = {
    setNotification: PropTypes.func,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }

  state = { isProcessing: true, isVerified: false, error: '' }

  async componentDidMount() {
    const { location, setNotification } = this.props
    const { token } = queryString.parse(location.search)

    try {
      await api.users.verifyAccount({ token })
      setNotification('Email verified')
      this.setState({ isVerified: true })
    } catch (error) {
      this.setState({ isProcessing: false, error: error.message })
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
  })

  render() {
    const props = this.generateProps()
    return <VerifyAccountScreen {...props} />
  }
}

export default connect(null, { setNotification })(VerifyAccountScreenContainer)
