import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './style.module.scss'

HeaderOne.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function HeaderOne(props) {
  const { children, className } = props
  return <h1 className={classNames(style.wrapper, className)}>{children}</h1>
}

export default HeaderOne
