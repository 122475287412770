import React, { useState, MouseEvent } from 'react'
import classNames from 'classnames'

import Input, { Props as InputProps } from '../'
import style from './style.module.scss'

type Props = InputProps

function PasswordInput(props: Props) {
  const [isShowing, setIsShowing] = useState(false)

  function handleToggleShow() {
    setIsShowing((state) => !state)
  }

  function handleMouseDown(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()
  }

  return (
    <div className={style.wrapper}>
      <Input
        {...props}
        type={isShowing ? 'text' : 'password'}
        className={style.input}
      />
      <button
        type='button'
        className={classNames(style.button, { [style.isShowing]: isShowing })}
        onClick={handleToggleShow}
        onMouseDown={handleMouseDown}
        tabIndex='-1'
      />
    </div>
  )
}

export default PasswordInput
