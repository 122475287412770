import React from 'react'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'

import { Form, Input, Button, ErrorText, Dropdown } from 'components/Forms'
import style from './style.module.scss'

const AMPM = ['AM', 'PM']

AlarmForm.propTypes = {
  onSubmit: PropTypes.func,
  isProcessing: PropTypes.bool,
}

function AlarmForm(props) {
  const { onSubmit, isProcessing } = props

  return (
    <Form
      onSubmit={onSubmit}
      values={{
        startTimeHour: '5',
        startTimeMinute: '30',
        startTimeAmPM: 'PM',
      }}
    >
      {({ onChange, values, error }) =>
        isProcessing ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={style.fields}>
              <Input
                type='text'
                maxLength='2'
                name='startTimeHour'
                value={values.startTimeHour}
                onChange={onChange}
                className={style.input}
              />
              <Input
                type='text'
                maxLength='2'
                name='startTimeMinute'
                value={values.startTimeMinute}
                onChange={onChange}
                className={style.input}
              />
              <Dropdown
                name='startTimeAmPM'
                value={values.startTimeAmPM}
                onChange={onChange}
                options={AMPM}
                className={style.dropdown}
              />
            </div>
            {error && <ErrorText>{error}</ErrorText>}

            <Button type='submit' small className={style.button}>
              Set Alarm
            </Button>
          </React.Fragment>
        )
      }
    </Form>
  )
}

export default AlarmForm
