import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import {
  Header,
  TextArea,
  Text,
  Label,
  Button,
  FieldGroup,
  Stack,
  Loader,
  Fields,
} from '@pet-notify/ui'

import { set as setNotification } from 'store/notification/actions'
import { cancelSubscription, logout } from 'UserApp/store/user/actions'

type FormData = {
  reasonForCanceling: string
  deleteAllMyData: boolean
}

type Props = {
  onClose: () => void
}

function useCancelForm({ onClose }: Props) {
  const [deleteAllMyData, setDeleteAllMyData] = useState(false)
  const [formData, setFormData] = useState<FormData>()
  const dispatch = useDispatch()
  const form = useForm<FormData>()

  async function handleSubmit(data: FormData) {
    if (formData) {
      await dispatch(cancelSubscription(formData))
      dispatch(setNotification(`Subscription canceled`))
      if (deleteAllMyData) {
        dispatch(logout())
      } else {
        onClose()
      }
    } else {
      setFormData(data)
    }
  }

  return {
    ...form,
    onSubmit: form.handleSubmit(handleSubmit),
    isSubmitting: form.formState.isSubmitting,
    errors: form.formState.errors,
    isConfirming: !!formData,
    formData,
    deleteAllMyData,
    setDeleteAllMyData,
  }
}

function CancelSubscriptionForm({ onClose }: Props) {
  const {
    isSubmitting,
    register,
    onSubmit,
    errors,
    formData,
    isConfirming,
    deleteAllMyData,
    setDeleteAllMyData,
  } = useCancelForm({
    onClose,
  })

  if (isSubmitting) {
    return <Loader />
  }

  if (isConfirming) {
    return (
      <>
        <Header as='h2' size='xlarge' mb='4'>
          {deleteAllMyData
            ? 'Cancel subscription and delete my data'
            : 'Cancel subscription'}
        </Header>

        <Text mb='6'>
          {deleteAllMyData
            ? 'Are you sure you want to cancel and delete your account? This cannot be undone.'
            : 'Are you sure you want to cancel your subscription?'}
        </Text>

        <form onSubmit={onSubmit}>
          <Stack direction='horizontal' spacing='4' mb='8'>
            <Button type='submit' variant='red'>
              Yes,{' '}
              {deleteAllMyData
                ? 'cancel and delete my data'
                : 'cancel subscription'}
            </Button>

            <Button type='button' onClick={onClose} variant='hollow'>
              No, keep my subscription
            </Button>
          </Stack>
        </form>
      </>
    )
  }

  return (
    <>
      <Header as='h2' size='xlarge' mb='4'>
        Cancel subscription
      </Header>

      <Text mb='6'>
        Canceling your subscription will switch you to our Free Plan, and you
        will lose access to Intellitag pet monitoring and lost pet messaging.
        You may upgrade to Premium anytime.
      </Text>

      <form onSubmit={onSubmit}>
        <Fields>
          <FieldGroup>
            <Label htmlFor='reasonForCanceling'>
              Please let us know why you’re cancelling?
            </Label>

            <TextArea
              id='reasonForCanceling'
              {...register('reasonForCanceling', {
                required: `To improve our products and service please provide us a short explanation as to why you're canceling.`,
              })}
            />
          </FieldGroup>

          {errors.reasonForCanceling && (
            <Text variant='error'>{errors.reasonForCanceling.message}</Text>
          )}
        </Fields>

        <Stack direction='horizontal' spacing='4' mb='8'>
          <Button
            type='submit'
            variant='red'
            onClick={() => setDeleteAllMyData(false)}
          >
            Cancel subscription
          </Button>

          <Button type='button' onClick={onClose} variant='hollow'>
            Keep subscription
          </Button>
        </Stack>

        <Header as='h3' size='large' mb='6'>
          Or, delete your account
        </Header>

        <Text mb='6'>
          Your Pet Notify account and data will be permanently deleted within 30
          days.
        </Text>

        <Button
          type='submit'
          variant='redHollow'
          onClick={() => setDeleteAllMyData(true)}
        >
          Delete my account
        </Button>
      </form>
    </>
  )
}
export default CancelSubscriptionForm
