import React from 'react'
import { NavLink } from 'react-router-dom'

import PetNotifyLogo from 'sharedComponents/PetNotifyLogo'
import Link from 'sharedComponents/Link'
import Navigation from './Navigation'
import style from './style.module.scss'

function Header() {
  return (
    <header className={style.wrapper}>
      <div className={style.inner}>
        <NavLink to='/' className={style.logo}>
          <PetNotifyLogo />
        </NavLink>
        <div className={style.nav}>
          <Navigation className={style.menu} />
        </div>
      </div>
    </header>
  )
}

export default Header
