import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import style from './style.module.scss'

type Props = {
  className?: string
  to?: string
  variant?: string
  href?: string
  [key: string]: any
}

function Button(props: Props) {
  const { className, to, href, variant = 'default', ...rest } = props

  if (to) {
    return (
      <Link
        to={to}
        className={classNames(style.wrapper, style[variant], className)}
        {...rest}
      />
    )
  } else if (href) {
    return (
      <a
        className={classNames(style.wrapper, style[variant], className)}
        href={href}
        {...rest}
      />
    )
  } else {
    return (
      <button
        className={classNames(style.wrapper, style[variant], className)}
        {...rest}
      />
    )
  }
}

export default Button
