import React from 'react'
import { Controller } from 'react-hook-form'
import { Input } from '@pet-notify/ui'

import formatPhone from 'lib/format-phone'

const PhoneInput = ({ control, name, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Input
          {...rest}
          {...field}
          type='phone'
          value={formatPhone(field.value)}
        />
      )}
    />
  )
}

export default PhoneInput
