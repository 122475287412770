import React from 'react'
import PropTypes from 'prop-types'

import { Label, Input, TextArea } from 'components/Forms'
import PhoneInput from 'sharedComponents/Input/Phone'
import Text from 'components/Text'
import style from './style.module.scss'

DietAndMedicalForm.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
}

function DietAndMedicalForm(props) {
  const { values, onChange } = props

  return (
    <React.Fragment>
      <Label htmlFor='pet-diet'>Diet</Label>
      <TextArea
        placeholder='Optional'
        name='meta.diet'
        value={values.meta.diet}
        onChange={onChange}
        id='pet-diet'
        className={style.textAreaWithNote}
      />
      <Text variant='note' className={style.note}>
        Include food types, schedule, special instructions or any dietary
        restrictions.
      </Text>

      <Label htmlFor='pet-medicalInformation'>Medical</Label>
      <TextArea
        placeholder='Optional'
        name='meta.medicalInformation'
        value={values.meta.medicalInformation}
        onChange={onChange}
        id='pet-medicalInformation'
        className={style.textAreaWithNote}
      />
      <Text variant='note' className={style.note}>
        Include allergies, as well as any medication they are taking.
      </Text>

      <Label htmlFor='pet-vet-clinic'>Vet clinic</Label>
      <Input
        placeholder='Optional'
        name='meta.vetClinic'
        value={values.meta.vetClinic}
        onChange={onChange}
        id='pet-vet-clinic'
      />

      <Label htmlFor='pet-vet-clinic'>Vet phone</Label>
      <PhoneInput
        placeholder='Optional'
        name='meta.vetClinicPhone'
        value={values.meta.vetClinicPhone}
        onChange={onChange}
        id='pet-vet-clinic-phone'
      />

      <Label htmlFor='pet-emergency-clinic'>Emergency clinic</Label>
      <Input
        placeholder='Optional'
        name='meta.emergencyClinic'
        value={values.meta.emergencyClinic}
        onChange={onChange}
        id='pet-emergency-clinic'
      />

      <Label htmlFor='pet-emergency-clinic-phone'>Emergency clinic phone</Label>
      <PhoneInput
        placeholder='Optional'
        name='meta.emergencyClinicPhone'
        value={values.meta.emergencyClinicPhone}
        onChange={onChange}
        id='pet-emergency-clinic-phone'
      />

      <Label htmlFor='pet-insurance-insurer'>Insurance Provider</Label>
      <Input
        placeholder='Optional'
        name='meta.insurance.insurer'
        value={values.meta.insurance.insurer}
        onChange={onChange}
        id='pet-insurance-provider'
      />

      <Label htmlFor='pet-insurance-policy-type'>Policy Type</Label>
      <Input
        placeholder='Optional'
        name='meta.insurance.policyType'
        value={values.meta.insurance.policyType}
        onChange={onChange}
        id='pet-insurance-policy-type'
      />

      <Label htmlFor='pet-insurance-phone'>Insurer Phone</Label>
      <PhoneInput
        placeholder='Optional'
        name='meta.insurance.phone'
        value={values.meta.insurance.phone}
        onChange={onChange}
        id='pet-insurance-phone'
      />

      <Label htmlFor='pet-insurance-policyNumber'>Policy #</Label>
      <Input
        placeholder='Optional'
        name='meta.insurance.policyNumber'
        value={values.meta.insurance.policyNumber}
        onChange={onChange}
        id='pet-insurance-policyNumber'
      />

      <Label htmlFor='pet-insurance-notes'>Insurance notes</Label>
      <TextArea
        placeholder='Optional'
        name='meta.insurance.notes'
        value={values.meta.insurance.notes}
        onChange={onChange}
        id='pet-insurance-notes'
      />
    </React.Fragment>
  )
}

export default DietAndMedicalForm
