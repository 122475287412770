import React from 'react'
import PropTypes from 'prop-types'

import HeaderTwo from 'components/Headers/HeaderTwo'
import { UpdatedButton as Button } from 'components/Forms'
import Link from 'components/Link'
import Text from 'components/Text'
import style from './style.module.scss'

IntellitagCTA.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
}

function IntellitagCTA(props) {
  const { to = '/order-intellitag', onClick, hasSubscription } = props

  return (
    <div className={style.wrapper}>
      <HeaderTwo className={style.header}>
        Intellitag<sup>&reg;</sup>
      </HeaderTwo>

      <Text className={style.text}>
        The smart pet tag for smart pet parents<sup>&trade;</sup>
      </Text>

      <div>
        <Link
          href='https://www.petnotify.com/how-it-works#intellitag'
          className={style.link}
        >
          Learn More
        </Link>
      </div>

      {hasSubscription ? (
        <Button
          to='/order-intellitag'
          className={style.button}
          onClick={onClick}
        >
          Order Now
        </Button>
      ) : (
        <Button
          to='/settings/upgrade'
          className={style.button}
          onClick={onClick}
        >
          Upgrade to premium
        </Button>
      )}
    </div>
  )
}

export default IntellitagCTA
