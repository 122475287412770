import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Variant =
  | 'default'
  | 'hollow'
  | 'redHollow'
  | 'red'
  | 'white'
  | 'secondary'

type Color = 'primary' | 'red' | 'white'

type Props = {
  variant?: Variant
  color?: Color
}

function Button<C extends React.ElementType = 'button'>({
  as,
  className,
  variant = 'default',
  color = 'primary',
  ...rest
}: Props & BaseProps<C>) {
  return (
    <Base
      as={as || 'button'}
      className={classNames(
        style.wrapper,
        style[variant],
        style[color],
        className,
      )}
      {...rest}
    />
  )
}

export default Button
