import React from 'react'
import classNames from 'classnames'

import Text from '../Text'
import style from './style.module.scss'

type Props = {
  name: string
  value: boolean
  enabledLabel: string
  disabledLabel: string
  onChange: (formField: FormField) => void
  className?: string
}

function Toggle(props: Props) {
  const { name, value, onChange, enabledLabel, disabledLabel, className } =
    props

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, checked } = event.currentTarget

    if (onChange) {
      onChange({ name, value: checked })
    }
  }

  return (
    <label className={classNames(style.wrapper, className)}>
      <Text className={style.labelText}>{enabledLabel}</Text>
      <input
        className={style.toggle}
        type='checkbox'
        name={name}
        checked={value}
        onChange={handleChange}
        id='toggle'
      />
      <label htmlFor='toggle' className={style.toggleLabel}></label>

      <Text className={style.labelText}>{disabledLabel}</Text>
    </label>
  )
}

export default Toggle
