import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'
import { set as setNotification } from 'store/notification/actions'

import TasksScreen from 'ContactApp/screens/Tasks'

TasksScreenContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }),
}

function formatTaskResponse(response) {
  return {
    ...response,
    startTime: response.startTime && new Date(response.startTime),
  }
}

function TasksScreenContainer(props) {
  const { match } = props
  const { params } = match
  const { token } = params

  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [task, setTask] = useState(undefined)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    async function fetchTask() {
      try {
        const response = await api.tasks.fetchOneByToken(token)
        setTask(formatTaskResponse(response))
      } catch (e) {
        setError(e)
      }
      setIsLoading(false)
    }
    fetchTask()
  }, [])

  const handleToggleDone = useCallback(async () => {
    setIsLoading(true)

    try {
      const isDone = !task.isDone

      const response = await api.tasks.updateByToken(token, {
        isDone,
      })
      setTask(formatTaskResponse(response))

      if (isDone) {
        dispatch(setNotification(`Task completed`))
      } else {
        dispatch(setNotification(`Task changed to incomplete`))
      }
    } catch (e) {
      setError(e)
    }
    setIsLoading(false)
  }, [dispatch, task])

  return (
    <TasksScreen
      isLoading={isLoading}
      task={task}
      error={error}
      onToggleDone={handleToggleDone}
    />
  )
}

export default TasksScreenContainer
