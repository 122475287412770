import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { MARKETING_SITE_HOME_URL } from 'config'
import { applyPromoCode } from 'UserApp/store/promoCode/actions'
import { saveUserDetails } from 'UserApp/store/signup/actions'

function InviteContainer(props) {
  const { location, history } = props
  const [isDone, setIsDone] = useState(false)
  const [promoCodeNotes, setPromoCodeNotes] = useState()

  useEffect(() => {
    async function setUserDetails() {
      const { promo, email, firstName, lastName, sendNewsAndUpdates } =
        queryString.parse(location.search)

      if (promo) {
        const { applyPromoCode } = props
        const affiliate = await applyPromoCode(promo)

        if (affiliate) {
          setPromoCodeNotes(affiliate.additionalMessage)
        }
      }

      if (email || firstName || lastName || sendNewsAndUpdates) {
        const { saveUserDetails } = props
        await saveUserDetails({
          email,
          firstName,
          lastName,
          sendNewsAndUpdates,
        })
      }

      setIsDone(true)
    }

    setUserDetails()
  }, [location.search])

  if (isDone) {
    if (location.pathname === '/pricing') {
      history.push('/signup')
    } else {
      const url = MARKETING_SITE_HOME_URL
      window.location.replace(`${url}?simple=true&notes=${promoCodeNotes}`)
    }
  }

  return null
}

export default connect(null, { applyPromoCode, saveUserDetails })(
  InviteContainer,
)
