import React, { useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
}

Link.defaultProps = {
  variant: `normal`,
}

function Link(props) {
  const { children, className, variant, to, ...rest } = props
  const { color } = useContext(ColorContext)

  if (to) {
    return (
      <RouterLink
        className={classNames(
          style.wrapper,
          style[variant],
          style[color],
          className,
        )}
        to={to}
        {...rest}
      >
        {children}
      </RouterLink>
    )
  } else {
    return (
      <a
        className={classNames(
          style.wrapper,
          style[variant],
          style[color],
          className,
        )}
        {...rest}
      >
        {children}
      </a>
    )
  }
}

export default Link
