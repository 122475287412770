import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Form.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.func,
}

Form.defaultProps = {
  values: {},
}

function Form(props) {
  const { onSubmit, children, className, values: initialValues } = props
  const [values, setValues] = useState(initialValues)
  const [error, setError] = useState('')

  function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    setError('')

    onSubmit(values, () => setValues({})).catch((error) => {
      setError(error.message ? error.message : error)
    })
  }

  function handleChange({ name, value }) {
    setValues((values) => {
      const updatedValues = {
        ...values,
      }

      if (typeof value !== 'undefined') {
        updatedValues[name] = value
      }

      return updatedValues
    })
  }

  function handleAdd({ name, value }) {
    setValues((values) => ({
      ...values,
      [name]: [...(values[name] || []), value],
    }))
  }

  function handleError(error) {
    setError(error)
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(style.wrapper, className)}
    >
      {children({
        values,
        onChange: handleChange,
        onAdd: handleAdd,
        onError: handleError,
        error,
      })}
    </form>
  )
}

export default Form
