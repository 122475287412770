import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import DeclinedCheckOnPetsScreen from 'ContactApp/screens/CheckOnPets/Declined'

class DeclinedCheckOnPetsScreenContainer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  }

  state = {
    isLoading: true,
    user: undefined,
    error: '',
  }

  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { token } = params

    try {
      const { user } = await api.alarmContactRequests.fetchOne(token)
      this.setState({ isLoading: false, user })
    } catch (error) {
      this.setState({ isLoading: false, error })
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
  })

  render() {
    const props = this.generateProps()

    return <DeclinedCheckOnPetsScreen {...props} />
  }
}

export default DeclinedCheckOnPetsScreenContainer
