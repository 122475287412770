import { createSelector } from 'reselect'

const selectEntities = (state) => state.tasks.entities
const selectResult = (state) => state.tasks.result

export const selectAllActive = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result.map((id) => entities[id]).filter((entity) => !entity.isDone),
)

export const selectAllDone = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result.map((id) => entities[id]).filter((entity) => entity.isDone),
)

export const selectOne = (state, props) => state.tasks.entities[props.id]
