import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Notice.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function Notice(props) {
  const { children, className } = props

  return <div className={classNames(style.wrapper, className)}>{children}</div>
}

export default Notice
