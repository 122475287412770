import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

class PrivateRoute extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    component: PropTypes.object,
    requiresSubscription: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }

  static defaultProps = {
    requiresSubscription: false,
  }

  render() {
    const {
      user,
      component: Component,
      requiresSubscription,
      ...rest
    } = this.props

    if (user) {
      if (Component) {
        return (
          <Route
            {...rest}
            render={(props) => <Component user={user} {...props} />}
          />
        )
      } else {
        return <Route {...rest} />
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: '/signin',
            state: {
              notice: 'please login',
              originalPath: this.props.location.pathname,
            },
          }}
        />
      )
    }
  }
}

export default connect((state) => ({ user: state.user }))(PrivateRoute)
