import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ContactType } from 'types'
import { set as setNotification } from 'store/notification/actions'

import {
  remove,
  add,
  fetchOne,
  update,
  resendInvite,
} from 'UserApp/store/contacts/actions'
import { selectOne } from 'UserApp/store/contacts/selectors'
import ContactForm from 'UserApp/components/Contacts/Form'

class ContactFormContainer extends React.PureComponent {
  static propTypes = {
    add: PropTypes.func,
    fetchOne: PropTypes.func,
    id: PropTypes.string,
    onClose: PropTypes.func,
    remove: PropTypes.func,
    resendInvite: PropTypes.func,
    update: PropTypes.func,
    values: PropTypes.shape(ContactType),
    setNotification: PropTypes.func,
  }

  state = {
    didResendInvite: false,
    isConfirmingRemove: false,
    isLoading: !!this.props.id,
    isProcessing: false,
    customError: null,
  }

  componentDidMount() {
    if (this.props.id) {
      this.fetch()
    }
  }

  fetch = async () => {
    const { id, fetchOne } = this.props

    await fetchOne(id)

    this.setState({
      isLoading: false,
    })
  }

  handleSubmit = async (data) => {
    const { id, add, update, onClose, setNotification } = this.props
    // console.log(`CONTACT DATA :: `,data)

    const {firstName, lastName, email, phone}= data

    if(!firstName || firstName.trim() === ''){
      return this.setState({
        customError: 'First name is required',
      });
    }
    if(!lastName || lastName.trim() === ''){
      return this.setState({
        customError: 'Last name is required',
      });
    }
    if(!email || email.trim() === ''){
      return this.setState({
        customError: 'Email is required',
      });
    }
    if(!phone || phone.trim() === ''){
      return this.setState({
        customError: 'Phone number is required',
      });
    }

    this.setState({
      isProcessing: true,
      customError: null,
    })

    try {
      if (id) {
        await update(id, data)

        setNotification(`Contact updated`)
      } else {
        await add(data)

        setNotification(`Invitation sent`)
      }
      onClose()
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  handleRemove = () => this.setState({ isConfirmingRemove: true })

  handleCancelRemove = () => this.setState({ isConfirmingRemove: false })

  handleConfirmRemove = async () => {
    const { remove, onClose, id, setNotification } = this.props

    this.setState({
      isProcessing: true,
    })

    try {
      await remove(id)

      setNotification(`Contact removed`)

      onClose()
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  handleResendInvite = async () => {
    const { resendInvite, id } = this.props

    this.setState({
      isLoading: true,
    })

    try {
      await resendInvite(id)
      this.setState({
        isLoading: false,
        didResendInvite: true,
      })
    } catch (error) {
      this.setState({
        isLoading: false,
      })
      throw error
    }
  }

  generateProps = () => {
    const { id } = this.props

    return {
      ...this.state,
      ...this.props,
      onSubmit: this.handleSubmit,
      onRemove: this.handleRemove,
      onConfirmRemove: this.handleConfirmRemove,
      onCancelRemove: this.handleCancelRemove,
      onResendInvite: this.handleResendInvite,
      isEditing: !!id,
      customError: this.state.customError,
    }
  }

  render() {
    const props = this.generateProps()

    return <ContactForm {...props} />
  }
}

export default connect(
  (state, ownProps) => {
    return {
      values: selectOne(state, ownProps) || {
        isEmergencyContact: true,
      },
    }
  },
  {
    add,
    update,
    remove,
    fetchOne,
    setNotification,
    resendInvite,
  },
)(ContactFormContainer)
