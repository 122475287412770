import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  Loader,
  Label,
  Input,
  Button,
  Header,
  FieldGroup,
  Text,
  Fields,
} from '@pet-notify/ui'

import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'

type FormData = {
  firstName: string
  lastName: string
}

type Props = {
  onClose: () => void
}

function ChangeNameForm({ onClose }: Props) {
  const { setNotification } = useNotification()
  const { user, update } = useUser()
  const { register, ...form } = useForm<FormData>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  })

  const { mutate, isLoading, error, isError } = useMutation(
    (data: FormData) => update(data),
    {
      onSuccess: () => {
        setNotification('Name changed')
        onClose()
      },
    },
  )

  const handleSubmit = form.handleSubmit((values) => {
    mutate(values)
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header mb='6'>Change name</Header>
      <Fields>
        <FieldGroup>
          <Label htmlFor='firstName'>First name</Label>
          <Input
            type='text'
            id='firstName'
            {...register('firstName', { required: true })}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='lastName'>Last name</Label>
          <Input
            type='text'
            id='lastName'
            {...register('lastName', { required: true })}
          />
        </FieldGroup>

        {isError && <Text variant='error'>{error}</Text>}
      </Fields>
      <Button type='submit'>Change name</Button>
    </form>
  )
}
export default ChangeNameForm
