import React from 'react'
import PropTypes from 'prop-types'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'

VaultItemDietAndMedical.propTypes = {
  meta: PropTypes.object,
}

function VaultItemDietAndMedical(props) {
  const { meta } = props
  const {
    diet,
    medicalInformation,
    vetClinic,
    vetClinicPhone,
    emergencyClinic,
    emergencyClinicPhone,
    insurance,
  } = meta

  return (
    <div className={style.wrapper}>
      <Row>
        <RowElement.Title>Diet</RowElement.Title>
        <RowElement.Value>{diet}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Medical</RowElement.Title>
        <RowElement.Value>{medicalInformation}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Vet clinic</RowElement.Title>
        <RowElement.Value>{vetClinic}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Vet phone</RowElement.Title>
        <RowElement.Value>{vetClinicPhone}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Emergency clinic</RowElement.Title>
        <RowElement.Value>{emergencyClinic}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Emergency clinic phone</RowElement.Title>
        <RowElement.Value>{emergencyClinicPhone}</RowElement.Value>
      </Row>

      {insurance && (
        <React.Fragment>
          <Row>
            <RowElement.Title>Insurance Provider</RowElement.Title>
            <RowElement.Value>{insurance.insurer}</RowElement.Value>
          </Row>
          <Row>
            <RowElement.Title>Policy Type</RowElement.Title>
            <RowElement.Value>{insurance.policyType}</RowElement.Value>
          </Row>
          <Row>
            <RowElement.Title>Insurer Phone</RowElement.Title>
            <RowElement.Value>{insurance.phone}</RowElement.Value>
          </Row>
          <Row>
            <RowElement.Title>Policy #</RowElement.Title>
            <RowElement.Value>{insurance.policyNumber}</RowElement.Value>
          </Row>
          <Row>
            <RowElement.Title>Insurance notes</RowElement.Title>
            <RowElement.Value>{insurance.notes}</RowElement.Value>
          </Row>
        </React.Fragment>
      )}
    </div>
  )
}

export default VaultItemDietAndMedical
