import React from 'react'
import { Route, Switch } from 'react-router-dom'

import DeclinedCheckOnPetsScreen from 'ContactApp/containers/CheckOnPets/Declined'
import CheckOnPetsScreen from 'ContactApp/containers/CheckOnPets'
import PetSafetyScreen from 'ContactApp/containers/PetSafety'
import SuccessPetSafetyScreen from 'ContactApp/containers/PetSafety/Success'
import TasksScreen from 'ContactApp/containers/Tasks'
import InvitationScreen from 'ContactApp/containers/Invitation'
import InvitationDeclinedScreen from 'ContactApp/containers/Invitation/Declined'
import InvitationAcceptedScreen from 'ContactApp/containers/Invitation/Accepted'
import VaultAccessScreen from 'ContactApp/containers/Vault/Access'
import VaultDetailsScreen from 'ContactApp/containers/Vault/Details'
import ExpiredVaultScreen from 'ContactApp/screens/Vault/Expired'

function App() {
  return (
    <Switch>
      <Route path='/i/:token/declined' component={InvitationDeclinedScreen} />
      <Route path='/i/:token/accepted' component={InvitationAcceptedScreen} />
      <Route path='/i/:token' component={InvitationScreen} />
      <Route path='/s/:token/success' component={SuccessPetSafetyScreen} />
      <Route path='/s/:token' component={PetSafetyScreen} />
      <Route path='/t/:token' component={TasksScreen} />
      <Route
        path='/c/:token/vault/:id/details'
        component={VaultDetailsScreen}
      />
      <Route path='/c/:token/vault/:id' component={VaultAccessScreen} />
      <Route path='/c/:token/expired' component={ExpiredVaultScreen} />
      <Route path='/c/:token/declined' component={DeclinedCheckOnPetsScreen} />
      <Route path='/c/:token' component={CheckOnPetsScreen} />
    </Switch>
  )
}

export default App
