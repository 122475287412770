import { createContext } from 'react'

export const ColorContext = createContext(null)

export const COLORS = {
  ORANGE: 'orange',
  GRAPE: 'grape',
  MINT: 'mint',
  FOREST_GREEN: 'forestGreen',
}
