import React from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { Button, Loader } from '@pet-notify/ui'

import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'

function ReactivateSubscriptionForm() {
  const { setNotification } = useNotification()
  const { reactivateSubscription } = useUser()
  const form = useForm()

  const { mutate, isLoading } = useMutation(reactivateSubscription, {
    onSuccess: () => {
      setNotification('Your account has been reactivated to premium')
    },
  })

  const handleSubmit = form.handleSubmit(() => {
    mutate()
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button type='submit'>Reactivate subscription</Button>
    </form>
  )
}
export default ReactivateSubscriptionForm
