import React from 'react';
import useUser from 'UserApp/hooks/useUser';
import { Redirect } from 'react-router';

const withEmailVerification = (WrappedComponent : any) => {
  return (props : any) => {
    const { user } = useUser();

    if (user.isVerified === false || user.isVerified===null)
    {
      // User is not verified
      return <Redirect to="/verify-email" />;
    }
    
    // User is verified
    return <WrappedComponent {...props} />;
  };
};

export default withEmailVerification;