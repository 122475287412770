import React, { useState, useRef } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { Crop } from 'react-image-crop'
import { useDispatch } from 'react-redux'

import { update } from 'UserApp/store/pets/actions'
import HeaderOne from 'sharedComponents/HeaderOne'
import Button from 'sharedComponents/Button'
import Loader from 'sharedComponents/Loader'
import api from 'services/pet-notify-api'
import style from './style.module.scss'

function getCroppedImg(img, crop, fileName) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    // const img = new Image()

    // img.onload = function() {
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height
    canvas.width = Math.ceil(crop.width * scaleX)
    canvas.height = Math.ceil(crop.height * scaleY)
    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    )
    canvas.toBlob(
      (blob) => {
        blob.name = fileName // eslint-disable-line no-param-reassign
        // window.URL.revokeObjectURL(this.fileUrl);
        // this.fileUrl = window.URL.createObjectURL(blob);
        // resolve(window.URL.createObjectURL(blob))
        resolve(blob)
      },
      'image/jpeg',
      1,
    )
  })
}

function EditPetPhotoForm(props) {
  const { values, onSubmit, onRemove, id } = props
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    aspect: 3 / 2,
  })

  const imageRef = useRef(null)

  async function handleSave() {
    setIsLoading(true)
    const file = await getCroppedImg(imageRef.current, crop, 'newFile.jpg')

    const response = await api.photos.upload(file)

    if (id) {
      await dispatch(update(id, { photoId: response.id }))
    }
    setIsLoading(true)
    onSubmit(response)
  }

  function handleCancel() {
    onRemove()
  }

  function handleImageLoaded(image) {
    imageRef.current = image
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <HeaderOne>Edit pet photo</HeaderOne>
      <ReactCrop
        keepSelection
        onImageLoaded={handleImageLoaded}
        src={values}
        crop={crop}
        onChange={setCrop}
      />
      <div className={style.buttons}>
        <Button className={style.button} variant='hollow' onClick={handleSave}>
          Save
        </Button>
        <Button
          className={style.button}
          variant='red-hollow'
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </>
  )
}

export default EditPetPhotoForm
