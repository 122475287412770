import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'
import { DocumentType } from 'types'

import DocumentForm from 'UserApp/components/Pets/Form/DocumentForm'

const SUPPORTED_FILE_TYPES = [
  'application/pdf',
  'image/jpeg',
  'image/png',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

class DocumentFormContainer extends React.PureComponent {
  static propTypes = {
    onRemove: PropTypes.func,
    onSubmit: PropTypes.func,
    values: PropTypes.shape(DocumentType),
  }

  state = { isLoading: false, error: '', file: undefined, name: '' }

  constructor(props) {
    super(props)

    if (props.values) {
      const { name, extension } = props.values

      this.state = {
        file: {
          name: extension,
        },
        name,
      }
    }
  }

  handleChangeName = ({ value }) => this.setState({ name: value })

  handleSelectFile = async (event) => {
    const { files } = event.target
    const [file] = files
    const { type } = file

    if (!SUPPORTED_FILE_TYPES.includes(type)) {
      this.setState({
        error: `That file type is not supported`,
        file: undefined,
      })
    } else {
      const name = file.name.split('.')
      name.pop()
      this.setState({
        name: name.join(''),
        error: '',
        file,
      })
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { onSubmit, values = {} } = this.props
    const { file, name } = this.state

    this.setState({
      isLoading: true,
    })

    try {
      const { id } = values

      let response

      if (id) {
        response = await api.documents.update(id, { name })
      } else {
        response = await api.documents.upload(file, name)
      }

      this.setState({
        isLoading: false,
      })

      onSubmit(response)
    } catch (e) {
      console.log(e)
      this.setState({
        error: e,
        isLoading: false,
      })
    }
  }

  handleRemove = async () => {
    const { onRemove, values } = this.props
    const { id } = values

    this.setState({
      isLoading: true,
    })

    try {
      await api.documents.remove(id)

      this.setState({
        isLoading: false,
      })

      onRemove()
    } catch (e) {
      console.log(e)
      this.setState({
        isLoading: false,
      })
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSelectFile: this.handleSelectFile,
    onChangeName: this.handleChangeName,
    onRemove: this.handleRemove,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()

    return <DocumentForm {...props} />
  }
}

export default DocumentFormContainer
