import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'components/Forms'
import HeaderThree from 'components/Headers/HeaderThree'
import { FORMS } from 'UserApp/containers/Pets/constants'
import List from '../List'
import Text from 'components/Text'
import style from './style.module.scss'

DocumentsAndExtrasForm.propTypes = {
  onChangeForm: PropTypes.func,
  values: PropTypes.object,
}

function DocumentsAndExtrasForm(props) {
  const { values, onChangeForm } = props

  const { documents = [], meta } = values
  const { careProviders = [], techDevices = [] } = meta

  return (
    <React.Fragment>
      <HeaderThree className={style.header}>Documents</HeaderThree>
      {documents.length > 0 && (
        <List
          items={documents}
          onChangeForm={onChangeForm}
          form={FORMS.DOCUMENT}
        />
      )}
      <Button
        className={style.button}
        onClick={() => onChangeForm(FORMS.DOCUMENT)}
        variant='hollow'
        small
      >
        Add Document
      </Button>
      <Text className={style.note} variant='note'>
        Documents often include things like vet records, pet insurance and
        registration.
      </Text>

      <HeaderThree className={style.header}>Care providers</HeaderThree>
      {careProviders.length > 0 && (
        <List
          items={careProviders}
          onChangeForm={onChangeForm}
          form={FORMS.CARE_PROVIDER}
        />
      )}
      <Button
        className={style.button}
        onClick={() => onChangeForm(FORMS.CARE_PROVIDER)}
        variant='hollow'
        small
      >
        Add Care Provider
      </Button>
      <Text className={style.note} variant='note'>
        Care providers include groomers, dog walkers, and day cares.
      </Text>
      <HeaderThree className={style.header}>Tech devices</HeaderThree>
      {techDevices.length > 0 && (
        <List
          items={techDevices}
          onChangeForm={onChangeForm}
          form={FORMS.TECH_DEVICE}
        />
      )}

      <Button
        className={style.button}
        onClick={() => onChangeForm(FORMS.TECH_DEVICE)}
        variant='hollow'
        small
      >
        Add Device
      </Button>
      <Text className={style.note} variant='note'>
        Devices include things like GPS collars, auto-feeders and cameras.
      </Text>
    </React.Fragment>
  )
}

export default DocumentsAndExtrasForm
