import React from 'react'
import classNames from 'classnames'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import possessive from 'lib/possessive'
import { PetType, UserType } from 'types'
import Columns from 'components/Layout/Columns'
import Column from 'components/Layout/Column'
import Loader from 'components/Loader'
import Text from 'components/Text'
import HeaderTwo from 'components/Headers/HeaderTwo'
import HeaderThree from 'components/Headers/HeaderThree'
import { UpdatedButton as Button } from 'components/Forms'

import VaultAccessExpires from 'ContactApp/components/VaultAccessExpires'
import Layout from 'ContactApp/components/Layout/VaultLayout'
import VaultItemGeneral from 'ContactApp/components/Vault/Item/General'
import VaultItemDietAndMedical from 'ContactApp/components/Vault/Item/DietAndMedical'
import VaultItemDocuments from 'ContactApp/components/Vault/Item/Documents'
import VaultItemCareProviders from 'ContactApp/components/Vault/Item/CareProviders'
import VaultItemTechDevices from 'ContactApp/components/Vault/Item/TechDevices'

import style from './style.module.scss'

VaultDetailsScreen.propTypes = {
  didDecline: PropTypes.bool,
  isExpired: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSafe: PropTypes.bool,
  onCancel: PropTypes.func,
  onSafe: PropTypes.func,
  pets: PropTypes.arrayOf(PropTypes.shape(PetType)),
  token: PropTypes.string,
  user: PropTypes.shape(UserType),
  vaultAccess: PropTypes.object,
}

function VaultDetailsScreen(props) {
  const {
    didDecline,
    isExpired,
    isLoading,
    isSafe,
    onCancel,
    onSafe,
    pets,
    token,
    user,
    vaultAccess,
  } = props

  if (isExpired) {
    return <Redirect to={`/c/${token}/expired`} push />
  }

  if (didDecline) {
    return <Redirect to={`/c/${token}/declined`} push />
  }

  if (isSafe) {
    return <Redirect to={`/s/${token}/success`} push />
  }

  if (isLoading) {
    return <Loader />
  }

  const {
    name,
    accessInformation,
    address,
    city,
    state,
    zip,
    country,
    locationDetails,
    lastKnownLocation,
  } = user

  const { accessExpires } = vaultAccess

  return (
    <Layout onCancel={onCancel} onSafe={onSafe}>
      <Columns>
        <Column className={style.petDetails}>
          <div className={style.mobileButtons}>
            <Button onClick={onCancel} variant='hollowRed'>
              Cancel, I’m unavailable
            </Button>
            <Button className={style.safeButton} onClick={onSafe}>
              Mark pets as safe
            </Button>
          </div>
          <HeaderTwo className={style.mainHeader}>
            {possessive(name)} Pet Vault
          </HeaderTwo>

          <VaultAccessExpires className={style.accessExpires}>
            {accessExpires}
          </VaultAccessExpires>

          {pets.map((pet) => (
            <React.Fragment key={pet.id}>
              <VaultItemGeneral {...pet} />
              <HeaderTwo className={style.header}>Diet & Medical</HeaderTwo>
              <VaultItemDietAndMedical {...pet} />

              {pet.documents && pet.documents.length > 0 && (
                <React.Fragment>
                  <HeaderTwo className={style.header}>Documents</HeaderTwo>
                  <VaultItemDocuments documents={pet.documents} />
                </React.Fragment>
              )}

              {pet.meta.careProviders && pet.meta.careProviders.length > 0 && (
                <React.Fragment>
                  <HeaderTwo className={style.header}>Care Providers</HeaderTwo>
                  <VaultItemCareProviders
                    careProviders={pet.meta.careProviders}
                  />
                </React.Fragment>
              )}

              {pet.meta.techDevices && pet.meta.techDevices.length > 0 && (
                <React.Fragment>
                  <HeaderTwo className={style.header}>Tech Devices</HeaderTwo>
                  <VaultItemTechDevices techDevices={pet.meta.techDevices} />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Column>
        <Column className={style.userDetails}>
          {address && (
            <React.Fragment>
              <HeaderThree className={style.header}>Address</HeaderThree>
              <Text
                className={classNames(style.address, style.text)}
              >{`${address}\n${city}, ${state} ${zip}\n ${country}`}</Text>
            </React.Fragment>
          )}
          {accessInformation && (
            <React.Fragment>
              <HeaderThree className={style.header}>
                Emergency access
              </HeaderThree>
              <Text className={style.text}>{accessInformation}</Text>
            </React.Fragment>
          )}
          {locationDetails && (
            <React.Fragment>
              <HeaderThree className={style.header}>
                Location details
              </HeaderThree>
              <Text className={style.text}>{locationDetails}</Text>
            </React.Fragment>
          )}
          {lastKnownLocation && (
            <React.Fragment>
              <HeaderThree className={style.header}>
                Last known location
              </HeaderThree>
              <div className={style.map}>
                <img
                  src={lastKnownLocation.mapImage}
                  width='336'
                  height='336'
                />
              </div>
              <Button
                variant='hollow'
                href={`https://www.google.com/maps/search/?api=1&query=${lastKnownLocation.lat},${lastKnownLocation.long}`}
                target='_blank'
              >
                View in Google Maps
              </Button>
            </React.Fragment>
          )}
        </Column>
      </Columns>
    </Layout>
  )
}
export default VaultDetailsScreen
