import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Header from './Header'
import style from './style.module.scss'

VaultLayout.propTypes = {
  children: PropTypes.node,
}

function VaultLayout(props) {
  const { children, ...rest } = props

  return (
    <React.Fragment>
      <Header {...rest} />
      <div className={style.wrapper}>
        <div className={classNames(style.inner)}>{children}</div>
      </div>
    </React.Fragment>
  )
}

export default VaultLayout
