import React, { ReactNode } from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

type Props = {
  variant?: 'note'
  children: ReactNode
  className?: string
}

function Text(props: Props) {
  const { variant = 'default', children, className } = props

  return (
    <p className={classNames(style.wrapper, className, style[variant])}>
      {children}
    </p>
  )
}

export default React.memo(Text)
