import React from 'react'

import HeaderOne from 'sharedComponents/HeaderOne'
import Button from 'sharedComponents/Button'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

type Props = {
  isMobile: boolean
}

function Success(props: Props) {
  const { isMobile } = props

  return (
    <>
      <HeaderOne className={style.header}>Order successful</HeaderOne>

      <Text className={style.text}>
        Your intellitag will arrive in 5-7 business days
      </Text>

      <Button
        className={style.button}
        to={!isMobile ? '/' : undefined}
        href={isMobile ? 'petnotify:///close' : undefined}
      >
        Back to Pet Vault
      </Button>
    </>
  )
}

export default Success
