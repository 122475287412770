import { normalize } from 'normalizr'

import mixpanel, { EVENTS } from 'services/mix-panel'
import schema from './schema'

export const FETCHED_ALL = 'TASKS/FETCHED_ALL'
export const FETCHED_ONE = 'TASKS/FETCHED_ONE'
export const UPDATE = 'TASKS/UPDATE'
export const ADD = 'TASKS/ADD'
export const REMOVE = 'TASKS/REMOVE'

export function fetchAll() {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.fetchAll()
    dispatch({
      type: FETCHED_ALL,
      payload: normalize(response, [schema]),
    })
    return response
  }
}

export function clearTaskHistory() {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.clearTaskHistory()
    dispatch({
      type: FETCHED_ALL,
      payload: normalize(response, [schema]),
    })
    return response
  }
}

export function add(params) {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.add(params)

    mixpanel.track(EVENTS.TASK_ADDED)

    dispatch({
      type: ADD,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function fetchOne(id) {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.fetchOne(id)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function update(id, params) {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.update(id, params)
    dispatch({
      type: UPDATE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function remove(id) {
  return async (dispatch, getState, api) => {
    const response = await api.tasks.remove(id)
    dispatch({
      type: REMOVE,
      payload: normalize(response, schema),
    })
    return response
  }
}
