import React from 'react'
import PropTypes from 'prop-types'

import RowElement, * as Row from 'UserApp/components/Row'
import Link from 'components/Link'
import style from './style.module.scss'

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onChangeForm: PropTypes.func,
  label: PropTypes.func,
  form: PropTypes.string,
}

function List(props) {
  const {
    items,
    onChangeForm,
    form,
    label = (item) => item.name || item.type,
  } = props

  return (
    <div className={style.wrapper}>
      {items.map((item, index) => (
        <RowElement key={item.name}>
          <Row.Value>
            <Link onClick={() => onChangeForm(form, index)}>{label(item)}</Link>
          </Row.Value>
        </RowElement>
      ))}
    </div>
  )
}

export default List
