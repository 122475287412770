import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './style.module.scss'

Pill.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
}

Pill.defaultProps = {
  variant: `normal`,
}

function Pill(props) {
  const { variant, children, className } = props

  return (
    <div className={classNames(style.wrapper, style[variant], className)}>
      {children}
    </div>
  )
}

export default Pill
