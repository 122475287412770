import { useReducer } from 'react'

const ADD_INTELLITAG = 'ADD_INTELLITAG'
const REMOVE_INTELLITAG = 'REMOVE_INTELLITAG'
const CHANGE_INTELLITAG = 'CHANGE_INTELLITAG'

const DEFAULT_INTELLITAG = { size: 'small', maxCharacters: 12 }

function reducer(state, action) {
  const { payload, type } = action

  switch (type) {
    case ADD_INTELLITAG:
      return [...state, { ...DEFAULT_INTELLITAG }]

    case REMOVE_INTELLITAG:
      return state.filter((_el, i) => i !== payload)

    case CHANGE_INTELLITAG:
      const { index, name, value } = payload
      return state.map((el, i) => {
        if (index === i) {
          const maxCharacters = el.size === 'small' ? 12 : 15
          switch (name) {
            case 'nameOnTag':
              return {
                ...el,
                nameOnTag: value.toUpperCase(),
                maxCharacters,
                nameTooLong: value.length > maxCharacters,
              }
            default:
              return {
                ...el,
                [name]: value,
              }
          }
        }
        console.log('el', el)

        return el
      })
  }
}

function useIntellitags(defaultValues) {
  const [intellitags, dispatch] = useReducer(
    reducer,
    defaultValues || [{ ...DEFAULT_INTELLITAG }],
  )

  function handleAdd() {
    dispatch({
      type: ADD_INTELLITAG,
    })
  }

  function handleRemove(index) {
    dispatch({
      type: REMOVE_INTELLITAG,
      payload: index,
    })
  }

  function handleChange(payload) {
    dispatch({
      type: CHANGE_INTELLITAG,
      payload,
    })
  }

  function handleValidate() {
    let hasErrors = false

    intellitags.forEach((intellitag, index) => {
      if (!intellitag.type || intellitag.type === '') {
        hasErrors = true
        handleChange({
          name: 'error',
          value: 'Pet type is required',
          index,
        })
        return
      }

      if (!intellitag.name || intellitag.name === '') {
        hasErrors = true
        handleChange({
          name: 'error',
          value: 'Pet name is required',
          index,
        })
        return
      }

      if (!intellitag.weight || intellitag.weight === '') {
        hasErrors = true
        handleChange({
          name: 'error',
          value: 'Pet weight is required',
          index,
        })
        return
      }

      if (!intellitag.nameOnTag || intellitag.nameOnTag === '') {
        hasErrors = true
        handleChange({
          name: 'error',
          value: 'Engraving name is required',
          index,
        })
        return
      }

      handleChange({
        name: 'error',
        value: null,
        index,
      })
    })

    return !hasErrors
  }

  return {
    intellitags,
    onAdd: handleAdd,
    onChange: handleChange,
    onRemove: handleRemove,
    onValidate: handleValidate,
  }
}

export default useIntellitags
