import React from 'react'

import Layout from 'sharedComponents/Layout/Split'
import countries from 'lib/countries'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'
import Loader from 'sharedComponents/Loader'
import Text from 'sharedComponents/Text'
import { NewForm as Form, Dropdown, StateLabel } from 'components/Forms'
import Error from 'sharedComponents/Error'
import Button from 'sharedComponents/Button'
import Label from 'sharedComponents/Label'
import Input from 'sharedComponents/Input'
import PhoneInput from 'sharedComponents/Input/Phone'
import HeaderOne from 'sharedComponents/HeaderOne'
import Steps from 'UserApp/components/Steps'
import MobileHeaderImage from '../../MobileHeaderImage'
import mixPanel, { EVENTS } from 'services/mix-panel'

type Props = {
  wasSubmitted: boolean
  isLoading: boolean
  onSubmit: () => void
}

function SignupShippingScreen(props: Props) {
  const { onSubmit, isLoading, defaultValues } = props

  return (
    <Layout>
      <MobileHeaderImage step={3} />
      <Steps currentStep={3} totalSteps={4} />
      <HeaderOne>Shipping address</HeaderOne>
      <Text>
        Your Intellitag will arrive within 5-7 business days. Please allow 10-14
        business days for international orders.
      </Text>

      <Form onSubmit={onSubmit} defaultValues={defaultValues}>
        {({ onChange, values, error }) =>
          isLoading ? (
            <Loader />
          ) : (
            <>
              <Label htmlFor='address-address'>Address</Label>
              <Input
                name='address'
                value={values.address}
                onChange={onChange}
                id='address-address'
              />
              <Label htmlFor='address-address2'>Apartment / Suite</Label>
              <Input
                name='address2'
                value={values.address2}
                onChange={onChange}
                id='address-address2'
              />
              <Label htmlFor='address-city'>City</Label>
              <Input
                name='city'
                value={values.city}
                onChange={onChange}
                id='address-city'
              />
              <Label htmlFor='address-country'>Country</Label>
              <Dropdown
                name='country'
                options={countries}
                id='address-country'
                value={values.country}
                onChange={onChange}
              />

              <StateLabel htmlFor='address-state' country={values.country} />
              {!values.country ||
              values.country === 'US' ||
              values.country === 'CA' ? (
                <Dropdown
                  includeBlank
                  name='state'
                  options={
                    values.country === 'CA' ? canadianProvinces : usStates
                  }
                  id='address-state'
                  value={values.state}
                  onChange={onChange}
                />
              ) : (
                <Input
                  type='text'
                  name='state'
                  id='address-state'
                  value={values.state}
                  onChange={onChange}
                />
              )}

              <Label htmlFor='address-zip'>
                {values.country === 'US' ? `Zip` : `Postal Code`}
              </Label>
              <Input
                type='text'
                name='zip'
                id='address-zip'
                value={values.zip}
                onChange={onChange}
              />

              <Label htmlFor='address-phone'>Mobile phone</Label>
              <PhoneInput
                type='text'
                name='phone'
                id='address-phone'
                value={values.phone}
                onChange={onChange}
              />

              {error && <Error>{error}</Error>}

              <Button
                type='submit'
                onClick={() => mixPanel.track(EVENTS.SHIPPING_ADDRESS_CLICK)}
              >
                Continue
              </Button>
            </>
          )
        }
      </Form>
    </Layout>
  )
}

export default SignupShippingScreen
