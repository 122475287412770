import {
  SET_INTELLITAGS,
  SET_SHIPPING_ADDRESS,
  ORDER_SUBMITTED,
} from './actions'

const LOCAL_STORAGE_KEY = 'intellitag-order'
const localVersion = window.localStorage.getItem(LOCAL_STORAGE_KEY)

export default function reducer(
  state = localVersion ? JSON.parse(localVersion) : {},
  action,
) {
  const updatedState = ((action) => {
    const { payload, type } = action

    switch (type) {
      case SET_INTELLITAGS:
        return {
          ...state,
          intellitags: payload,
        }
      case SET_SHIPPING_ADDRESS:
        return {
          ...state,
          shippingAddress: payload,
        }
      case ORDER_SUBMITTED:
        return {}
      default:
        return state
    }
  })(action)

  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedState))
  return updatedState
}
