import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { AlarmType } from 'types'
import { fetchOne, triggerSOS } from 'UserApp/store/alarm/actions'
import { selectAlarm } from 'UserApp/store/alarm/selectors'
import { selectCount as selectPetCount } from 'UserApp/store/pets/selectors'
import { selectField } from 'UserApp/store/user/selectors'
import AlarmScreen from 'UserApp/screens/Alarm'

class AlarmScreenContainer extends React.PureComponent {
  static propTypes = {
    alarm: PropTypes.shape(AlarmType),
    fetchOne: PropTypes.func,
  }

  state = {
    isLoading: true,
  }

  async componentDidMount() {
    const { fetchOne } = this.props
    try {
      await fetchOne()
    } catch (e) {
      // no alarm
    }
    this.setState({ isLoading: false })
  }

  handleTriggerSOS = async () => {
    const { triggerSOS, history } = this.props
    this.setState({ isLoading: true })

    try {
      await triggerSOS()
    } catch (e) {
      // no alarm
    }

    history.push('/alarm')
    this.setState({ isLoading: false })
  }

  generateProps = () => {
    const isGoingOff =
      this.props.alarm &&
      (this.props.alarm.isFiring ||
        this.props.alarm.isExpired ||
        this.props.alarm.isComplete)
    return {
      ...this.props,
      ...this.state,
      onTriggerSOS: this.handleTriggerSOS,
      isGoingOff,
      hasAlarm: this.props.alarm,
    }
  }

  render() {
    const props = this.generateProps()

    return <AlarmScreen {...props} />
  }
}

export default connect(
  (state) => ({
    locationDetails: selectField(state, `locationDetails`),
    alarm: selectAlarm(state),
    petCount: selectPetCount(state),
  }),
  { fetchOne, triggerSOS },
)(AlarmScreenContainer)
