import React from 'react'
import PropTypes from 'prop-types'

import HeaderOne from 'components/Headers/HeaderOne'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Text from 'components/Text'
import { ErrorText, Button } from 'components/Forms'

import { FORMS } from 'UserApp/containers/Pets/constants'
import DocumentForm from 'UserApp/containers/Pets/Form/DocumentForm'
import BackLink from 'UserApp/components/BackLink'

import IntellitagForm from './IntellitagForm'
import EditPetPhotoForm from './EditPetPhotoForm'
import DietAndMedicalForm from './DietAndMedicalForm'
import DocumentsAndExtrasForm from './DocumentsAndExtrasForm'
import GeneralForm from './GeneralForm'
import CareProviderForm from './CareProviderForm'
import TechDeviceForm from './TechDeviceForm'
import ChipIDForm from './ChipIDForm'
import InsuranceForm from './InsuranceForm'
import VetClinicForm from './VetClinicForm'
import Tabs from './Tabs'
import { PetType } from 'types'
import style from './style.module.scss'

PetForm.propTypes = {
  collectionValues: PropTypes.object,
  currentForm: PropTypes.string,
  error: PropTypes.string,
  isConfirmingRemove: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onCancelRemove: PropTypes.func,
  onChange: PropTypes.func,
  onChangeForm: PropTypes.func,
  onCollectionFormSubmit: PropTypes.func,
  onCollectionRemove: PropTypes.func,
  onConfirmRemove: PropTypes.func,
  onError: PropTypes.func,
  onGoBack: PropTypes.func,
  onRemove: PropTypes.func,
  onSkip: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.shape(PetType),
}

function getTitle(props) {
  const { isEditing, currentForm, title } = props

  if (title) {
    return title
  }

  if (currentForm === FORMS.EDIT_PET_PHOTO) {
    return `Edit pet photo`
  }

  if (isEditing) {
    return `Edit Pet`
  }

  switch (currentForm) {
    case FORMS.GENERAL:
      return `General information`
    case FORMS.DIET_AND_MEDICAL:
      return `Diet & Medical`
    case FORMS.DOCUMENTS_AND_EXTRAS:
      return `Documents & extras`
  }
}

function getButtonText(props) {
  const { currentForm, isEditing } = props

  if (isEditing) {
    return `Save`
  }

  if (currentForm === FORMS.DOCUMENTS_AND_EXTRAS) {
    return `Finish`
  } else {
    return `Save & Continue`
  }
}

function getCurrentForm(props) {
  const { currentForm } = props

  switch (currentForm) {
    case FORMS.GENERAL:
      return GeneralForm
    case FORMS.INTELLITAG:
      return IntellitagForm
    case FORMS.EDIT_PET_PHOTO:
      return EditPetPhotoForm
    case FORMS.DIET_AND_MEDICAL:
      return DietAndMedicalForm
    case FORMS.DOCUMENTS_AND_EXTRAS:
      return DocumentsAndExtrasForm
    case FORMS.DOCUMENT:
      return DocumentForm
    case FORMS.CARE_PROVIDER:
      return CareProviderForm
    case FORMS.TECH_DEVICE:
      return TechDeviceForm
    case FORMS.CHIP_ID:
      return ChipIDForm
    case FORMS.INSURANCE:
      return InsuranceForm
    case FORMS.VET_CLINIC:
      return VetClinicForm
  }
}

function getIsCollectionForm(props) {
  const { currentForm } = props

  return (
    currentForm === FORMS.EDIT_PET_PHOTO ||
    currentForm === FORMS.DOCUMENT ||
    currentForm === FORMS.CARE_PROVIDER ||
    currentForm === FORMS.TECH_DEVICE ||
    currentForm === FORMS.CHIP_ID ||
    currentForm === FORMS.INSURANCE ||
    currentForm === FORMS.VET_CLINIC
  )
}

function getShowButtons(props) {
  const { isEditing, currentForm } = props

  return isEditing && currentForm !== FORMS.EDIT_PET_PHOTO
}

function PetForm(props) {
  const {
    id,
    collectionValues,
    currentForm,
    error,
    isConfirmingRemove,
    isEditing,
    isLoading,
    isProcessing,
    onCancelRemove,
    onChange,
    onChangeForm,
    onCollectionFormSubmit,
    onCollectionRemove,
    onConfirmRemove,
    onError,
    onGoBack,
    onRemove,
    onSkip,
    onSubmit,
    values,
    disableRemove,
    onPetPhotoUpload,
    hasSubscription,
    user,
  } = props

  if (isLoading) {
    return <Loader />
  }

  const title = getTitle(props)
  const buttonText = getButtonText(props)
  const CurrentForm = getCurrentForm(props)
  const isCollectionForm = getIsCollectionForm(props)
  const showButtons = getShowButtons(props)

  return (
    <div className={style.wrapper}>
      {(!isEditing || isCollectionForm) && onGoBack && (
        <BackLink onClick={onGoBack} className={style.backLink}>
          Back
        </BackLink>
      )}

      {isCollectionForm ? (
        <CurrentForm
          id={id}
          onRemove={onCollectionRemove}
          onSubmit={onCollectionFormSubmit}
          values={collectionValues}
        />
      ) : (
        <React.Fragment>
          <HeaderOne>{title}</HeaderOne>
          {values.intellitags?.length === 0 &&
            !values.inactiveIntellitag &&
            (!values.photo || !values.meta.intellitagInstructions) && (
              <div className={style.alert}>
                Your Intellitag lost pet instructions and/or pet's photo are
                incomplete and are required to provide automated pet-care and
                emergency contact notifications if your pet becomes lost.
              </div>
            )}
          {showButtons && (
            <Tabs currentForm={currentForm} onChangeForm={onChangeForm} />
          )}

          <form onSubmit={onSubmit} className={style.form}>
            {isProcessing ? (
              <Loader />
            ) : (
              <React.Fragment>
                <CurrentForm
                  onChange={onChange}
                  onChangeForm={onChangeForm}
                  values={values}
                  onError={onError}
                  isEditing={isEditing}
                  onPetPhotoUpload={onPetPhotoUpload}
                  hasSubscription={hasSubscription}
                  user={user}
                />
                {error && <ErrorText>{error}</ErrorText>}

                {isEditing ? (
                  isConfirmingRemove ? (
                    <React.Fragment>
                      <Text>Are you sure you want to remove this pet?</Text>
                      <Button
                        onClick={onCancelRemove}
                        variant='hollowRed'
                        small
                        className={style.button}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onConfirmRemove}
                        variant='red'
                        small
                        className={style.button}
                      >
                        Remove
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {showButtons && (
                        <Button type='submit' small className={style.button}>
                          Save
                        </Button>
                      )}

                      {showButtons && !disableRemove && (
                        <Button onClick={onRemove} variant='hollowRed' small>
                          Remove Pet
                        </Button>
                      )}
                    </React.Fragment>
                  )
                ) : (
                  <Button type='submit' className={style.button}>
                    {buttonText}
                  </Button>
                )}

                {onSkip && <Link onClick={onSkip}>Skip, I’ll do it later</Link>}
              </React.Fragment>
            )}
          </form>
        </React.Fragment>
      )}
    </div>
  )
}

export default PetForm
