import React from 'react'

import style from './style.module.scss'

type Props = {
  currentStep: number
  totalSteps: number
}

function Steps(props: Props) {
  const { currentStep, totalSteps } = props

  return (
    <div className={style.wrapper}>
      Step {currentStep} of {totalSteps}
    </div>
  )
}

export default Steps
