import React from 'react'

import Column from 'components/Layout/Column'
import Columns from 'components/Layout/Columns'
import HeaderThree from 'components/Headers/HeaderThree'
import Layout from 'UserApp/components/Layout'
import Text from 'components/Text'

import AlarmContacts from 'UserApp/containers/Alarm/Contacts'
import AlarmBanner from 'UserApp/containers/Alarm/Banner'
import LocationDetailsForm from 'UserApp/containers/Alarm/LocationDetailsForm'

import style from './style.module.scss'

function AlarmReadyScreen() {
  return (
    <Layout banner={() => <AlarmBanner />}>
      <Columns>
        <Column className={style.contactsColumn}>
          <HeaderThree className={style.subHeader}>
            When the alarm goes off, we'll start to notify:
          </HeaderThree>
          <AlarmContacts />
        </Column>
        <Column>
          <HeaderThree className={style.subHeader}>
            Where are you going?
          </HeaderThree>
          <Text variant='small' className={style.text}>
            We’ll share this with your emergency contacts if your alarm goes off
            and you don’t respond.
          </Text>
          <LocationDetailsForm />
        </Column>
      </Columns>
    </Layout>
  )
}

export default AlarmReadyScreen
