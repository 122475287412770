import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import CheckOnPetsScreen from 'ContactApp/screens/CheckOnPets'

class CheckOnPetsScreenContainer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  }

  state = {
    isLoading: true,
    response: undefined,
    user: undefined,
    error: '',
  }

  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { token } = params

    try {
      const { user, alarm, response } = await api.alarmContactRequests.fetchOne(
        token,
      )

      this.setState({ isLoading: false, user, alarm, response })
    } catch (error) {
      this.setState({ isLoading: false, error })
    }
  }

  handleAccept = async () => {
    return this.handleSubmit('accepted')
  }

  handleDecline = async () => {
    return this.handleSubmit('declined')
  }

  handleSubmit = async (response) => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      await api.alarmContactRequests[response](token)
      this.setState({ response })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  generateProps = () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    return {
      ...this.props,
      ...this.state,
      token,
      onAccept: this.handleAccept,
      onDecline: this.handleDecline,
    }
  }

  render() {
    const props = this.generateProps()

    return <CheckOnPetsScreen {...props} />
  }
}

export default CheckOnPetsScreenContainer
