import React from 'react'
import PropTypes from 'prop-types'

import possessive from 'lib/possessive'
import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import { ErrorText, Button } from 'components/Forms'
import style from './style.module.scss'

DeclinedCheckOnPetsScreen.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
}

function DeclinedCheckOnPetsScreen(props) {
  const { user, isLoading, error } = props

  if (isLoading) {
    return <Loader />
  }

  const { firstName } = user

  return (
    <Layout showNav={false}>
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <React.Fragment>
          <HeaderOne className={style.header}>
            Thanks for responding, we’ll ask {possessive(firstName)} next
            emergency contact.
          </HeaderOne>
          <Button href='https://www.petnotify.com/' target='_blank'>
            Learn more about Pet Notify
          </Button>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default DeclinedCheckOnPetsScreen
