import React from 'react'

import Link from 'components/Link'
import { ContactType } from 'types'
import style from './style.module.scss'

ContactLink.propTypes = {
  ...ContactType,
}

function ContactLink(props) {
  const { id, firstName, lastName } = props

  return (
    <Link to={`/contacts/${id}`} className={style.wrapper}>
      {firstName} {lastName}
    </Link>
  )
}

export default ContactLink
