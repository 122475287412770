import { FETCHED as FETCHED_USER } from '../user/actions'
import { FETCHED_ALL, FETCHED_ONE, UPDATE, ADD, REMOVE } from './actions'

export default function reducer(
  state = { entities: {}, result: [] },
  action = {},
) {
  const { payload = {}, type } = action
  const { result, entities } = payload

  switch (type) {
    case FETCHED_USER:
      return {
        ...state,
        entities: {
          ...entities.pets,
        },
        result: [...entities.user[result].pets],
      }
    case FETCHED_ALL:
      return {
        ...state,
        entities: {
          ...entities.pets,
        },
        result: [...result],
      }
    case UPDATE:
    case FETCHED_ONE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [result]: entities.pets[result],
        },
      }
    case ADD:
      return {
        ...state,
        entities: {
          ...state.entities,
          [result]: entities.pets[result],
        },
        result: [...state.result, result],
      }
    case REMOVE:
      return {
        ...state,
        result: [...state.result.filter((id) => id !== result)],
      }
    default:
      return state
  }
}
