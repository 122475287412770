import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import api from 'services/pet-notify-api'
import Layout from 'components/Layout/SplitLayout'
import {
  Header,
  Text,
  Label,
  Input,
  Button,
  FieldGroup,
  Loader,
  Fields,
} from '@pet-notify/ui'
import { useHistory } from 'react-router'

function ForgotPasswordScreen() {
  const history = useHistory()
  const { register, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const onSubmit = handleSubmit(async ({ email }) => {
    setIsLoading(true)
    try {
      await api.users.forgotPassword({ email })
      history.push({
        pathname: '/forgot-password/check-email',
        state: { email },
      })
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <Layout alternateBackground2>
      <Header as='h1' size='xxlarge' mb='8'>
        Forgot Password
      </Header>

      <form onSubmit={onSubmit} className='mb-8'>
        <Fields>
          <FieldGroup>
            <Label htmlFor='forgot-password-email'>Email</Label>
            <Input
              type='email'
              id='forgot-password-email'
              {...register('email')}
            />
          </FieldGroup>
          <Text>
            We will send you an email with instructions on how to reset your
            password.
          </Text>
          {error && <Text variant='error'>{error}</Text>}
        </Fields>

        {isLoading ? <Loader /> : <Button type='submit'>Reset password</Button>}
      </form>
    </Layout>
  )
}
export default ForgotPasswordScreen
