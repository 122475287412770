import React, { ReactNode } from 'react'
import classNames from 'classnames'

import Label from '../Label'
import style from './style.module.scss'

type Props = {
  name: string
  value: string
  variant?: 'small'
  checked: boolean
  label?: string
  id: string
  onChange: (formField: FormField) => void
  children?: ReactNode
  className?: string
}

function RadioButton(props: Props) {
  const {
    name,
    value,
    checked,
    onChange,
    label,
    id,
    children,
    className,
    variant = 'normal',
  } = props

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget

    if (onChange) {
      onChange({ name, value })
    }
  }

  return (
    <div className={classNames(style.wrapper, style[variant], className)}>
      <input
        className={style.radioButton}
        type='radio'
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <Label htmlFor={id} className={style.label}>
        {label}
        {children && <span>{children}</span>}
      </Label>
    </div>
  )
}

export default RadioButton
