import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Header, Text, Button } from '@pet-notify/ui'

function Success() {
  return (
    <>
      <Header mb='6'>Order successful</Header>

      <Text mb='6'>
        Your replacement Intellitag will arrive in 5-7 business days.
      </Text>

      <Button as={RouterLink} to='/'>
        Back to Pet Vault
      </Button>
    </>
  )
}

export default Success
