import React from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { FULL_DATE_AND_TIME } from 'constants/dateFormats'
import { List, ListItem } from 'components/List'
import Text from 'components/Text'
import style from './style.module.scss'

AlarmHistoryContainer.propTypes = {
  history: PropTypes.array,
}

function AlarmHistoryContainer(props) {
  const { history } = props

  return (
    <List className={style.wrapper}>
      {history.map((h) => (
        <ListItem key={h.id} className={classNames(style.item, style[h.event])}>
          <Text className={style.message}>{h.message}</Text>
          <Text className={style.note} variant='note'>
            {format(h.createdAt, FULL_DATE_AND_TIME)}
          </Text>
        </ListItem>
      ))}
    </List>
  )
}

export default AlarmHistoryContainer
