import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import smoothscroll from 'smoothscroll-polyfill'
import classNames from 'classnames'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

smoothscroll.polyfill()
class Modal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    children: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.modalRoot = document.getElementById('modal')
    this.el = document.createElement('div')
    this.container = React.createRef()
  }
  componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el)
    }
  }

  handleChangedView = () => {
    this.container.current.scrollTo(0, 0)
  }

  render() {
    const {
      children,
      onClose,
      className,
      hideClose,
      enableClickOffToClose = true,
    } = this.props

    return ReactDOM.createPortal(
      <ColorContext.Provider value={{}}>
        <div
          className={style.wrapper}
          onClick={enableClickOffToClose ? onClose : undefined}
          ref={this.container}
        >
          <div
            className={classNames(style.container, className)}
            onClick={(event) => event.stopPropagation()}
          >
            {!hideClose && (
              <button onClick={onClose} className={style.closeButton}>
                Close
              </button>
            )}
            {children({
              onChangedView: this.handleChangedView,
            })}
          </div>
        </div>
      </ColorContext.Provider>,
      this.el,
    )
  }
}

export default Modal
