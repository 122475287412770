import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Text from 'components/Text'
import { Form, Label, Button, ErrorText } from 'components/Forms'
import PasswordInput from 'sharedComponents/Input/Password'

import style from './style.module.scss'

ResetPasswordScreen.propTypes = {
  onSubmit: PropTypes.func,
  wasSuccessful: PropTypes.bool,
  isProcessing: PropTypes.bool,
}

function ResetPasswordScreen(props) {
  const { onSubmit, wasSuccessful, isProcessing } = props

  if (wasSuccessful) {
    return <Redirect to='/signin' push />
  }

  return (
    <Layout>
      <HeaderOne>Reset Password</HeaderOne>

      <Form onSubmit={onSubmit}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Label htmlFor='reset-password-password'>New password</Label>
              <PasswordInput
                name='newPassword'
                id='reset-password-password'
                value={values.newPassword}
                onChange={onChange}
              />

              <Text className={style.note} variant='note'>
                Must contain 6 characters including a number and capital letter
              </Text>

              <Label htmlFor='reset-password-confirm-password'>
                Confirm new password
              </Label>
              <PasswordInput
                mustMatch={values.newPassword}
                name='confirmNewPassword'
                id='reset-password-confirm-password'
                value={values.confirmNewPassword}
                onChange={onChange}
              />
              {error && <ErrorText>{error}</ErrorText>}
              <Button type='submit'>Reset password</Button>
            </React.Fragment>
          )
        }
      </Form>
    </Layout>
  )
}
export default ResetPasswordScreen
