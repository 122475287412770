import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'

import './date-picker.scss'
import {
  Form,
  Label,
  Input,
  Button,
  Dropdown,
  ErrorText,
} from 'components/Forms'
import Text from 'components/Text'
import HeaderOne from 'components/Headers/HeaderOne'

import Loader from 'components/Loader'
import { TaskType } from 'types'
import style from './style.module.scss'

TaskForm.propTypes = {
  alertOptions: PropTypes.array,
  assignToOptions: PropTypes.array,
  isConfirmingRemove: PropTypes.bool,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onCancelRemove: PropTypes.func,
  onConfirmRemove: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  repeatOptions: PropTypes.array,
  values: PropTypes.shape(TaskType),
}

function TaskForm(props) {
  const {
    alertOptions,
    assignToOptions,
    isConfirmingRemove,
    isEditing,
    isLoading,
    isProcessing,
    onCancelRemove,
    onConfirmRemove,
    onRemove,
    onSubmit,
    repeatOptions,
    values,
  } = props

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={style.wrapper}>
      <HeaderOne className={style.header}>
        {isEditing ? `Edit` : `Add`} task
      </HeaderOne>
      <Form onSubmit={onSubmit} values={values}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Label htmlFor='task-name'>Task</Label>
              <Input
                placeholder='Required'
                name='name'
                id='task-name'
                value={values.name}
                onChange={onChange}
              />
              <Label htmlFor='task-contact-id'>Assign To</Label>
              <Dropdown
                name='contactId'
                value={values.contactId}
                onChange={onChange}
                options={assignToOptions}
                id='task-contact-id'
              />
              <Label htmlFor='task-start-time'>Date/Time</Label>
              <DatePicker
                id='task-start-time'
                selected={values.startTime}
                className={style.datePicker}
                onChange={(value) => onChange({ name: 'startTime', value })}
                showTimeSelect
                timeIntervals={15}
                dateFormat='MMMM do, yyyy  h:mm aa'
              />
              <Label htmlFor='task-alertMinutesBefore'>Alert</Label>
              <Dropdown
                name='alertMinutesBefore'
                value={values.alertMinutesBefore}
                onChange={onChange}
                options={alertOptions}
                id='task-alertMinutesBefore'
              />
              <Label htmlFor='task-repeat'>Repeat</Label>
              <Dropdown
                name='repeat'
                value={values.repeat}
                onChange={onChange}
                options={repeatOptions}
                id='task-repeat'
              />
              <Label htmlFor='task-instructions'>Instructions</Label>
              <Input
                name='instructions'
                id='task-instructions'
                value={values.instructions}
                onChange={onChange}
              />

              {error && <ErrorText>{error}</ErrorText>}

              {isEditing ? (
                isConfirmingRemove ? (
                  <React.Fragment>
                    <Text>Are you sure you want to remove this task?</Text>
                    <Button
                      onClick={onCancelRemove}
                      variant='hollowRed'
                      small
                      className={style.button}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={onConfirmRemove}
                      variant='red'
                      small
                      className={style.button}
                    >
                      Remove
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button type='submit' small className={style.button}>
                      Save
                    </Button>
                    <Button
                      onClick={onRemove}
                      variant='hollowRed'
                      small
                      className={style.button}
                    >
                      Remove task
                    </Button>
                  </React.Fragment>
                )
              ) : (
                <Button type='submit'>Create task</Button>
              )}
            </React.Fragment>
          )
        }
      </Form>
    </div>
  )
}

export default TaskForm
