import React from 'react'

import visaIcon from './images/visa-icon.png'
import masterCardIcon from './images/mastercard-icon.png'
import americanExpressIcon from './images/americanexpress-icon.png'
import discoverIcon from './images/discover-icon.png'
import style from './style.module.scss'

function PaymentMethodIcons() {
  return (
    <div className={style.wrapper}>
      <div className={style.icon}>
        <img src={visaIcon} alt='Visa' />
      </div>
      <div className={style.icon}>
        <img src={masterCardIcon} alt='Mastercard' />
      </div>
      <div className={style.icon}>
        <img src={americanExpressIcon} alt='American Express' />
      </div>
      <div className={style.icon}>
        <img src={discoverIcon} alt='Discover' />
      </div>
      <div className={style.secureMessage}>Secure Payment</div>
    </div>
  )
}

export default PaymentMethodIcons
