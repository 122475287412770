import React from 'react'

import countries from 'lib/countries'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'
import { Label, Input, Dropdown, StateLabel } from 'components/Forms'

function AddressFormFields(props) {
  const { values, onChange } = props

  return (
    <React.Fragment>
      <Label htmlFor='address-address'>Address</Label>
      <Input
        name='address'
        value={values.address}
        onChange={onChange}
        id='address-address'
      />
      <Label htmlFor='address-address2'>Apartment / Suite</Label>
      <Input
        name='address2'
        value={values.address2}
        onChange={onChange}
        id='address-address2'
      />
      <Label htmlFor='address-city'>City</Label>
      <Input
        name='city'
        value={values.city}
        onChange={onChange}
        id='address-city'
      />
      <Label htmlFor='address-country'>Country</Label>
      <Dropdown
        name='country'
        options={countries}
        id='address-country'
        value={values.country || 'US'}
        onChange={onChange}
      />

      <StateLabel htmlFor='address-state' country={values.country} />
      {values.country === 'US' || values.country === 'CA' ? (
        <Dropdown
          includeBlank
          name='state'
          options={values.country === 'US' ? usStates : canadianProvinces}
          id='address-state'
          value={values.state}
          onChange={onChange}
        />
      ) : (
        <Input
          type='text'
          name='state'
          id='address-state'
          value={values.state}
          onChange={onChange}
        />
      )}

      <Label htmlFor='address-zip'>
        {values.country === 'US' ? `Zip` : `Postal Code`}
      </Label>
      <Input
        type='text'
        name='zip'
        id='address-zip'
        value={values.zip}
        onChange={onChange}
      />
    </React.Fragment>
  )
}

export default AddressFormFields
