import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import Layout from 'components/Layout/SplitLayout'
import Loader from 'components/Loader'
import Text from 'components/Text'
import Link from 'components/Link'
import HeaderOne from 'components/Headers/HeaderOne'
import { Form, Input, Button, ErrorText } from 'components/Forms'

import VaultAccessExpires from 'ContactApp/components/VaultAccessExpires'

import style from './style.module.scss'

VaultAccessScreen.propTypes = {
  accessPin: PropTypes.string,
  vaultAccess: PropTypes.object,
  contact: PropTypes.object,
  didDecline: PropTypes.bool,
  didResendAccessPin: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onCancel: PropTypes.bool,
  onResendAccessPin: PropTypes.bool,
  onSubmit: PropTypes.bool,
  token: PropTypes.string,
}

function VaultAccessScreen(props) {
  const {
    accessPin,
    contact,
    didDecline,
    didResendAccessPin,
    error,
    id,
    isLoading,
    isProcessing,
    onCancel,
    onResendAccessPin,
    onSubmit,
    vaultAccess,
    token,
  } = props

  if (accessPin) {
    return (
      <Redirect
        to={`/c/${token}/vault/${id}/details?accessPin=${accessPin}`}
        push
      />
    )
  }

  if (didDecline) {
    return <Redirect to={`/c/${token}/declined`} push />
  }

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  const { last4Phone } = contact
  const { accessExpires } = vaultAccess

  return (
    <Layout showNav={false}>
      <HeaderOne>Enter access code</HeaderOne>
      <Text>
        We’ve sent a 4 digit access code to the phone number ending in{' '}
        {last4Phone}. Please enter it to access the vault.
      </Text>
      <VaultAccessExpires>{accessExpires}</VaultAccessExpires>

      <Form onSubmit={onSubmit}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Input
                type='text'
                name='accessPin'
                value={values.accessPin}
                onChange={onChange}
                className={style.input}
              />
              {error && <ErrorText>{error}</ErrorText>}

              <Button type='submit' className={style.continueButton}>
                Continue
              </Button>

              <Link onClick={onResendAccessPin}>Resend access code</Link>

              {didResendAccessPin && (
                <Text className={style.accessPinSuccess}>Resent</Text>
              )}
            </React.Fragment>
          )
        }
      </Form>
      <Button variant='hollowRed' small onClick={onCancel}>
        Cancel, I'm unavailable
      </Button>
    </Layout>
  )
}
export default VaultAccessScreen
