import React from 'react'
import PropTypes from 'prop-types'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import ReactGA from 'react-ga'

import notification from 'store/notification/reducer'

import App from './components/App'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({ notification }),
  composeEnhancers(applyMiddleware(thunk)),
)

class ContactApp extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      ReactGA.pageview(
        this.props.location.pathname + this.props.location.search,
      )
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Switch>
          <Route path='/' component={App} />
        </Switch>
      </Provider>
    )
  }
}

export default ContactApp
