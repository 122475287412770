import { normalize } from 'normalizr'

import mixpanel, { EVENTS } from 'services/mix-panel'
import schema from './schema'

export const FETCHED_ALL = 'PETS/FETCHED_ALL'
export const FETCHED_ONE = 'PETS/FETCHED_ONE'
export const UPDATE = 'PETS/UPDATE'
export const ADD = 'PETS/ADD'
export const REMOVE = 'PETS/REMOVE'

export function fetchAll() {
  return async (dispatch, getState, api) => {
    const response = await api.pets.fetchAll()
    dispatch({
      type: FETCHED_ALL,
      payload: normalize(response, [schema]),
    })
    return response
  }
}

export function add(data) {
  return async (dispatch, getState, api) => {
    const response = await api.pets.add(data)

    mixpanel.track(EVENTS.PET_ADDED)

    dispatch({
      type: ADD,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function fetchOne(id) {
  return async (dispatch, getState, api) => {
    const response = await api.pets.fetchOne(id)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function update(id, data) {
  return async (dispatch, getState, api) => {
    const response = await api.pets.update(id, data)
    dispatch({
      type: UPDATE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function remove(id) {
  return async (dispatch, getState, api) => {
    const response = await api.pets.remove(id)
    dispatch({
      type: REMOVE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function activateIntellitags(activationCode, data) {
  return async (dispatch, getState, api) => {
    const response = await api.orders.activateIntellitagsByActivationCode(
      activationCode,
      data,
    )
    dispatch({
      type: UPDATE,
      payload: normalize(response, [schema]),
    })
    return response
  }
}
