import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'

import style from './style.module.css'

type Props = {
  onClose?: () => void
  children: React.ReactNode
  closeOnOverlayClick?: boolean
  className?: string
  variant?: 'large' | 'small'
}

function Modal({
  variant,
  onClose,
  children,
  className,
  closeOnOverlayClick = true,
}: Props) {
  function handleClick() {
    if (onClose) {
      onClose()
    }
  }

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTo(0, 0)
    }
  }, [children])

  const portal = document.getElementById('portal')

  if (!portal) {
    return null
  }

  return createPortal(
    <div
      className={style.wrapper}
      onClick={closeOnOverlayClick ? handleClick : undefined}
    >
      <div
        className={classNames(style.container, className, {
          [style.large]: variant === 'large',
        })}
        onClick={(event) => event.stopPropagation()}
        ref={containerRef}
      >
        {onClose && (
          <button
            onClick={onClose}
            className={style.closeButton}
            aria-label='Close modal'
          >
            <svg
              width='30'
              height='30'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect
                x='1'
                y='1'
                width='34'
                height='34'
                rx='17'
                stroke='currentColor'
                strokeWidth='2'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.0806 18.1714L12.2257 13.3165C11.9248 13.0156 11.9248 12.5265 12.2257 12.2257C12.5265 11.9248 13.0156 11.9248 13.3165 12.2257L18.1714 17.0806L23.0263 12.2257C23.3272 11.9248 23.8163 11.9248 24.1172 12.2257C24.4181 12.5265 24.4181 13.0156 24.1172 13.3165L19.2623 18.1714L24.1172 23.0263C24.4181 23.3272 24.4181 23.8163 24.1172 24.1172C23.8163 24.4181 23.3272 24.4181 23.0263 24.1172L18.1714 19.2623L13.3165 24.1172C13.0156 24.4181 12.5265 24.4181 12.2257 24.1172C11.9248 23.8163 11.9248 23.3272 12.2257 23.0263L17.0806 18.1714Z'
                fill='currentColor'
              />
            </svg>
          </button>
        )}
        {children}
      </div>
    </div>,
    portal,
  )
}

export default Modal
