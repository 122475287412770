import React, { useContext } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'
import { ColorContext } from 'ColorContext'

HeaderRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function HeaderRow(props) {
  const { children, className } = props
  const { color } = useContext(ColorContext)

  return (
    <div className={classNames(style.wrapper, style[color], className)}>
      {children}
    </div>
  )
}

export default HeaderRow
