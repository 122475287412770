import { normalize } from 'normalizr'
import Cookies from 'js-cookie'

import mixpanel, { EVENTS } from 'services/mix-panel'
import schema from './schema'

export const FETCHED = 'USER/FETCHED'
export const LOGOUT = 'USER/LOGOUT'
export const UPDATE_PAYMENT = 'USER/UPDATE_PAYMENT'
export const RENEW_SUBSCRIPTION = 'USER/RENEW_SUBSCRIPTION'
export const REACTIVATE_SUBSCRIPTION = 'USER/REACTIVATE_SUBSCRIPTION'
export const CANCEL_SUBSCRIPTION = 'USER/CANCEL_SUBSCRIPTION'
export const DOWNGRADE_SUBSCRIPTION = 'USER/DOWNGRADE_SUBSCRIPTION'
export const UPGRADE_SUBSCRIPTION = 'USER/UPGRADE_SUBSCRIPTION'
export const CHANGE_SUBSCRIPTION = 'USER/CHANGE_SUBSCRIPTION'
export const REACTIVATE_ACCOUNT = 'USER/REACTIVATE_ACCOUNT'

export function signup(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.signup(data)
    return dispatch({
      type: FETCHED,
      payload: normalize(user, schema),
    })
  }
}

export function authenticate(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.authenticate(data)
    mixpanel.identify(user.id)
    mixpanel.track(EVENTS.LOGIN)
    mixpanel.register({ plan: user.hasSubscription ? 'paid' : 'free' })
    mixpanel.people.set({
      $name: user.name,
      $email: user.email,
      $phone: user.phone,
    })
    return dispatch({
      type: FETCHED,
      payload: normalize(user, schema),
    })
  }
}

export function activate(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.activate(data)

    return dispatch({
      type: FETCHED,
      payload: normalize(user, schema),
    })
  }
}

export function fetch() {
  return async (dispatch, getState, api) => {
    const token = Cookies.get('token')

    if (token) {
      try {
        const user = await api.users.me.fetch()

        return dispatch({
          type: FETCHED,
          payload: normalize(user, schema),
        })
      } catch (e) {
        logout()(dispatch)
      }
    }
  }
}

export function reactivateSubscription(data) {
  return {
    type: REACTIVATE_SUBSCRIPTION,
    payload: normalize(data, schema),
  }
}

export function renewSubscription(data) {
  return async (dispatch, getState, api) => {
    const response = await api.users.me.renewSubscription(data)

    dispatch({
      type: RENEW_SUBSCRIPTION,
      payload: normalize(response, schema),
    })
  }
}

export function reactivateAccount() {
  return async (dispatch, getState, api) => {
    const response = await api.users.me.reactivateAccount()

    dispatch({
      type: REACTIVATE_ACCOUNT,
      payload: normalize(response, schema),
    })
  }
}

export function cancelSubscription(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.me.cancelSubscription(data)

    mixpanel.track(EVENTS.CANCEL_SUBSCRIPTION)

    if (data.deleteAllMyData) {
      mixpanel.track(EVENTS.DELETE_ACCOUNT)
    }

    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: normalize(user, schema),
    })
  }
}

export function downgradeSubscription() {
  return async (dispatch, getState, api) => {
    const user = await api.users.me.downgradeSubscription()

    mixpanel.track(EVENTS.DOWNGRADE_SUBSCRIPTION)

    dispatch({
      type: DOWNGRADE_SUBSCRIPTION,
      payload: normalize(user, schema),
    })

    return user
  }
}

export function downgradeSubscriptionSimple(user) {
  return {
    type: DOWNGRADE_SUBSCRIPTION,
    payload: normalize(user, schema),
  }
}

export function upgrade(data) {
  return async (dispatch, getState, api) => {
    const response = await api.users.me.upgrade(data)

    dispatch({
      type: UPGRADE_SUBSCRIPTION,
      payload: normalize(response, schema),
    })
  }
}

export function updateEmail(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.me.updateEmail(data)

    return dispatch({
      type: FETCHED,
      payload: normalize(user, schema),
    })
  }
}

export function updatePayment(data) {
  return async (dispatch, getState, api) => {
    const card = await api.users.me.updatePayment(data)

    return dispatch({
      type: UPDATE_PAYMENT,
      payload: card,
    })
  }
}

export function changeBillingPeriod(data) {
  return async (dispatch, getState, api) => {
    const response = await api.users.me.changeBillingPeriod(data)

    dispatch({
      type: CHANGE_SUBSCRIPTION,
      payload: normalize(response, schema),
    })
  }
}

export function update(data) {
  return async (dispatch, getState, api) => {
    const user = await api.users.me.update(data)

    return dispatch({
      type: FETCHED,
      payload: normalize(user, schema),
    })
  }
}

export function updateSimple(user) {
  return {
    type: FETCHED,
    payload: normalize(user, schema),
  }
}

export function logout() {
  return (dispatch) => {
    Cookies.remove('token')
    mixpanel.reset()
    dispatch({
      type: LOGOUT,
    })
  }
}

export function logoutSimple() {
  return {
    type: LOGOUT,
  }
}
