import React, { useState } from 'react'
import { Base, Logo } from '@pet-notify/ui'

import PlanForm from '../PlanForm'
import PaymentForm from '../PaymentForm'
import { useHistory } from 'react-router-dom'

enum Steps {
  Plan,
  Payment,
}

function UpdateSubscription() {
  const history = useHistory()
  const [step, setStep] = useState(Steps.Plan)

  function handleSuccess() {
    history.push('/')
  }

  return (
    <>
      <Base mb='4'>
        <Logo />
      </Base>

      {step === Steps.Plan && (
        <PlanForm onSuccess={() => setStep(Steps.Payment)} />
      )}

      {step === Steps.Payment && (
        <PaymentForm
          onSuccess={handleSuccess}
          onChangePlan={() => setStep(Steps.Plan)}
        />
      )}
    </>
  )
}

export default UpdateSubscription
