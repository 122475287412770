import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Direction = 'vertical' | 'horizontal'

type Props = React.ComponentPropsWithoutRef<'div'> & {
  direction?: Direction | Direction[]
  spacing?: '1' | '2' | '3' | '4' | '5' | '6'
}

function classNameForDirection(direction: Direction | Direction[]) {
  if (Array.isArray(direction)) {
    const [desktop, mobile] = direction
    return classNames(style[desktop], style[`${mobile}Mobile`])
  }

  return style[direction]
}

function Stack<C extends React.ElementType = 'div'>({
  direction = 'vertical',
  spacing = '1',
  as,
  className,
  ...props
}: Props & BaseProps<C>) {
  return (
    <Base
      as={as || 'div'}
      className={classNames(
        style.wrapper,
        `gap-${spacing}`,
        className,
        classNameForDirection(direction),
      )}
      {...props}
    />
  )
}

export default Stack
