import React from 'react'

import Pet from './Pet'
import { Loader } from '@pet-notify/ui'
import style from './style.module.css'

function PetsList({ rows, isLoading }) {
  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    )
  }

  return (
    <ul className={style.wrapper}>
      {rows.map((pet) => (
        <li key={pet.id} className={style.item}>
          <Pet {...pet} />
        </li>
      ))}
    </ul>
  )
}

export default PetsList
