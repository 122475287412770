export const SET = 'NOTIFICATION/SET'
export const REMOVE = 'NOTIFICATION/REMOVE'

export function set(message) {
  return (dispatch) => {
    dispatch({
      type: SET,
      payload: {
        message,
      },
    })
  }
}

export function remove() {
  return (dispatch) => {
    dispatch({
      type: REMOVE,
    })
  }
}
