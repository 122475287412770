import numeral from 'numeral'

import formatMonths from '../../../lib/format-months'

export function selectPromoCodeWithDiscount(
  state: State,
  isAnnualPlanSelected: boolean,
) {
  const { promoCode } = state

  if (!promoCode) {
    return null
  }

  const { code, affiliate } = promoCode

  const {
    hasAffiliateCompany,
    subscriptionDiscount,
    intellitagDiscount,
    additionalMessage,
    limitedToAnnual,
  } = affiliate

  let discounts = []

  if (hasAffiliateCompany) {
    discounts.push(
      'Receive an Intellitag and free bonus pet light for every household pet with your Pet Notify premium subscription',
    )
  } else {
    if (subscriptionDiscount) {
      discounts.push(
        `${formatMonths(subscriptionDiscount.durationInMonths)} free`,
      )
    }

    if (intellitagDiscount) {
      if (intellitagDiscount.amountOff) {
        discounts.push(
          `${numeral(intellitagDiscount.amountOff).format(
            '$0.00',
          )} off Intellitag`,
        )
      } else {
        discounts.push(
          `${intellitagDiscount.percentOffDisplay}% off Intellitag`,
        )
      }
    }

    if (additionalMessage) {
      discounts = [additionalMessage]
    }
  }

  return {
    code,
    discounts: discounts.join(', '),
    isDisabled: !isAnnualPlanSelected && limitedToAnnual,
  }
}
