import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import ReactDOM from 'react-dom'

import style from './style.module.scss'

type Props = {
  isFullscreen?: boolean
  disableClickOffToClose?: boolean
  showCloseButton?: boolean
  onClose?: () => void
  children: JSX.Element
  className?: string
}

function Modal(props: Props) {
  const {
    isFullscreen = false,
    disableClickOffToClose = false,
    showCloseButton = true,
    onClose,
    children,
    className,
  } = props

  const wrapperEl = useRef<HTMLDivElement>(null)

  function handleClick() {
    if (!disableClickOffToClose && onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (wrapperEl && wrapperEl.current) {
      wrapperEl.current.scrollTo(0, 0)
    }
  }, [children])

  const modalEl = document.getElementById('modal')

  if (!modalEl) {
    return null
  }

  if (isFullscreen) {
    return ReactDOM.createPortal(
      <div
        className={classNames(style.fullscreenContainer, className)}
        onClick={(event) => event.stopPropagation()}
        ref={wrapperEl}
      >
        {children}
      </div>,
      modalEl,
    )
  }

  return ReactDOM.createPortal(
    <div className={style.wrapper} onClick={handleClick} ref={wrapperEl}>
      <div
        className={classNames(style.container, className)}
        onClick={(event) => event.stopPropagation()}
      >
        {showCloseButton && (
          <button onClick={onClose} className={style.closeButton}>
            Close
          </button>
        )}
        {children}
      </div>
    </div>,
    modalEl,
  )
}

export default Modal
