import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { selectAddress } from 'UserApp/store/user/selectors'
import { selectHasPetsWithoutIntellitags } from 'UserApp/store/pets/selectors'
import PetVaultScreen from 'UserApp/screens/PetVault'

class PetVaultScreenContainer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      path: PropTypes.string,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  }
  state = {
    dismissIntellitagCta: false,
  }

  handleClose = () => this.props.history.push('/')

  handleDismissIntellitagCta = () =>
    this.setState({ dismissIntellitagCta: true })

  generateProps = () => {
    const { match } = this.props
    const { path, params } = match

    return {
      ...this.props,
      ...this.state,
      isAddingAddress: path === '/address/new',
      isAddingPet: path === '/pets/new',
      isEditingAddress: path === '/address/edit',
      isEditingPet: path === '/pets/:id',
      onClose: this.handleClose,
      onDismissIntellitagCta: this.handleDismissIntellitagCta,
      petId: params.id,
    }
  }

  render() {
    const props = this.generateProps()

    return <PetVaultScreen {...props} />
  }
}

export default connect((state) => ({
  address: selectAddress(state),
  hasPetsWithouIntellitags: selectHasPetsWithoutIntellitags(state),
}))(PetVaultScreenContainer)
