import React from 'react'
import classNames from 'classnames'
import queryString from 'query-string'
import { Route, Switch } from 'react-router-dom'

import Modal from 'sharedComponents/Modal'
import Intellitag from 'UserApp/containers/OrderIntellitagModal/Intellitag'
import Shipping from 'UserApp/containers/OrderIntellitagModal/Shipping'
import Payment from 'UserApp/containers/OrderIntellitagModal/Payment'
import Success from './Success'

import style from './style.module.scss'

function Onboarding(props) {
  const { onClose, ...rest } = props
  const isSuccessPage = props.location.pathname === `/order-intellitag/success`
  const { mobile } = queryString.parse(location.search)
  const isMobile = mobile === 'true'

  return (
    <Modal
      onClose={onClose}
      showCloseButton={!isSuccessPage}
      className={classNames(style.wrapper, {
        [style.successModal]: isSuccessPage,
        [style.isFullscreen]: isMobile,
      })}
      disableClickOffToClose
      isFullscreen={isMobile}
    >
      <Switch>
        <Route
          path='/order-intellitag/success'
          render={(routeProps) => (
            <Success {...routeProps} {...rest} isMobile={isMobile} />
          )}
        />
        <Route
          path='/order-intellitag/payment'
          render={(routeProps) => (
            <Payment {...routeProps} {...rest} isMobile={isMobile} />
          )}
        />
        <Route
          path='/order-intellitag/shipping'
          render={(routeProps) => (
            <Shipping {...routeProps} {...rest} isMobile={isMobile} />
          )}
        />
        <Route
          path='/order-intellitag'
          render={(routeProps) => (
            <Intellitag {...routeProps} {...rest} isMobile={isMobile} />
          )}
        />
      </Switch>
    </Modal>
  )
}

export default Onboarding
