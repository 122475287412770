import React from 'react'
import classNames from 'classnames'

type PolymorphicRef<C extends React.ElementType> =
  React.ComponentPropsWithRef<C>['ref']

type AsProp<C extends React.ElementType> = {
  as?: C
}

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P)

type PolymorphicComponentProp<
  C extends React.ElementType,
  Props = unknown,
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>

type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = unknown,
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> }

type MarginBottom =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'

export type BaseProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<C, { mb?: MarginBottom }>

const Base = React.forwardRef(
  <C extends React.ElementType = 'div'>(
    { as, mb, className, ...props }: BaseProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'div'

    return (
      <Component
        className={classNames(className, { [`mb-${mb}`]: mb })}
        ref={ref}
        {...props}
      />
    )
  },
)

export default Base
