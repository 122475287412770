import React from 'react'

import HeaderOne from 'sharedComponents/HeaderOne'
import Modal from 'sharedComponents/Modal'
import style from './style.module.scss'

type Props = {
  onClose: () => void
}

function PremiumFeaturesModal(props: Props) {
  const { onClose } = props
  return (
    <Modal onClose={onClose}>
      <div>
        <HeaderOne>Premium features include</HeaderOne>
        <ul className={style.features}>
          <li>Intellitag lost pet protection plan</li>
          <li>Engraved Intellitags for every household pet</li>
          <li>Intellitag lifetime replacement if lost, worn, or damanged</li>
          <li>Pet Vault unlimited document storage</li>
          <li>Automatic location based & daily check-in alarms</li>
          <li>SOS emergency alarm notifications</li>
          <li>Pet care task assignment to family & care providers</li>
          <li>Unlimited emergency & pet care contacts</li>
        </ul>
      </div>
    </Modal>
  )
}

export default PremiumFeaturesModal
