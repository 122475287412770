import React from 'react'
import { Link } from 'react-router-dom'

import style from './style.module.scss'

type Props = {
  id: number
  name: string
  intellitagId: null | number
  photo?: {
    sizes: {
      large: {
        src: string
      }
    }
  }
}

function Pet(props: Props) {
  const {
    id,
    name,
    photo,
    hasPendingOrder,
    meta,
    intellitagId,
    inactiveIntellitag,
  } = props

  return (
    <>
      <Link to={`/pets/${id}`} className={style.wrapper}>
        {photo ? (
          <img src={photo.sizes.large.src} />
        ) : (
          <div className={style.placeholder}>Add photo</div>
        )}

        <div className={style.inner}>
          {name}{' '}
          {intellitagId &&
            !inactiveIntellitag &&
            (!photo || !meta.intellitagInstructions) && (
              <span className={style.alert}>!</span>
            )}
        </div>
      </Link>
      {hasPendingOrder && !inactiveIntellitag && (
        <div className={style.orderPending}>Order Pending</div>
      )}
      {intellitagId && !inactiveIntellitag && (
        <div className={style.activatedIntellitag}>Intellitag Activated</div>
      )}
      {inactiveIntellitag && (
        <Link
          to={`/pets/activate-intellitag/reactivate`}
          className={style.activateIntellitagLink}
        >
          Reactivate Intellitag
        </Link>
      )}

      {!intellitagId && !hasPendingOrder && (
        <Link
          className={style.activateIntellitagLink}
          to={`/pets/activate-intellitag?petId=${id}`}
        >
          Order or Activate Intellitag
        </Link>
      )}
    </>
  )
}

export default Pet
