import mixpanel, { EVENTS } from 'services/mix-panel'

export function downgradeSubscription() {
  mixpanel.track(EVENTS.DOWNGRADE_SUBSCRIPTION)
}

export function deleteAccount() {
  mixpanel.track(EVENTS.DELETE_ACCOUNT)
  mixpanel.reset()
}
