import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { set as setNotification } from 'store/notification/actions'
import api from 'services/pet-notify-api'

import ResetPasswordScreen from 'UserApp/screens/ResetPassword'

class ResetPasswordScreenContainer extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    setNotification: PropTypes.func,
  }

  state = { isProcessing: false, wasSuccessful: false }

  handleSubmit = async (data) => {
    const { location, setNotification } = this.props
    const { token } = queryString.parse(location.search)

    this.setState({ isProcessing: true })

    try {
      await api.users.resetPassword({ ...data, token })
      await this.setState({
        wasSuccessful: true,
      })
      setNotification(`You have updated your password`)
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()
    return <ResetPasswordScreen {...props} />
  }
}

export default connect(null, { setNotification })(ResetPasswordScreenContainer)
