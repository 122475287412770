import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { set as setNotification } from 'store/notification/actions'

import { update } from 'UserApp/store/user/actions'
import AddressForm from 'UserApp/components/Address/Form'

function usersAddress(user) {
  const {
    address,
    address2,
    city,
    state,
    country,
    zip,
    phone,
    shippingAddress = {},
    accessInformation,
  } = user

  return {
    accessInformation,
    address: address || shippingAddress.address,
    address2: address2 || shippingAddress.address2,
    city: city || shippingAddress.city,
    state: state || shippingAddress.state,
    country: country || shippingAddress.country || 'US',
    zip: zip || shippingAddress.zip,
    phone,
  }
}
class AddressFormContainer extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object,
    update: PropTypes.func,
    setNotification: PropTypes.func,
    onClose: PropTypes.func,
  }

  state = { error: '', isProcessing: false }

  handleSubmit = async (data) => {
    const { update, onClose, setNotification } = this.props

    this.setState({
      isProcessing: true,
      error: '',
    })

    try {
      await update(data)
      setNotification(`Address saved`)
      onClose()
    } catch (error) {
      this.setState({
        error,
        isProcessing: false,
      })
    }
  }

  generateProps = () => {
    const { user } = this.props
    const address = usersAddress(user)
    return {
      ...this.props,
      ...this.state,
      address,
      onSubmit: this.handleSubmit,
    }
  }

  render() {
    const props = this.generateProps()

    return <AddressForm {...props} />
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { update, setNotification },
)(AddressFormContainer)
