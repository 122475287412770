import axios from 'axios'
import * as Sentry from '@sentry/react'

import { API_URL } from 'config'

const request = axios.create({
  baseURL: `${API_URL}/api/`,
  withCredentials: true,
})

request.interceptors.request.use(({ data, skipDataFormat, ...rest }) => {
  return {
    data: skipDataFormat ? data : { data },
    ...rest,
  }
})

request.interceptors.response.use(
  (response) => {
    return response.data.data
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const message =
        error.response.data.errors[0].detail ||
        error.response.data.errors[0].title
      return Promise.reject(new Error(message))
    } else {
      Sentry.captureException(new Error(error))
      return Promise.reject(new Error(`Oops, something went wrong.`))
    }
  },
)

export default {
  affiliateCompanies: {
    login: (data) => request.post(`affiliate-companies/authenticate`, data),
    fetch: (name) => request.get(`affiliate-companies/${name}`),
  },
  affiliateInvites: {
    create: (data) => request.post(`affiliate-invites`, data),
  },
  affiliates: {
    fetchAll: () => request.get('affiliates'),
    create: (data) => request.post('affiliates', data),
    update: (id, data) => request.put(`affiliates/${id}`, data),
    resendSignupEmail: (id) =>
      request.post(`affiliates/${id}/resend-signup-email`),
    fetch: (promoCode) => request.get(`affiliates/${promoCode}`),
    login: (data) => request.post(`affiliates/authenticate`, data),
    me: () => request.get(`affiliates/me`),
  },
  users: {
    // fetchByActivationCode: (activationCode) =>
    //   request.get(`users/activation-code/${activationCode}`),
    activate: (data) => request.post('users/activate', data),
    authenticate: (data) => request.post('users/authenticate', data),
    verifyAccount: (data) => request.post('users/verify-account', data),
    forgotPassword: (data) => request.post('users/forgot-password', data),
    forgotEmail: (data) => request.post('users/forgot-email', data),
    resetPassword: (data) => request.patch('users/reset-password', data),
    allowSmsConsent: (data) => request.put('users/sms-consent',data),
    lostEmailAccessUpdateEmail: (data)=> request.patch('users/lost-email-access', data),
    me: {
      fetch: () => request.get('users/me'),
      update: (data) => request.patch('users/me', data),
      updateEmail: (data) => request.put('users/me/email', data),
      updatePayment: (data) => request.put('users/me/payment', data),
      changePassword: (data) => request.put('users/me/password', data),
      upgrade: (data) => request.patch('users/me/subscription/upgrade', data),
      changeBillingPeriod: (data) =>
        request.patch('users/me/subscription/billing-period', data),
      cancelSubscription: (data) =>
        request.patch('users/me/subscription/cancel', data),
      downgradeSubscription: () =>
        request.patch('users/me/subscription/downgrade'),
      renewSubscription: (data) =>
        request.patch('users/me/subscription/renew', data),
      reactivateSubscription: (data) =>
        request.patch('users/me/subscription/reactivate', data),
      reactivateAccount: () => request.patch('users/me/reactivate'),
      fetchLostPetLocations: (userId) => request.get(`users/lostPetLocations/${userId}`),
    },
  },
  orders: {
    pricing: (data = {}) => request.post('orders/pricing', data), // move to a /pricing route
    fetchByActivationCode: (activationCode) =>
      request.get(`orders/${activationCode}`),
    activateIntellitagsByActivationCode: (activationCode, data) =>
      request.put(`orders/${activationCode}`, data),
  },
  intellitagOrders: {
    create: (data) => request.post('intellitag-orders', data),
    breakdown: (data) => request.post(`intellitag-orders/breakdown`, data),
  },
  signup: {
    breakdown: (data) => request.post(`signup/breakdown`, data),
    complete: (data) => request.post(`signup`, data),
    createFreeSignup: (data) => request.post(`signup/free`, data),
    lead: (data) => request.post(`signup/lead`, data),
    createTempUser: (data) => request.post(`signup/temp-user`, data),
    setPassword: (data) => request.patch('signup/set-password', data),
    setPasswordLater: (data) =>
      request.patch('signup/set-password-later', data),
  },
  pets: {
    fetchAll: () => request.get('pets'),
    fetchOne: (id) => request.get(`pets/${id}`),
    fetchOneByIntellitag: (tagNumber) =>
      request.get(`pets/intellitag/${tagNumber}`),
    update: (id, data) => request.patch(`pets/${id}`, data),
    remove: (id) => request.delete(`pets/${id}`),
    add: (data) => request.post(`pets`, data),
  },
  replaceIntellitagOrders: {
    fetchOne: (id) => request.get(`replace-intellitag-orders/${id}`),
    fetchPricing: (id) =>
      request.get(`replace-intellitag-orders/${id}/pricing`),
    create: (data) => request.post(`replace-intellitag-orders`, data),
    update: (id, data) => request.put(`replace-intellitag-orders/${id}`, data),
    confirm: (id, data) =>
      request.patch(`replace-intellitag-orders/${id}/confirm`, data),
  },
  foundPets: {
    create: (data) => request.post(`found-pets`, data),
    fetchOne: (id) => request.get(`found-pets/${id}`),
    fetchOneByToken: (token) => request.get(`found-pets/token/${token}`),
    updateByToken: (token, data) =>
      request.patch(`found-pets/token/${token}`, data),
  },
  contacts: {
    fetchAll: () => request.get('contacts'),
    setOrder: (data) => request.put('contacts/order', data),
    fetchOne: (id) => request.get(`contacts/${id}`),
    update: (id, data) => request.patch(`contacts/${id}`, data),
    remove: (id) => request.delete(`contacts/${id}`),
    resendInvite: (id) => request.post(`contacts/${id}/resend-invite`),
    add: (data) => request.post(`contacts`, data),
  },
  contactInvitations: {
    view: (token) => request.get(`contact-invitations/${token}`),
    accept: (token, data) =>
      request.post(`contact-invitations/${token}/accept`, data),
    decline: (token) => request.post(`contact-invitations/${token}/decline`),
  },
  alarm: {
    fetchOne: () => request.get(`alarm`),
    turnOff: () => request.delete(`alarm`),
    create: (data) => request.post(`alarm`, data),
    triggerSOS: (data) => request.post(`alarm/sos`, data),
    update: (data) => request.patch(`alarm`, data),
  },
  vaults: {
    fetchOne: (id, accessPin) =>
      request.get(`vaults/${id}`, { params: { accessPin } }),
  },
  alarmContactRequests: {
    fetchOne: (token) => request.get(`alarm-contact-requests/${token}`),
    accepted: (token) => request.put(`alarm-contact-requests/${token}/accept`),
    declined: (token) => request.put(`alarm-contact-requests/${token}/decline`),
    resendAccessPin: (token) =>
      request.put(`alarm-contact-requests/${token}/resend-access-pin`),
    safe: (token) => request.put(`alarm-contact-requests/${token}/safe`),
  },
  tasks: {
    markDoneByToken: (token) => request.delete(`tasks/token/${token}`),
    fetchAll: () => request.get('tasks'),
    clearTaskHistory: () => request.delete('tasks/history'),
    fetchOneByToken: (token) => request.get(`tasks/token/${token}`),
    fetchOne: (id) => request.get(`tasks/${id}`),
    remove: (id) => request.delete(`tasks/${id}`),
    update: (id, data) => request.patch(`tasks/${id}`, data),
    updateByToken: (token, data) => request.patch(`tasks/token/${token}`, data),
    add: (data) => request.post(`tasks`, data),
  },
  documents: {
    upload: (file, name) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)
      return request.post(`documents`, formData, {
        skipDataFormat: true,
      })
    },
    update: (id, data) => request.patch(`documents/${id}`, data),
    remove: (id) => request.delete(`documents/${id}`),
  },
  photos: {
    upload: (file) => {
      const formData = new FormData()
      formData.append('photo', file, 'photo.jpg')
      return request.post(`photos`, formData, {
        skipDataFormat: true,
      })
    },
    remove: (id) => request.delete(`photos/${id}`),
  },
}
