import React from 'react'

import Label from '../Label'
import style from './style.module.scss'

type Props = {
  name: string
  value: boolean
  label: string
  id: string
  onChange: (formField: FormField) => void
}

Checkbox.defaultProps = {
  value: false,
}

function Checkbox(props: Props) {
  const { name, value, onChange, label, id } = props

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, checked } = event.currentTarget

    if (onChange) {
      onChange({ name, value: checked })
    }
  }

  return (
    <div className={style.wrapper}>
      <input
        className={style.checkbox}
        type='checkbox'
        name={name}
        id={id}
        checked={value}
        onChange={handleChange}
      />
      <Label htmlFor={id} className={style.label}>
        {label}
      </Label>
    </div>
  )
}

export default Checkbox
