import React from 'react'
import PropTypes from 'prop-types'

import { List, ListItem } from 'components/List'
import Empty from '../Empty'
import Link from '../Link'
import Pill from '../Pill'
import { ContactType } from 'types'
import style from './style.module.scss'

OtherContactsList.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
}

function OtherContactsList(props) {
  const { contacts } = props

  if (contacts.length === 0) {
    return <Empty>No contacts added</Empty>
  }

  return (
    <List className={style.wrapper}>
      {contacts.map((contact) => (
        <ListItem className={style.item} key={contact.id}>
          <Link {...contact} />
          {contact.isPending && <Pill variant='pending'>Pending</Pill>}
        </ListItem>
      ))}
    </List>
  )
}

export default OtherContactsList
