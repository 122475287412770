import React from 'react'
import PropTypes from 'prop-types'

import { Button, ErrorText, Form, Input, Label } from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import PhoneInput from 'sharedComponents/Input/Phone'
import style from './style.module.scss'

ChipIDForm.propTypes = {
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
}

function ChipIDForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values

  async function handleSubmit(data) {
    const { company, number } = data

    if (!company || company === '') {
      throw new Error(`Company required`)
    }

    if (!number || number === '') {
      throw new Error(`ID Number required`)
    }

    await onSubmit(data)
  }

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} microchip ID</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='chip-id-company'>Chip Company</Label>
            <Input
              name='company'
              value={values.company}
              onChange={onChange}
              id='chip-id-company'
            />

            <Label htmlFor='chip-id-number'>Microchip ID Number</Label>
            <Input
              name='number'
              value={values.number}
              onChange={onChange}
              id='chip-id-number'
            />

            <Label htmlFor='chip-id-phone'>Company Phone Number</Label>
            <PhoneInput
              placeholder='Optional'
              name='phone'
              value={values.phone}
              onChange={onChange}
              id='chip-id-phone'
            />

            <Label htmlFor='chip-id-url'>Company URL</Label>
            <Input
              placeholder='Optional'
              name='url'
              value={values.url}
              onChange={onChange}
              id='chip-id-url'
            />

            <Label htmlFor='chip-id-login'>Login</Label>
            <Input
              placeholder='Optional'
              name='login'
              value={values.login}
              onChange={onChange}
              id='chip-id-login'
            />

            <Label htmlFor='chip-id-password'>Password</Label>
            <Input
              placeholder='Optional'
              name='password'
              value={values.password}
              onChange={onChange}
              id='chip-id-password'
            />

            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add microchip ID`}
            </Button>
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default ChipIDForm
