import axios from 'axios'

import { API_URL } from 'config'

const request = axios.create({
  baseURL: API_URL || '/',
  withCredentials: true,
})

request.interceptors.request.use((config) => {
  if (config.data) {
    return {
      ...config,
      data: { data: config.data },
    }
  } else {
    return config
  }
})

request.interceptors.response.use(
  (response) => {
    const { data, ...rest } = response

    return {
      ...rest,
      data: data.data,
    }
  },
  (error) => {
    return Promise.reject(error.response.data.errors[0].detail)
  },
)

export default request
