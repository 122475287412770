import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Label.propTypes = {
  children: PropTypes.node,
  subTitle: PropTypes.string,
}

function Label(props) {
  const { children, subTitle, ...rest } = props

  return (
    <div className={style.wrapper}>
      <label className={style.label} {...rest}>
        {children}
      </label>
      {subTitle && (
        <div className={style.subTitle}>
          {typeof subTitle === 'function' ? subTitle() : subTitle}
        </div>
      )}
    </div>
  )
}

export default React.memo(Label)
