import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { List, ListItem } from 'components/List'
import Empty from '../Empty'
import Link from '../Link'
import Pill from '../Pill'
import Loader from 'components/Loader'
import { ContactType } from 'types'
import style from './style.module.scss'

EmergencyContactsList.propTypes = {
  isLoading: PropTypes.bool,
  onSort: PropTypes.func,
  contacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
}

function EmergencyContactsList(props) {
  const { contacts, isLoading } = props

  function handleDragEnd(result) {
    const { onSort } = props

    if (!result.destination) {
      return
    }

    const reordered = [...contacts]
    const [removed] = reordered.splice(result.source.index, 1)
    reordered.splice(result.destination.index, 0, removed)

    return onSort(reordered.map((contact) => contact.id))
  }

  if (isLoading) {
    return <Loader />
  }

  if (contacts.length === 0) {
    return <Empty>No contacts added</Empty>
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='contacts'>
        {(provided, snapshot) => (
          <List ref={provided.innerRef} className={style.wrapper}>
            {contacts.map((contact, index) => (
              <Draggable
                key={contact.id}
                draggableId={`${contact.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <ListItem
                    ref={provided.innerRef}
                    className={style.item}
                    {...provided.draggableProps}
                  >
                    <Link {...contact} />
                    {contact.isPending && (
                      <Pill variant='pending'>Pending</Pill>
                    )}
                    {contact.isDeclined && (
                      <Pill variant='declined'>Declined</Pill>
                    )}
                    <div
                      className={style.dragHandle}
                      {...provided.dragHandleProps}
                    />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default EmergencyContactsList
