import React from 'react'
import { useMutation } from 'react-query'
import { useForm } from 'react-hook-form'
import { Header, Text, Stack, Button, Loader } from '@pet-notify/ui'

import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'
import { formatFullDate } from 'lib/format-date'

type Props = {
  onClose: () => void
}

function DowngradeSubscriptionForm({ onClose }: Props) {
  const { setNotification } = useNotification()
  const { user, downgradeSubscription } = useUser()
  const form = useForm()

  const { mutate, isLoading } = useMutation(downgradeSubscription, {
    onSuccess: () => {
      setNotification('Subscription downgraded')
      onClose()
    },
  })

  const handleSubmit = form.handleSubmit(() => {
    mutate()
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header as='h2' mb='4'>
        Downgrade to free plan
      </Header>

      <Text mb='4'>
        In downgrading your plan, you'll lose access to Intellitag monitoring
        and notifications on{' '}
        {formatFullDate(user.subscription.lastDayOfBillingPeriod)}.
      </Text>

      <Stack direction='horizontal' spacing='4'>
        <Button type='submit' variant='red'>
          Downgrade to free plan
        </Button>

        <Button onClick={onClose} variant='hollow'>
          Cancel
        </Button>
      </Stack>
    </form>
  )
}
export default DowngradeSubscriptionForm
