import {
  FETCHED_ALL,
  FETCHED_ONE,
  UPDATE,
  ADD,
  REMOVE,
  SET_ORDER,
} from './actions'

export default function reducer(
  state = { entities: {}, result: [] },
  action = {},
) {
  const { payload = {}, type } = action
  const { result, entities } = payload

  switch (type) {
    case FETCHED_ALL:
      return {
        ...state,
        entities: {
          ...entities.contacts,
        },
        result: [...result],
      }
    case SET_ORDER:
      const others = state.result.filter((id) => !result.includes(id))
      return {
        ...state,
        result: [...result, ...others],
      }
    case UPDATE:
    case FETCHED_ONE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [result]: entities.contacts[result],
        },
      }
    case ADD:
      return {
        ...state,
        entities: {
          ...state.entities,
          [result]: entities.contacts[result],
        },
        result: [...state.result, result],
      }
    case REMOVE:
      return {
        ...state,
        result: [...state.result.filter((id) => id !== result)],
      }
    default:
      return state
  }
}
