import React from 'react'
import classNames from 'classnames'
import { format, isToday } from 'date-fns'
import PropTypes from 'prop-types'

import { FULL_DATE_AND_TIME, TIME } from 'constants/dateFormats'
import Text from 'components/Text'

import style from './style.module.scss'

VaultAccessExpires.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
}

function VaultAccessExpires(props) {
  const { children, className } = props

  return (
    <Text className={classNames(style.wrapper, className)}>
      Vault access expires at
      {isToday(children)
        ? format(children, ` ${TIME}`)
        : format(children, ` ${FULL_DATE_AND_TIME}`)}
    </Text>
  )
}

export default VaultAccessExpires
