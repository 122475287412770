import React from 'react'
import PropTypes from 'prop-types'

import Loader from 'components/Loader'
import { Form, TextArea, Button, ErrorText } from 'components/Forms'
import style from './style.module.scss'

LocationDetailsForm.propTypes = {
  isProcessing: PropTypes.bool,
  values: PropTypes.object,
  onSubmit: PropTypes.func,
}

function LocationDetailsForm(props) {
  const { isProcessing, values, onSubmit } = props

  return (
    <Form values={values} onSubmit={onSubmit} className={style.wrapper}>
      {({ onChange, values, error }) =>
        isProcessing ? (
          <Loader />
        ) : (
          <React.Fragment>
            <TextArea
              name='locationDetails'
              value={values.locationDetails}
              onChange={onChange}
              placeholder='Heading off to hike at crows nest pass. Should be back around 6pm'
              className={style.textarea}
            />
            {error && <ErrorText>{error}</ErrorText>}

            <Button type='submit'>Save</Button>
          </React.Fragment>
        )
      }
    </Form>
  )
}

export default LocationDetailsForm
