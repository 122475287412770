import { FETCHED_ONE, TURNED_OFF } from './actions'

export default function reducer(state = null, action = {}) {
  const { payload = {}, type } = action
  const { result, entities } = payload

  switch (type) {
    case FETCHED_ONE:
      return {
        ...entities.alarms[result],
      }
    case TURNED_OFF:
      return null
    default:
      return state
  }
}
