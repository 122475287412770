import { useReducer, useEffect } from 'react'

const ADD_INTELLITAG = 'ADD_INTELLITAG'
const REMOVE_INTELLITAG = 'REMOVE_INTELLITAG'
const CHANGE_INTELLITAG = 'CHANGE_INTELLITAG'
const CHANGE_INTELLITAG_VALUE = 'CHANGE_INTELLITAG_VALUE'

function reducer(state, action) {
  const { payload, type } = action

  switch (type) {
    case ADD_INTELLITAG:
      return [...state, { ...payload }]
    case REMOVE_INTELLITAG:
      return state.filter((_el, i) => i !== payload)
    case CHANGE_INTELLITAG:
      const { index, values } = payload
      return state.map((el, i) => {
        if (index === i) {
          const maxCharacters = el.size === 'small' ? 12 : 15
          const newEl = {
            ...el,
            ...values,
          }
          return {
            ...newEl,
            maxCharacters,
            nameTooLong: newEl.name.length > maxCharacters,
          }
        }

        return el
      })
    case CHANGE_INTELLITAG_VALUE: {
      const { index, name, value } = payload
      return state.map((el, i) => {
        if (index === i) {
          const maxCharacters = el.size === 'small' ? 12 : 15
          const newEl = {
            ...el,
            [name]: value,
          }
          return {
            ...newEl,
            maxCharacters,
            nameTooLong: newEl.name.length > maxCharacters,
          }
        }

        return el
      })
    }
  }
}

function useIntellitag(pets, firstIndex = 0) {
  const [intellitags, dispatch] = useReducer(reducer, [])

  function handleAdd(index) {
    dispatch({
      type: ADD_INTELLITAG,
      payload: {
        size: 'small',
        name: pets[index].name.toUpperCase(),
        pet: index,
      },
    })
  }

  function handleRemove(index) {
    dispatch({
      type: REMOVE_INTELLITAG,
      payload: index,
    })
  }

  function handleChange(payload) {
    const { index, name, value } = payload
    if (name === 'pet') {
      dispatch({
        type: CHANGE_INTELLITAG,
        payload: {
          index,
          values: {
            pet: Number(value),
            name: pets[value].name.toUpperCase(),
          },
        },
      })
    } else {
      dispatch({
        type: CHANGE_INTELLITAG_VALUE,
        payload,
      })
    }
  }

  useEffect(() => {
    handleAdd(pets[firstIndex] ? firstIndex : 0)
  }, [])

  return {
    intellitags,
    onAdd: handleAdd,
    onRemove: handleRemove,
    onChange: handleChange,
  }
}

export default useIntellitag
