import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'
import { saveIntellitags } from 'UserApp/store/signup/actions'
import IntellitagScreen from 'UserApp/screens/Signup/Premium/Intellitag'
import useIntellitags from './useIntellitags'

IntellitagScreenContainer.propTypes = {
  saveIntellitags: PropTypes.func,
  intellitags: PropTypes.array,
}

function IntellitagScreenContainer(props) {
  const { saveIntellitags } = props
  const [isValidated, setIsValidated] = useState(false)
  const { intellitags, onAdd, onRemove, onChange, onValidate } = useIntellitags(
    props.intellitags,
  )

  function handleSubmit(_event) {
    if (onValidate()) {
      setIsValidated(true)
    }
  }

  useEffect(() => {
    if (isValidated) {
      saveIntellitags(intellitags)
      props.history.push('/signup/premium/shipping')
    }
  }, [intellitags, isValidated])

  return (
    <IntellitagScreen
      intellitags={intellitags}
      onSubmit={handleSubmit}
      onAdd={onAdd}
      onRemove={onRemove}
      onChange={onChange}
    />
  )
}

export default connect(
  (state) => ({
    intellitags: state.signup.intellitags,
  }),
  {
    saveIntellitags,
  },
)(IntellitagScreenContainer)
