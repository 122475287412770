import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'
import Base, { BaseProps } from '../Base'

type Props = BaseProps<'div'>

function FieldGroup({ className, ...rest }: Props) {
  return <Base className={classNames(style.wrapper, className)} {...rest} />
}

export default FieldGroup
