import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import PetSafetyScreen from 'ContactApp/screens/PetSafety'

class PetSafetyScreenContainer extends React.PureComponent {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string,
      }),
    }),
  }

  state = {
    isLoading: true,
    user: undefined,
    error: '',
  }

  async componentDidMount() {
    const { match } = this.props
    const { params } = match
    const { token } = params

    try {
      const { user, alarm, response } = await api.alarmContactRequests.fetchOne(
        token,
      )

      this.setState({ isLoading: false, user, alarm, answer: response })
    } catch (error) {
      this.setState({ isLoading: false, error })
    }
  }

  handleSafe = async () => {
    return this.handleSubmit('safe')
  }

  handleDecline = async () => {
    return this.handleSubmit('decline')
  }

  handleSubmit = async (answer) => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      await api.alarmContactRequests[answer](token)
      this.setState({ answer })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  generateProps = () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    return {
      ...this.props,
      ...this.state,
      token,
      onSafe: this.handleSafe,
      onDecline: this.handleDecline,
    }
  }

  render() {
    const props = this.generateProps()

    return <PetSafetyScreen {...props} />
  }
}

export default PetSafetyScreenContainer
