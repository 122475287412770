import { schema } from 'normalizr'

export default new schema.Entity(
  'contacts',
  {},
  {
    processStrategy: (entity) => {
      return {
        ...entity,
        isPending: entity.inviteStatus === 'sent',
        isDeclined: entity.inviteStatus === 'declined',
      }
    },
  },
)
