import React from 'react'
import queryString from 'query-string'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import VaultDetailsScreen from 'ContactApp/screens/Vault/Details'

class VaultDetailsContainer extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
      }),
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
  }

  state = { isLoading: true, didDecline: false, isSafe: undefined }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { match, location } = this.props
    const { params } = match
    const { id, token } = params
    const { accessPin } = queryString.parse(location.search)

    this.setState({ isLoading: true })

    try {
      const { user, pets, vaultAccess } = await api.vaults.fetchOne(
        id,
        accessPin,
      )

      this.setState({
        isLoading: false,
        user,
        pets,
        vaultAccess: {
          ...vaultAccess,
          accessExpires: new Date(vaultAccess.accessExpires),
        },
      })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false, isExpired: true, token })
    }
  }

  handleCancel = async () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      await api.alarmContactRequests.declined(token)
      this.setState({ didDecline: true, token })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  handleSafe = async (answer) => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      const request = await api.alarmContactRequests.safe(token)
      this.setState({ isSafe: true, token: request.token })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onCancel: this.handleCancel,
    onSafe: this.handleSafe,
  })

  render() {
    const props = this.generateProps()

    return <VaultDetailsScreen {...props} />
  }
}

export default VaultDetailsContainer
