import React, { ReactNode } from 'react'

import Header from '../../Header'
import Footer from '../../Footer'
import style from './style.module.scss'

interface Props {
  children: ReactNode
}

function FullLayout(props: Props) {
  const { children } = props

  return (
    <>
      <Header />
      <div className={style.wrapper}>
        {children}
        <Footer />
      </div>
    </>
  )
}

export default FullLayout
