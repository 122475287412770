import React from 'react'

import Input, { Props as InputProps } from '../'
import formatPhone from 'lib/format-phone'

type Props = InputProps

function PhoneInput(props: Props) {
  const { value = '' } = props
  return <Input {...props} type='phone' value={formatPhone(value)} />
}

export default PhoneInput
