import React from 'react'
import PropTypes from 'prop-types'
import { parse, addDays } from 'date-fns'
import { connect } from 'react-redux'

import { AlarmType } from 'types'

import { create, turnOff } from 'UserApp/store/alarm/actions'
import { selectAlarm } from 'UserApp/store/alarm/selectors'
import AlarmBanner from 'UserApp/components/Alarm/Banner'

class AlarmBannerContainer extends React.PureComponent {
  static propTypes = {
    alarm: PropTypes.shape(AlarmType),
    create: PropTypes.func,
    turnOff: PropTypes.func,
  }

  state = {
    isLoading: false,
    isTurningOff: false,
  }

  handleResetUntilTomorrow = async () => {
    const { create, turnOff, alarm } = this.props
    this.setState({ isLoading: true })

    const startTime = addDays(alarm.startTime, 1)

    await turnOff()
    await create({ startTime })

    this.setState({ isLoading: false })
  }

  handleCancelTurnOff = () => this.setState({ isTurningOff: false })
  handleTurnOff = () => this.setState({ isTurningOff: true })

  handleConfirmTurnOff = async () => {
    const { turnOff } = this.props
    this.setState({ isTurningOff: false, isLoading: true })
    await turnOff()
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onResetUntilTomorrow: this.handleResetUntilTomorrow,
    onTurnOff: this.handleTurnOff,
    onCancelTurnOff: this.handleCancelTurnOff,
    onConfirmTurnOff: this.handleConfirmTurnOff,
    onDismiss: this.handleTurnOff,
  })

  render() {
    const props = this.generateProps()

    return <AlarmBanner {...props} />
  }
}
export default connect(
  (state) => ({
    alarm: selectAlarm(state),
  }),
  { create, turnOff },
)(AlarmBannerContainer)
