import React, { useState } from 'react'

import Text from 'sharedComponents/Text'
import Loader from 'sharedComponents/Loader'
import Form from 'sharedComponents/Form'
import Label from 'sharedComponents/Label'
import Button from 'sharedComponents/Button'
import Error from 'sharedComponents/Error'
import PasswordInput from 'sharedComponents/Input/Password'
import style from './style.module.scss'

type Props = {
  onSubmit: (data: SignupPassword) => Promise<any>
  className?: string
}

function SetPasswordForm(props: Props) {
  const { onSubmit, className } = props
  const [isLoading, setIsLoading] = useState()

  async function handleSubmit(data: SignupPassword) {
    setIsLoading(true)

    try {
      await onSubmit(data)
    } catch (error) {
      setIsLoading(false)
      throw error
    }
    setIsLoading(false)
  }

  return (
    <Form<SignupPassword> onSubmit={handleSubmit} className={className}>
      {({ onChange, values, error }) =>
        isLoading ? (
          <Loader />
        ) : (
          <>
            <Label htmlFor='signup-password'>Set your password</Label>

            <Text className={style.note}>
              Must contain 6 characters including a number and capital letter
            </Text>
            <PasswordInput
              name='newPassword'
              id='signup-password'
              value={values.newPassword}
              onChange={onChange}
            />

            <Label htmlFor='signup-password'>Confirm password</Label>
            <PasswordInput
              name='confirmNewPassword'
              id='signup-repeatPassword'
              value={values.confirmNewPassword}
              onChange={onChange}
            />

            {error && <Error>{error}</Error>}

            <Button type='submit'>Set Password</Button>
          </>
        )
      }
    </Form>
  )
}

export default SetPasswordForm
