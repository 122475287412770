import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import style from './style.module.scss'

import Notification from 'containers/Notification'

import Header from '../Header'

SplitLayout.propTypes = {
  children: PropTypes.node,
  showNav: PropTypes.bool,
  alternateBackground: PropTypes.bool,
}

function SplitLayout(props) {
  const { children, showNav, alternateBackground, alternateBackground2 } = props

  return (
    <React.Fragment>
      <Notification />
      <Header showNav={showNav} />
      <div
        className={classNames(style.wrapper, {
          [style.alternateBackground]: alternateBackground,
          [style.alternateBackground2]: alternateBackground2,
        })}
      >
        <div className={style.inner}>
          <div>{children}</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SplitLayout
