import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink as Link } from 'react-router-dom'

import style from './style.module.scss'

Navigation.propTypes = {
  className: PropTypes.string,
}

function Navigation(props) {
  const { className, isInSignupFlow } = props
  const [isExpanded, setIsExpaneded] = useState(false)

  function handleToggleMenu() {
    setIsExpaneded((isExpanded) => !isExpanded)
  }

  function handleClickLink() {
    setIsExpaneded(false)
  }

  return (
    <nav
      className={classNames(style.wrapper, className, {
        [style.isExpanded]: isExpanded,
      })}
    >
      <button className={style.hamburger} onClick={handleToggleMenu} />
      <div className={style.links}>
        <a href='https://www.petnotify.com/' className={style.link}>
          Home
        </a>
        <a href='https://www.petnotify.com/how-it-works' className={style.link}>
          Product Suite
        </a>
        <a href='https://www.petnotify.com/pricing' className={style.link}>
          Pricing
        </a>
        <a href='https://www.petnotify.com/faq' className={style.link}>
          FAQ
        </a>
        <React.Fragment>
          <Link
            onClick={handleClickLink}
            to='/signin'
            className={classNames(style.link, style.signIn)}
            activeClassName={style.active}
          >
            Sign in
          </Link>
        </React.Fragment>
      </div>
    </nav>
  )
}

export default Navigation
