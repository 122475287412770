export const FORMS = {
  GENERAL: 'general',
  INTELLITAG: 'intellitag',
  EDIT_PET_PHOTO: 'edit-pet-photo',
  DIET_AND_MEDICAL: 'diet-and-medical',
  DOCUMENTS_AND_EXTRAS: 'documents-and-extras',
  CARE_PROVIDER: 'care-provider',
  DOCUMENT: 'document',
  TECH_DEVICE: 'tech-device',
  CHIP_ID: 'chip-id',
  INSURANCE: 'insurance',
  VET_CLINIC: 'vet-clinic',
}

export const TABS = [
  { form: FORMS.GENERAL, title: `General` },
  { form: FORMS.INTELLITAG, title: `Intellitag` },
  { form: FORMS.DIET_AND_MEDICAL, title: `Health` },
  { form: FORMS.DOCUMENTS_AND_EXTRAS, title: `Documents` },
]
