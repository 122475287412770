import { normalize } from 'normalizr'

import mixpanel, { EVENTS } from 'services/mix-panel'
import schema from './schema'

export const FETCHED_ONE = 'ALARM/FETCHED_ONE'
export const TURNED_OFF = 'ALARM/TURNED_OFF'

export function fetchOne() {
  return async (dispatch, getState, api) => {
    const response = await api.alarm.fetchOne()
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function create(data) {
  return async (dispatch, getState, api) => {
    const response = await api.alarm.create({ ...data, mode: 'manual' })

    mixpanel.track(EVENTS.ALARM_SET)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function triggerSOS() {
  return async (dispatch, getState, api) => {
    const response = await api.alarm.triggerSOS({ mode: 'manual' })

    mixpanel.track(EVENTS.ALARM_SET)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function update(data) {
  return async (dispatch, getState, api) => {
    const response = await api.alarm.update(data)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function turnOff() {
  return async (dispatch, getState, api) => {
    const response = await api.alarm.turnOff()
    dispatch({
      type: TURNED_OFF,
    })
    return response
  }
}
