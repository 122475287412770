export const API_URL = import.meta.env.VITE_API_URL || ''
export const STRIPE_PUBLISHABLE_KEY = import.meta.env
  .VITE_STRIPE_PUBLISHABLE_KEY
export const GOOGLE_ANALYTICS = import.meta.env.VITE_GOOGLE_ANALYTICS
export const SMARTLOOK_KEY = import.meta.env.VITE_SMARTLOOK_KEY
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY
export const IOS_APP_STORE_LINK = `https://itunes.apple.com/us/app/apple-store/id1457107815?mt=8`
export const GOOGLE_PLAY_STORE_LINK = `https://play.google.com/store/apps/details?id=com.petnotify.petnotifyapp`
export const MARKETING_SITE_HOME_URL = `//www.petnotify.com`
export const VERSION = undefined
