import React from 'react'
import classNames from 'classnames'
import { NavLink as Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Navigation.propTypes = {
  showNavigation: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onClickLink: PropTypes.func,
}

Navigation.defaultProps = {
  showNavigation: true,
}

function Navigation(props) {
  const { showNavigation, isExpanded, onClickLink } = props

  return (
    <nav
      className={classNames(style.wrapper, {
        [style.isExpanded]: isExpanded,
      })}
    >
      {showNavigation && (
        <React.Fragment>
          <Link
            onClick={onClickLink}
            to='/'
            exact
            className={classNames(style.link, style.vault)}
            activeClassName={style.active}
          >
            Pet Vault
          </Link>
          <Link
            onClick={onClickLink}
            to='/contacts'
            className={classNames(style.link, style.contacts)}
            activeClassName={style.active}
          >
            Contacts
          </Link>
          <Link
            onClick={onClickLink}
            to='/alarm'
            className={classNames(style.link, style.alarm)}
            activeClassName={style.active}
          >
            Alarms
          </Link>
          <Link
            onClick={onClickLink}
            to='/tasks'
            className={classNames(style.link, style.tasks)}
            activeClassName={style.active}
          >
            Tasks
          </Link>
          <Link
            onClick={onClickLink}
            to='/settings'
            className={classNames(style.link, style.more)}
            activeClassName={style.active}
          >
            More
          </Link>
        </React.Fragment>
      )}
    </nav>
  )
}

export default Navigation
