import React from 'react'
import PropTypes from 'prop-types'

import { ContactType } from 'types'
import Loader from 'components/Loader'
import { List, ListItem } from 'components/List'
import Text from 'components/Text'
import style from './style.module.scss'

AlarmContacts.propTypes = {
  isLoading: PropTypes.bool,
  contacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
}

function AlarmContacts(props) {
  const { isLoading, contacts } = props

  return isLoading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <List className={style.me}>
        <ListItem className={style.item}>You</ListItem>
      </List>
      <Text className={style.text} variant='small'>
        If you don’t respond in 1 hour, we’ll notify your contacts in the
        following order
      </Text>
      <List className={style.contacts}>
        {contacts.map((contact) => (
          <ListItem key={contact.id} className={style.item}>
            {contact.firstName} {contact.lastName}
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
}

export default AlarmContacts
