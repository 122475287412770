import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { selectAlarmHistory } from 'UserApp/store/alarm/selectors'
import AlarmHistory from 'UserApp/components/Alarm/History'

class AlarmHistoryContainer extends React.PureComponent {
  static propTypes = {
    history: PropTypes.array,
  }

  generateProps = () => ({
    ...this.props,
  })

  render() {
    const props = this.generateProps()

    return <AlarmHistory {...props} />
  }
}
export default connect((state) => ({
  history: selectAlarmHistory(state),
}))(AlarmHistoryContainer)
