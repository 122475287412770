import React from 'react'
import { connect } from 'react-redux'

import OrderIntellitagModal from 'UserApp/components/OrderIntellitagModal'

function OrderIntellitagModalContainer(props) {
  function handleClose() {
    props.history.push('/')
  }

  return <OrderIntellitagModal {...props} onClose={handleClose} />
}

export default connect((state) => ({
  orderId: state.intellitagOrder.id,
}))(OrderIntellitagModalContainer)
