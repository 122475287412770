import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

Selection.propTypes = {
  children: PropTypes.node,
}

function Selection(props) {
  const { children } = props

  return <div className={style.wrapper}>{children}</div>
}

export default Selection
