import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import HeaderOne from 'components/Headers/HeaderOne'
import Text from 'components/Text'
import { Button } from 'components/Forms'
import style from './style.module.scss'

TurnOffConfirmation.propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  isSOS: PropTypes.bool,
}

function TurnOffConfirmation(props) {
  const { onCancel, onConfirm, isSOS } = props

  return (
    <div className={classNames(style.wrapper, style.expired)}>
      <HeaderOne className={style.header}>{ (isSOS) ? 'Turn SOS off':'Turn alarm off'}</HeaderOne>

      <Text className={style.text}>
        {(isSOS) ? `Are you sure you want to stop the SOS? We'll notify all your
        previously notified contacts that you no longer need assistance, and
        will revoke their access to your Pet Vault.`:`Are you sure you want to turn off the alarm? We'll notify all your
        previously notified contacts that you no longer need assistance, and
        will revoke their access to your Pet Vault.`}
      </Text>

      <Button onClick={onCancel} variant='hollow' className={style.button}>
        Cancel
      </Button>
      <Button onClick={onConfirm} variant='red' className={style.button}>
        { (isSOS) ? 'Stop SOS':'Turn Alarm Off'}
      </Button>
    </div>
  )
}

export default TurnOffConfirmation
