import { useDispatch } from 'react-redux'

import { set } from 'store/notification/actions'

function useNotification() {
  const dispatch = useDispatch()

  return {
    setNotification: (text: string) => {
      dispatch(set(text))
    },
  }
}

export default useNotification
