import React from 'react'
import PropTypes from 'prop-types'
import { format, parse, addHours } from 'date-fns'
import { connect } from 'react-redux'

import { set as setNotification } from 'store/notification/actions'
import { TaskType } from 'types'

import { remove, add, fetchOne, update } from 'UserApp/store/tasks/actions'
import { fetchAll as fetchAllContacts } from 'UserApp/store/contacts/actions'
import { selectOne } from 'UserApp/store/tasks/selectors'
import {
  selectAllOther as selectOtherContacts,
  selectAllEmergency as selectEmergencyContacts,
} from 'UserApp/store/contacts/selectors'
import TaskForm from 'UserApp/components/Tasks/Form'

const ALERT_OPTIONS = [
  { value: '', label: `Never` },
  { value: 0, label: `At task time` },
  { value: 5, label: `5 minutes before` },
  { value: 10, label: `10 minutes before` },
  { value: 15, label: `15 minutes before` },
  { value: 30, label: `30 minutes before` },
  { value: 45, label: `45 minutes before` },
  { value: 60, label: `1 hour before` },
  { value: 120, label: `2 hours before` },
  { value: 180, label: `3 hours before` },
]

const REPEAT_OPTIONS = [
  { value: '', label: `Never` },
  { value: 'daily', label: `Every day` },
  { value: 'weekly', label: `Every week` },
]

function formatDataForUI(data) {
  if (!data.repeat) {
    data.repeat = ''
  }

  if (data.startTime) {
    data.startTime = new Date(data.startTime)
  } else if (!data.id) {
    let now = new Date()

    if (now.getMinutes() > 0) {
      now.setMinutes(0)
      now = addHours(now, 1)
    }

    data.startTime = now
  }

  return data
}

function formatDataForAPI(data) {
  const updatedData = Object.keys(data).reduce((acc, key) => {
    let value = data[key]

    if (value === '') {
      value = undefined
    }

    acc[key] = value

    return acc
  }, {})

  if (updatedData.contactId === 'me') {
    updatedData.contactId = null
  }

  return updatedData
}

class TaskFormContainer extends React.PureComponent {
  static propTypes = {
    add: PropTypes.func,
    fetchOne: PropTypes.func,
    fetchAllContacts: PropTypes.func,
    otherContacts: PropTypes.array,
    emergencyContacts: PropTypes.array,
    id: PropTypes.string,
    onClose: PropTypes.func,
    remove: PropTypes.func,
    update: PropTypes.func,
    values: PropTypes.shape(TaskType),
    setNotification: PropTypes.func,
  }

  state = {
    isConfirmingRemove: false,
    isLoading: true,
    isProcessing: false,
  }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { id, fetchOne, fetchAllContacts } = this.props

    if (id) {
      await fetchOne(id)
    }

    await fetchAllContacts()

    this.setState({
      isLoading: false,
    })
  }

  handleSubmit = async (data) => {
    const { id, add, update, onClose, setNotification } = this.props

    this.setState({
      isProcessing: true,
    })

    const updatedData = formatDataForAPI(data)

    try {
      if (id) {
        await update(id, updatedData)

        setNotification(`Task updated`)
      } else {
        await add(updatedData)

        setNotification(`Task created`)
      }
      onClose()
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  handleRemove = () => this.setState({ isConfirmingRemove: true })

  handleCancelRemove = () => this.setState({ isConfirmingRemove: false })

  handleConfirmRemove = async () => {
    const { remove, onClose, id, setNotification } = this.props

    this.setState({
      isProcessing: true,
    })

    try {
      await remove(id)

      setNotification(`Task removed`)

      onClose()
    } catch (error) {
      this.setState({
        isProcessing: false,
      })
      throw error
    }
  }

  formatContactOption = (contact) => {
    const { id, name, isPending } = contact

    return {
      value: id,
      disabled: isPending,
      label: `${name}${isPending ? ` (Pending)` : ``}`,
    }
  }

  getContactsOptions = () => {
    const { otherContacts, emergencyContacts } = this.props

    return [...otherContacts, ...emergencyContacts].reduce(
      (acc, contact) => {
        acc.push(this.formatContactOption(contact))
        return acc
      },
      [{ value: 'me', label: 'Me' }],
    )
  }

  generateProps = () => {
    const { id, values } = this.props

    return {
      ...this.state,
      ...this.props,
      values: formatDataForUI({ ...values }),
      repeatOptions: REPEAT_OPTIONS,
      alertOptions: ALERT_OPTIONS,
      assignToOptions: this.getContactsOptions(),
      onSubmit: this.handleSubmit,
      onRemove: this.handleRemove,
      onConfirmRemove: this.handleConfirmRemove,
      onCancelRemove: this.handleCancelRemove,
      isEditing: !!id,
    }
  }

  render() {
    const props = this.generateProps()

    return <TaskForm {...props} />
  }
}

export default connect(
  (state, ownProps) => {
    return {
      values: selectOne(state, ownProps) || {},
      emergencyContacts: selectEmergencyContacts(state),
      otherContacts: selectOtherContacts(state),
    }
  },
  {
    add,
    update,
    remove,
    fetchOne,
    setNotification,
    fetchAllContacts,
  },
)(TaskFormContainer)
