import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'

type Option = {
  value: string
  label: string
  disabled?: boolean
}

type Props = React.HTMLProps<HTMLSelectElement> & {
  options: Option[] | string[]
  includeBlank?: boolean
}

const Dropdown = React.forwardRef<HTMLSelectElement, Props>(
  ({ className, options, includeBlank = true, ...rest }: Props, ref) => {
    return (
      <select
        className={classNames(style.wrapper, className)}
        {...rest}
        ref={ref}
      >
        {includeBlank && 
          <option value="" disabled selected>
            {options?.length>0 ? 'Select option' : 'Not available'}
          </option>
        }

        {options.map((option) => {
          if (typeof option === 'string') {
            return <option key={option}>{option}</option>
          } else {
            const { value, label, disabled } = option

            return (
              <option key={value} value={value} disabled={disabled}>
                {label}
              </option>
            )
          }
        })}
      </select>
    )
  },
)

export default Dropdown
