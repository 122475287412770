import React from 'react'

import step1 from './images/step1.jpg'
import step2 from './images/step2.jpg'
import step3 from './images/step3.jpg'
import step4 from './images/step4.jpg'
import step5 from './images/step5.jpg'
import style from './style.module.scss'

type Props = {
  step: number
}

const images: { [key: number]: string } = {
  1: step1,
  2: step2,
  3: step3,
  4: step4,
  5: step5,
}

function MobileHeaderImage(props: Props) {
  const { step } = props
  const image = images[step]

  return (
    <div className={style.wrapper}>
      <img src={image} />
    </div>
  )
}

export default MobileHeaderImage
