import React, { useContext } from 'react'

import { Button } from 'components/Forms'
import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

HeaderButton.propTypes = {}

function HeaderButton(props) {
  const { color } = useContext(ColorContext)

  return <Button {...props} className={style[color]} />
}

export default HeaderButton
