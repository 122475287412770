import React from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { Button, Header, Text, Base, Loader } from '@pet-notify/ui'

import api from 'lib/api'
import useNotification from 'UserApp/hooks/useNotification'
import formatMoney from 'lib/format-money'
import style from './style.module.scss'

type Interval = 'year' | 'month'

type FormData = {
  interval: Interval
}

type Plan = {
  amount: number
  interval: Interval
}

type Props = {
  onSuccess: () => void
}

function PlanForm({ onSuccess }: Props) {
  const { register, handleSubmit, reset, watch } = useForm<FormData>()
  const { setNotification } = useNotification()

  const update = useMutation(
    (data: FormData) => api.patch('/api/users/me/subscription/plan', data),
    {
      onSuccess: () => {
        setNotification(`Billing interval updated`)
        onSuccess()
      },
    },
  )

  const planQuery = useQuery(
    'plan',
    () => api.get<Plan>('/api/users/me/subscription/plan'),
    {
      onSuccess: (data) => {
        reset({ interval: data.data.interval })
      },
    },
  )

  const plansQuery = useQuery('plans', () =>
    api.get<Plan[]>('/api/subscriptions/plans'),
  )

  const selectedInterval = watch('interval')

  if (planQuery.isLoading || plansQuery.isLoading || update.isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit((values) => update.mutate(values))}>
      <Header as='h3' mb='4' size='xlarge'>
        Billing interval
      </Header>

      <Text mb='4'>
        Choose how you would like to be billed for your subscription. One plan
        provides Intellitag protection for all your household pets. Pet Notify
        members receive FREE annual replacement Intellitags ordered directly
        through your pet's vault.
      </Text>

      <Base mb='4'>
        {plansQuery.data.data.map(({ interval, amount }) => (
          <label
            key={interval}
            className={classNames(style.plan, {
              [style.selected]: interval === selectedInterval,
            })}
          >
            <input
              className={style.radio}
              type='radio'
              value={interval}
              {...register('interval')}
            />
            <div className={style.title}>
              {interval === 'year'
                ? formatMoney(amount / 12 / 100)
                : formatMoney(amount / 100)}{' '}
              per month
            </div>
            <div className={style.subTitle}>
              Paid {interval === 'year' ? 'annually' : 'monthly'}
            </div>
            {interval === 'year' && (
              <div className={style.pill}>45% Savings</div>
            )}
          </label>
        ))}
      </Base>

      <Base>
        <Button type='submit'>Next</Button>
      </Base>
    </form>
  )
}

export default PlanForm
