import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  Header,
  Loader,
  Button,
  Text,
  Label,
  FieldGroup,
  PasswordInput,
  Fields,
} from '@pet-notify/ui'

import api from 'lib/api'
import useNotification from 'UserApp/hooks/useNotification'

type FormData = {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

type Props = {
  onClose: () => void
}

function ChangePasswordForm({ onClose }: Props) {
  const { setNotification } = useNotification()
  const { register, ...form } = useForm<FormData>()

  const { mutate, isLoading, isError, error } = useMutation(
    (data: FormData) => api.put('/api/users/me/password', data),
    {
      onSuccess: () => {
        setNotification('Password changed')
        onClose()
      },
    },
  )

  const handleSubmit = form.handleSubmit((values) => {
    mutate(values)
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header mb='6'>Change password</Header>

      <Fields>
        <FieldGroup>
          <Label htmlFor='currentPassword'>Current password</Label>
          <PasswordInput
            id='currentPassword'
            {...register('currentPassword', { required: true })}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='newPassword'>New password</Label>
          <PasswordInput
            id='newPassword'
            {...register('newPassword', { required: true })}
          />
          <Text>
            Then new password needs to be at least 8 characters in length
            containing at least 1 number.
          </Text>
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='confirmNewPassword'>Confirm new password</Label>
          <PasswordInput
            id='confirmNewPassword'
            {...register('confirmNewPassword', { required: true })}
          />
        </FieldGroup>

        {isError && <Text variant='error'>{error}</Text>}
      </Fields>
      <Button type='submit'>Change password</Button>
    </form>
  )
}
export default ChangePasswordForm
