import React, { useState } from 'react'
import numeral from 'numeral'

import Layout from 'sharedComponents/Layout/Split'
import HeaderOne from 'sharedComponents/HeaderOne'
import HeaderTwo from 'sharedComponents/HeaderTwo'
import INTELLITAG_SIZE_OPTIONS from 'lib/intellitag-size-options'
import PET_TYPE_OPTIONS from 'lib/intellitag-pet-type-options'
import { Button, Dropdown, Selection, SelectionOption } from 'components/Forms'
import Label from 'sharedComponents/Label'
import Error from 'sharedComponents/Error'
import Link from 'sharedComponents/Link'
import Input from 'sharedComponents/Input'
import Text from 'sharedComponents/Text'
import PhoneInput from 'sharedComponents/Input/Phone'
import Steps from 'UserApp/components/Steps'
import Modal from 'sharedComponents/Modal'
import tagFrontImage from './images/tag-front.jpg'
import tagBackImage from './images/tag-back.jpg'
import MobileHeaderImage from '../../MobileHeaderImage'
import vaultImage from './images/vault.jpg'
import mixPanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'

type Props = {
  onSubmit: () => void
  onChange: () => void
  onAdd: () => void
  onRemove: () => void
  intellitags: []
  wasSubmitted: boolean
}

function IntellitagScreen(props: Props) {
  const { onSubmit, onChange, intellitags, onAdd, onRemove } = props

  const [isShowingModal, setIsShowingModal] = useState(false)
  const [isShowingFront, setIsShowingFront] = useState(true)
  const hasNameThatIsTooLong = intellitags.some((i) => i.nameTooLong)

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit()
  }

  function handleShowModal() {
    setIsShowingModal(true)
  }

  function handleCloseModal() {
    setIsShowingModal(false)
  }

  return (
    <Layout>
      <MobileHeaderImage step={2} />
      <Steps currentStep={2} totalSteps={4} />
      <HeaderOne className={style.title}>Customize your Intellitag</HeaderOne>
      <div className={style.image}>
        <img src={vaultImage} alt='Pet Vault on iPhone and Intellitag' />
      </div>
      <form onSubmit={handleSubmit} className={style.form}>
        {intellitags.map((intellitag, index) => (
          <React.Fragment key={`intellitag${index}`}>
            {index > 0 && (
              <div className={style.header}>
                <HeaderTwo>Additional Intellitag</HeaderTwo>
                <Link
                  className={style.removeLink}
                  onClick={() => onRemove(index)}
                >
                  Remove
                </Link>
              </div>
            )}
            <Label htmlFor='intellitag-size'>Size of tag</Label>
            <Link className={style.sizeLink} onClick={handleShowModal}>
              What size Intellitag should I get for my pet?
            </Link>
            <Selection>
              {INTELLITAG_SIZE_OPTIONS.map((option) => (
                <SelectionOption
                  key={option.value}
                  name='size'
                  value={option.value}
                  title={option.label}
                  subTitle={option.subTitle}
                  isSelected={option.value === intellitag.size}
                  onChange={(props) => onChange({ ...props, index })}
                />
              ))}
            </Selection>
            <Label htmlFor='intellitag-type'>Pet type</Label>
            <Dropdown
              name='type'
              value={intellitag.type}
              onChange={(props) => onChange({ ...props, index })}
              options={PET_TYPE_OPTIONS}
              id='intellitag-type'
            />

            <Label htmlFor='intellitag-weight'>Pet weight (in pounds)</Label>
            <Input
              name='weight'
              value={intellitag.weight}
              onChange={(props) => onChange({ ...props, index })}
              id='intellitag-weight'
            />

            <Label
              htmlFor='intellitag-name'
              subTitle='This is the name reflected in your pet’s digital vault.'
            >
              Pet name
            </Label>
            <Input
              name='name'
              value={intellitag.name}
              onChange={(props) => onChange({ ...props, index })}
              id='intellitag-name'
            />

            <Label
              htmlFor='intellitag-name-on-tag'
              subTitle={`What name do you want engraved on your Intellitag? ${intellitag.maxCharacters} character limit.`}
            >
              Intellitag engraving
            </Label>
            <Input
              name='nameOnTag'
              value={intellitag.nameOnTag}
              onChange={(props) => onChange({ ...props, index })}
              id='intellitag-name-on-tag'
              invalid={intellitag.nameTooLong}
            />
            {intellitag.nameTooLong && (
              <Error>
                Name must not be over {intellitag.maxCharacters} characters long
              </Error>
            )}

            <Label
              htmlFor='phone'
              subTitle='This number is printed on your Intellitag and is optional since your Intellitag ID will call, email, and text you when someone finds your lost pet.'
            >
              Mobile phone number
            </Label>
            <PhoneInput
              name='phone'
              value={intellitag.phone}
              onChange={(props) => onChange({ ...props, index })}
              placeholder='Optional'
              id='phone'
            />

            {intellitag.error && <Error>{intellitag.error}</Error>}
          </React.Fragment>
        ))}

        <div className={style.buttons}>
          <Button
            type='submit'
            className={style.submitButton}
            disabled={hasNameThatIsTooLong}
            onClick={() => mixPanel.track(EVENTS.BUY_INTELLITAG_CLICK)}
          >
            Continue
          </Button>
          <Button
            noId
            variant='hollow'
            className={style.addAnotherButton}
            onClick={onAdd}
          >
            Add another Intellitag
          </Button>
        </div>

        <Text>
          Your pet's Intellitag ID is directly tied to your Pet Notify user
          account and will not provide lost pet communications if gifted,
          distributed or transferred to any 3rd party.
        </Text>
      </form>

      {isShowingModal && (
        <Modal onClose={handleCloseModal} showCloseButton={false}>
          <div>
            <HeaderOne>What size Intellitag should I get for my pet?</HeaderOne>
            <div className={style.image}>
              {isShowingFront ? (
                <img
                  src={tagFrontImage}
                  alt='Small, Medium and Large intellitags'
                />
              ) : (
                <img
                  src={tagBackImage}
                  alt='Small, Medium and Large intellitags'
                />
              )}
            </div>
            <div className={style.tagButtons}>
              <Button
                variant='hollow'
                onClick={() => setIsShowingFront((s) => !s)}
              >
                {isShowingFront
                  ? 'Show Back of Intellitag'
                  : 'Show Front of Intellitag'}
              </Button>
            </div>
            <Text className={style.modalTitle}>Small</Text>
            <Text className={style.modalText}>
              .95" diameter, recommended for pets &lt; 10lbs.
            </Text>
            <Text className={style.modalTitle}>Medium</Text>
            <Text className={style.modalText}>
              1.10" diameter, recommended for pets 10-25lbs.
            </Text>
            <Text className={style.modalTitle}>Large</Text>
            <Text className={style.modalText}>
              1.25” diameter, recommended for pets &gt; 25lbs.
            </Text>
            <Button className={style.modalButton} onClick={handleCloseModal}>
              Ok, got it
            </Button>
          </div>
        </Modal>
      )}
    </Layout>
  )
}

export default IntellitagScreen
