import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Text from 'components/Text'
import Loader from 'components/Loader'
import { Button } from 'components/Forms'

import style from './style.module.scss'

InvitationDeclinedScreen.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object,
}

function InvitationDeclinedScreen(props) {
  const { isLoading, user } = props

  if (isLoading) {
    return <Loader />
  }

  const { firstName, name } = user

  return (
    <Layout showNav={false}>
      <HeaderOne className={style.header}>
        You’ve declined to be an emergency contact for {name}.
      </HeaderOne>
      <Text className={style.text}>
        We’ll notify {firstName} that you’ve declined. If you change your mind,
        please contact {firstName} to have them send you a new invitation to Pet
        Notify.
      </Text>
      <Button href='https://www.petnotify.com/' target='_blank'>
        Learn more about Pet Notify
      </Button>
    </Layout>
  )
}

export default InvitationDeclinedScreen
