import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { set as setNotification } from 'store/notification/actions'

import { logout } from 'UserApp/store/user/actions'

class SignoutContainer extends React.Component {
  static propTypes = {
    logout: PropTypes.func,
    setNotification: PropTypes.func,
  }

  componentDidMount() {
    const { setNotification, logout, location } = this.props
    const { state } = location
    setNotification(
      state && state.notification ? state.notification : `You are signed out`,
    )
    logout()
  }

  render() {
    return <Redirect to='/' />
  }
}

export default connect(null, { logout, setNotification })(SignoutContainer)
