import React, { useState } from 'react'
import { connect } from 'react-redux'

import { setShippingAddress } from 'UserApp/store/intellitagOrder/actions'
import Shipping from 'UserApp/components/OrderIntellitagModal/Shipping'

function ShippingContainer(props) {
  const { setShippingAddress, defaultValues, isMobile } = props
  const [wasSubmitted, setWasSubmitted] = useState(false)

  async function handleSubmit(data) {
    const { address, city, state, zip, phone } = data

    if (!address || address === '') {
      throw new Error('Address required')
    }
    if (!city || city === '') {
      throw new Error('City required')
    }
    if (!state || state === '') {
      throw new Error('State required')
    }
    if (!zip || zip === '') {
      throw new Error('Zip required')
    }
    if (!phone || phone === '') {
      throw new Error('Phone required')
    }

    setShippingAddress(data)
    setWasSubmitted(true)
  }

  return (
    <Shipping
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      wasSubmitted={wasSubmitted}
      isMobile={isMobile}
    />
  )
}

export default connect(
  (s) => {
    const { phone, address, address2, city, state, country, zip } = s.user

    return {
      defaultValues: {
        phone,
        address,
        address2,
        city,
        state,
        country,
        zip,
      },
    }
  },
  { setShippingAddress },
)(ShippingContainer)
