import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Link from 'components/Link'

import style from './style.module.scss'

BackLink.propTypes = {
  className: PropTypes.string,
}

function BackLink(props) {
  const { className, ...rest } = props
  return <Link {...rest} className={classNames(style.wrapper, className)} />
}

export default BackLink
