import React from 'react'
import PropTypes from 'prop-types'

import Label from '../Label'
import style from './style.module.scss'

RadioButton.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
}

function RadioButton(props) {
  const { name, value, onChange, id, ...rest } = props

  return (
    <div className={style.wrapper}>
      <input
        type='radio'
        name={name}
        value={value}
        id={id}
        className={style.radioButton}
        onChange={() => onChange({ name, value })}
        {...rest}
      />

      <Label htmlFor={id} className={style.label} />
    </div>
  )
}

export default RadioButton
