import React, { ReactNode } from 'react'
import classNames from 'classnames'

import Header from '../../Header'
import style from './style.module.scss'

type Props = {
  children: ReactNode
  cat?: boolean
}

function SplitLayout(props: Props) {
  const { children, cat } = props

  return (
    <>
      <Header />
      <div
        className={classNames(style.wrapper, {
          [style.cat]: cat,
          [style.dog]: !cat,
        })}
      >
        <div className={style.inner}>
          <div>{children}</div>
        </div>
      </div>
    </>
  )
}

export default SplitLayout
