import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

StripeInput.propTypes = {
  component: PropTypes.func,
  onChange: PropTypes.func,
}

function StripeInput(props) {
  const { component: Component, onChange } = props

  function handleChange(event) {
    const { elementType, complete } = event
    onChange({ name: elementType, isValid: complete })
  }

  return (
    <Component
      onChange={handleChange}
      options={{
        placeholder: '',
        style: {
          base: {
            color: '#4f4f4f',
            fontFamily: `'Nunito Sans', sans-serif`,
            fontSize: '14px',
          },
        },
        classes: {
          base: style.wrapper,
          invalid: style.invalid,
          focus: style.focus,
        },
      }}
    />
  )
}

export default StripeInput
