import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { saveShippingAddress } from 'UserApp/store/signup/actions'
import SignupShippingScreen from 'UserApp/screens/Signup/Premium/Shipping'

SignupShippingScreenContainer.propTypes = {
  saveShippingAddress: PropTypes.func,
  shippingAddress: PropTypes.object,
}

function SignupShippingScreenContainer(props) {
  const { saveShippingAddress, shippingAddress } = props

  function handleSubmit(data) {
    if (!data.address || data.address === '') {
      throw new Error('Address required')
    }
    if (!data.city || data.city === '') {
      throw new Error('City required')
    }
    if (!data.state || data.state === '') {
      throw new Error('State required')
    }
    if (!data.zip || data.zip === '') {
      throw new Error('Zip required')
    }
    if (!data.phone || data.phone === '') {
      throw new Error('Mobile phone required')
    }

    saveShippingAddress(data)
    props.history.push('/signup/premium/payment')
  }

  return (
    <SignupShippingScreen
      defaultValues={shippingAddress}
      onSubmit={handleSubmit}
    />
  )
}

export default connect(
  (state) => ({
    shippingAddress: state.signup.shippingAddress || { country: 'US' },
  }),
  { saveShippingAddress },
)(SignupShippingScreenContainer)
