import React from 'react'

import Layout from 'components/Layout/SplitLayout'
import { Header, Text } from '@pet-notify/ui'
import { useLocation } from 'react-router-dom'

function ForgotPasswordCheckEmailScreen() {
  const location = useLocation()
  const { email } = location.state

  return (
    <Layout alternateBackground2>
      <Header as='h1' size='xxlarge' mb='8'>
        Check your email
      </Header>
      <Text>
        An email has been sent to {email} with information on how to reset your
        password.
      </Text>
    </Layout>
  )
}
export default ForgotPasswordCheckEmailScreen
