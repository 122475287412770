import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { connect } from 'react-redux'

import api from 'services/pet-notify-api'
import { submitOrder } from 'UserApp/store/intellitagOrder/actions'
import Payment from 'UserApp/components/OrderIntellitagModal/Payment'

function PaymentContainer(props) {
  const stripe = useStripe()
  const elements = useElements()
  const { promoCode, submitOrder, intellitagOrder, isMobile } = props
  const [isLoading, setIsLoading] = useState(true)
  const [isProcessing, setIsProcessing] = useState(false)
  const [wasSuccessful, setWasSuccessful] = useState(false)
  const [breakdown, setBreakdown] = useState()
  const [canMakePayment, setCanMakePayment] = useState(false)
  const [isPaymentRequired, setIsPaymentRequired] = useState(true)

  const paymentRequest = useRef()

  useEffect(() => {
    if (stripe && intellitagOrder.intellitags) {
      setIsLoading(true)
      api.intellitagOrders
        .breakdown({ ...intellitagOrder, promoCode })
        .then((result) => {
          const paymentRequired = result.total > 0
          setBreakdown(result)
          setIsPaymentRequired(paymentRequired)
          setIsLoading(false)

          if (isPaymentRequired) {
            console.log('Creating paymentRequest with total:', result.total);
            paymentRequest.current = stripe.paymentRequest({
              country: 'US',
              currency: 'usd',
              total: {
                label: 'Intellitag(s)',
                amount: result.total,
              },
              requestPayerName: true,
              requestPayerEmail: true,
            })

            paymentRequest.current.on('token', async ({ complete, token }) => {
              setIsProcessing(true)

              try {
                await submitOrder({
                  ...intellitagOrder,
                  token: token.id,
                  promoCode,
                })
                setWasSuccessful(true)
                // complete('success')
              } catch (e) {
                setIsProcessing(false)
                // complete('fail')
                throw e
              }
            })

            paymentRequest.current.canMakePayment().then((result) => {
              // console.log('canMakePayment result:', result);
              setCanMakePayment(!!result)
            })
          }
        })
    }
  }, [stripe, intellitagOrder, promoCode])

  const handleSubmit = useCallback(
    async (data) => {
      let paymentData = {}

      setIsProcessing(true)

      if (isPaymentRequired) {
        const { zip } = data

        if (!zip || zip === '') {
          setIsProcessing(false)
          throw new Error('Billing ZIP code required')
        }

        const cardNumberElement = elements.getElement(CardNumberElement)

        const { token, error } = await stripe.createToken(cardNumberElement, {
          address_zip: zip,
        })

        if (error) {
          setIsProcessing(false)
          throw error.message
        }

        paymentData = {
          promoCode,
          token: token.id,
        }
      }

      try {
        await submitOrder({
          ...intellitagOrder,
          ...paymentData,
        })
        setWasSuccessful(true)
      } catch (e) {
        setIsProcessing(false)
        throw e
      }
    },
    [isPaymentRequired],
  )

  return (
    <Payment
      isPaymentRequired={isPaymentRequired}
      isMobile={isMobile}
      breakdown={breakdown}
      onSubmit={handleSubmit}
      isProcessing={isProcessing}
      isLoading={isLoading}
      wasSuccessful={wasSuccessful}
      shippingAddress={intellitagOrder.shippingAddress}
      paymentRequest={canMakePayment && paymentRequest.current}
    />
  )
}

export default connect(
  (state) => ({
    user: state.user,
    intellitagOrder: state.intellitagOrder,
    promoCode: state.promoCode && state.promoCode.code,
  }),
  { submitOrder },
)(PaymentContainer)
