import React from 'react'
import style from './style.module.scss'

function Error(props) {
  const { children } = props

  return <div className={style.wrapper}>{children}</div>
}

export default Error
