import React from 'react'
import PropTypes from 'prop-types'

import PetPhoto from 'components/Pets/Photo'

import Row, * as RowElement from '../Row'

import style from './style.module.scss'

VaultItemGeneral.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  photo: PropTypes.object,
  meta: PropTypes.object,
}

function VaultItemGeneral(props) {
  const { name, type, photo, meta } = props
  const {
    breed,
    weight,
    weightUnit,
    chipID,
    chipProvider,
    specialInstructions,
  } = meta

  return (
    <div className={style.wrapper}>
      {photo && (
        <PetPhoto src={photo.sizes.medium.src} className={style.photo} />
      )}

      <Row>
        <RowElement.Title>Name</RowElement.Title>
        <RowElement.Value>{name}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Type</RowElement.Title>
        <RowElement.Value>{type}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Breed</RowElement.Title>
        <RowElement.Value>{breed}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Weight</RowElement.Title>
        <RowElement.Value>
          {weight}
          {weightUnit}
        </RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Microchip Provider</RowElement.Title>
        <RowElement.Value>{chipProvider}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Microchip ID</RowElement.Title>
        <RowElement.Value>{chipID}</RowElement.Value>
      </Row>
      <Row>
        <RowElement.Title>Notes</RowElement.Title>
        <RowElement.Value>{specialInstructions}</RowElement.Value>
      </Row>
    </div>
  )
}

export default VaultItemGeneral
