import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import possessive from 'lib/possessive'
import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import HeaderThree from 'components/Headers/HeaderThree'
import Loader from 'components/Loader'
import Text from 'components/Text'
import {
  Form,
  Input,
  Button,
  ErrorText,
  Note,
  Checkbox,
} from 'components/Forms'
import PhoneInput from 'sharedComponents/Input/Phone'
import style from './style.module.scss'

InvitationScreen.propTypes = {
  didAccept: PropTypes.bool,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onSubmit: PropTypes.func,
  token: PropTypes.string,
  userdata: PropTypes.object,
}

function InvitationScreen(props) {
  const { didAccept, error, isLoading, isProcessing, onSubmit, token, userdata } =
    props

  if (error) {
    return <ErrorText>{error}</ErrorText>
  }

  if (didAccept) {
    return <Redirect to={`/i/${token}/accepted`} push />
  }

  if (isLoading) {
    return <Loader />
  }
  //
  // if (didDecline) {
  //   return <Redirect to={`/i/${token}/declined`} push />
  // }
  const {user, contact} = userdata
  const { firstName, name } = user
  // console.log(`CONTACT INVITATION USER DATA :: `,user)
  // console.log(`CONTACT INVITATION CONTACT DATA :: `,contact)

  return (
    <Layout showNav={false}>
      <HeaderOne className={style.header}>
        Hello {contact.firstName}
      </HeaderOne>
      <HeaderOne className={style.header}>
        Welcome to Pet Notify!
      </HeaderOne>

      <Form onSubmit={onSubmit}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              {/* <PhoneInput
                required
                name='phone'
                placeholder='Enter your mobile phone'
                value={values.phone}
                onChange={onChange}
                className={style.input}
              /> */}
              <Text className={style.smallHeader}>
                Thank you for accepting to be {name}'s {(contact?.isEmergencyContact) ? 'Emergency':'Pet Care'} contact. If a Pet-Care assignment or an Emergency Alarm notification is triggered, we will provide you access to {firstName}'s Pet Vault with instructions to care for their pet(s) and a map of {firstName}'s last known location to assist if they need help.
              </Text>

              <Text className={style.disclosure}>
                By accepting you agree to receive SMS and email messages sent by
                Pet Notify on behalf of the user named above. Pet Notify will
                not share or use your contact information for any other purpose.
              </Text>

              <Checkbox
                name='agree'
                value={values.agree}
                onChange={onChange}
                label='I agree'
                id='agree'
              />

              <HeaderThree className={style.phoneHeader}>
                Email
              </HeaderThree>
              <Text className={style.text}>
                {contact.email} 
              </Text>
              <HeaderThree className={style.phoneHeader}>
                Mobile phone
              </HeaderThree>
              <Text className={style.text}>
                {contact.phone} 
              </Text>

              {error && <ErrorText>{error}</ErrorText>}

              <Button type='submit' className={style.button}>
                Confirm
              </Button>
            </React.Fragment>
          )
        }
      </Form>

      <Text className={style.smallHeader}>What is Pet Notify?</Text>

      <Text className={style.text}>
        Pet Notify is a service that helps protect pets and their parents. In
        the event that {firstName} or the pets need help, we will send a message
        and ask if you can assist.
      </Text>

      <Button
        href='https://www.petnotify.com'
        target='_blank'
        variant='hollow'
        className={style.learnMoreButton}
      >
        Learn more
      </Button>
    </Layout>
  )
}
export default InvitationScreen
