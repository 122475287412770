const APOSTROPHE_CHAR = '’'

export default function possessive(string) {
  if (string === '') {
    return string
  }

  const lastChar = string.slice(-1)
  const endOfWord =
    lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`
  return `${string}${endOfWord}`
}
