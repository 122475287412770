import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { set as setNotification } from 'store/notification/actions'

import LocationDetailsForm from 'UserApp/components/Alarm/LocationDetailsForm'
import { update } from 'UserApp/store/user/actions'
import { selectField } from 'UserApp/store/user/selectors'

class LocationDetailsFormContainer extends React.PureComponent {
  static propTypes = {
    update: PropTypes.func,
    setNotification: PropTypes.func,
  }

  state = {
    isProcessing: false,
  }

  handleSubmit = async (data) => {
    const { update, setNotification, onClose } = this.props
    this.setState({ isProcessing: true })
    await update(data)
    setNotification('Location activity saved')
    if (onClose) {
      onClose()
    } else {
      this.setState({ isProcessing: false })
    }
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
    onSubmit: this.handleSubmit,
  })

  render() {
    const props = this.generateProps()

    return <LocationDetailsForm {...props} />
  }
}

export default connect(
  (state) => ({
    values: { locationDetails: selectField(state, `locationDetails`) },
  }),
  { update, setNotification },
)(LocationDetailsFormContainer)
