import { normalize } from 'normalizr'

import mixpanel, { EVENTS } from 'services/mix-panel'
import schema from './schema'

export const FETCHED_ALL = 'CONTACTS/FETCHED_ALL'
export const FETCHED_ONE = 'CONTACTS/FETCHED_ONE'
export const UPDATE = 'CONTACTS/UPDATE'
export const ADD = 'CONTACTS/ADD'
export const REMOVE = 'CONTACTS/REMOVE'
export const SET_ORDER = 'CONTACTS/SET_ORDER'

export function fetchAll() {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.fetchAll()
    dispatch({
      type: FETCHED_ALL,
      payload: normalize(response, [schema]),
    })
    return response
  }
}

export function setOrder(data) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.setOrder(data)
    dispatch({
      type: SET_ORDER,
      payload: normalize(response, [schema]),
    })
    return response
  }
}

export function add(params) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.add(params)

    mixpanel.track(EVENTS.CONTACT_ADDED)

    dispatch({
      type: ADD,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function fetchOne(id) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.fetchOne(id)
    dispatch({
      type: FETCHED_ONE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function update(id, params) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.update(id, params)
    dispatch({
      type: UPDATE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function resendInvite(id, params) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.resendInvite(id, params)
    dispatch({
      type: UPDATE,
      payload: normalize(response, schema),
    })
    return response
  }
}

export function remove(id) {
  return async (dispatch, getState, api) => {
    const response = await api.contacts.remove(id)
    dispatch({
      type: REMOVE,
      payload: normalize(response, schema),
    })
    return response
  }
}
