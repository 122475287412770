import React from 'react'

import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import { Button } from 'components/Forms'

import style from './style.module.scss'

function VaultExpiredScreen() {
  return (
    <Layout showNav={false}>
      <HeaderOne className={style.header}>
        Sorry, access to that vault has expired.
      </HeaderOne>
      <Button href='https://www.petnotify.com/' target='_blank' small>
        Learn more about Pet Notify
      </Button>
    </Layout>
  )
}

export default VaultExpiredScreen
