import { schema } from 'normalizr'

export default new schema.Entity(
  'tasks',
  {},
  {
    processStrategy: (entity) => {
      return {
        ...entity,
        startTime: new Date(entity.startTime),
        completedAt: new Date(entity.completedAt),
      }
    },
  },
)
