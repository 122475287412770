import React, { useContext } from 'react'
import classNames from 'classnames'
import { camelize, decamelize } from 'humps'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  small: PropTypes.bool,
}

Button.defaultProps = {
  variant: `normal`,
}

function Button(props) {
  const {
    children,
    onClick,
    disabled,
    variant,
    className,
    to,
    href,
    type,
    noId,
    ...rest
  } = props
  const { color } = useContext(ColorContext)

  const sharedProps = {
    className: classNames(
      style.wrapper,
      style[variant],
      className,
      style[color],
    ),
    id: noId ? undefined : `${decamelize(camelize(children))}-button`,
  }

  if (to) {
    return (
      <RouterLink to={to} {...sharedProps}>
        {children}
      </RouterLink>
    )
  } else if (href) {
    return (
      <a href={href} {...sharedProps} {...rest}>
        {children}
      </a>
    )
  } else {
    if (type === 'submit') {
      return (
        <button
          type='submit'
          disabled={disabled}
          {...sharedProps}
          onClick={onClick}
        >
          {children}
        </button>
      )
    } else {
      const handleOnClick = (event) => {
        event.preventDefault()
        onClick(event)
      }

      return (
        <button onClick={handleOnClick} disabled={disabled} {...sharedProps}>
          {children}
        </button>
      )
    }
  }
}

export default React.memo(Button)
