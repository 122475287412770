import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import HeaderThree from 'sharedComponents/HeaderThree'
import { selectPromoCodeWithDiscount } from 'UserApp/store/promoCode/selectors'
import { removePromoCode } from 'UserApp/store/promoCode/actions'
import PromoCodeForm from './PromoCodeForm'
import PromoCodeDetails from './PromoCodeDetails'
import Link from 'sharedComponents/Link'
import style from './style.module.scss'

type Props = {
  isAnnualPlanSelected: boolean
  canRemove: boolean
}

function PromoCode(props: Props) {
  const { isAnnualPlanSelected, canRemove = true, size } = props
  const dispatch = useDispatch()
  const promoCodeWithDiscount = useSelector((state: State) =>
    selectPromoCodeWithDiscount(state, isAnnualPlanSelected),
  )

  function handleRemove() {
    dispatch(removePromoCode())
  }

  return (
    <div className={style.wrapper}>
      <HeaderThree className={style.header}>
        Promo code{' '}
        {canRemove && promoCodeWithDiscount && (
          <Link onClick={handleRemove} className={style.removeLink}>
            Remove
          </Link>
        )}
      </HeaderThree>
      {promoCodeWithDiscount ? (
        <PromoCodeDetails {...promoCodeWithDiscount} />
      ) : (
        <PromoCodeForm size={size} />
      )}
    </div>
  )
}

export default PromoCode
