import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link } from 'react-router-dom'

import Logo from 'components/Logo'
import { UpdatedButton as Button } from 'components/Forms'
import style from './style.module.scss'

Header.propTypes = {
  onCancel: PropTypes.func,
  onSafe: PropTypes.func,
}

function Header(props) {
  const { onCancel, onSafe } = props

  return (
    <header className={style.wrapper}>
      <Link to='/'>
        <Logo />
      </Link>

      <div className={style.buttons}>
        <Button onClick={onCancel} variant='hollowRed'>
          Cancel, I’m unavailable
        </Button>
        <Button className={style.safeButton} onClick={onSafe}>
          Mark pets as safe
        </Button>
      </div>
    </header>
  )
}

export default Header
