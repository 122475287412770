import { createSelector } from 'reselect'

const selectEntities = (state) => state.pets.entities
const selectResult = (state) => state.pets.result

export const selectAll = createSelector(
  [selectEntities, selectResult],
  (entities, result) => result.map((id) => entities[id]),
)

export const selectPets = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result.map((id) => ({ ...entities[id], id: String(id) })),
)

export const selectCount = (state) => {
  return state.pets.result.length
}

export const selectHasPetsWithoutIntellitags = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result.some(
      (id) =>
        entities[id].intellitags.length === 0 && !entities[id].hasPendingOrder,
    ),
)

export const selectPetsWithoutIntellitags = createSelector(
  [selectEntities, selectResult],
  (entities, result) =>
    result
      .filter(
        (id) =>
          entities[id].intellitags.length === 0 &&
          !entities[id].hasPendingOrder,
      )
      .map((id) => entities[id]),
)
