import React from 'react'
import classNames from 'classnames'

import { Link as RouterLink } from 'react-router-dom'
import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'

type Props = {
  className?: string
}

function SOSButton({ className }: Props) {
  const user = useUser();

  return (
    <RouterLink
      to={(user?.user.contacts.length > 0) ? '/alarm/sos' : '/alarm/contacts'}
      className={classNames(style.wrapper, className)}
    >
      SOS
    </RouterLink>
  )
}

export default SOSButton
