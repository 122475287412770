import React from 'react'

import Label from '../Label'

type Props = {
  country: string
} & React.ComponentPropsWithoutRef<'label'>

function StateLabel({ country, ...rest }: Props) {
  let children

  if (country === 'CA') {
    children = 'Province'
  } else if (country === 'US' || !country) {
    children = `State`
  } else {
    children = `Region`
  }

  return <Label {...rest}>{children}</Label>
}

export default StateLabel
