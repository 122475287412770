import pluralize from 'pluralize'

export default function formatMonths(months: number) {
  if (months < 12) {
    return `${months} ${pluralize(`months`, months)}`
  } else {
    const years = months / 12
    return `${years} ${pluralize(`years`, years)}`
  }
}
