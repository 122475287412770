import React from 'react'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import Notification from 'containers/Notification'
import thunk from 'redux-thunk'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from 'react-query'

import '@pet-notify/ui/src/reset.css'
import '@pet-notify/ui/src/main.css'
import '@pet-notify/ui/src/spacing.css'
import * as reducers from './store/reducers'
import api from 'services/pet-notify-api'
import App from './components/App'
import { STRIPE_PUBLISHABLE_KEY } from 'config'

const elementsProps = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
    },
  ],
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers(reducers),
  composeEnhancers(applyMiddleware(thunk.withExtraArgument(api))),
)

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

const queryClient = new QueryClient()

function UserApp() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Elements {...elementsProps} stripe={stripePromise}>
          <Notification />
          <App />
        </Elements>
      </QueryClientProvider>
    </Provider>
  )
}

export default UserApp
