import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Props = BaseProps<'label'>

function Label({ as = 'label', className, ...rest }: Props) {
  return (
    <Base as={as} className={classNames(style.wrapper, className)} {...rest} />
  )
}

export default Label
