import React from 'react'
import PropTypes from 'prop-types'

import Notice from '../Notice'
import style from './style.module.scss'

ErrorText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

function ErrorText(props) {
  const { children } = props
  let error = children

  if (error.message) {
    error = error.message
  }

  return <Notice className={style.wrapper}>{error}</Notice>
}

export default ErrorText
