import React from 'react'
import PropTypes from 'prop-types'

import countries from 'lib/countries'
import usStates from 'lib/us-states'
import canadianProvinces from 'lib/canadian-provinces'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import {
  Form,
  Dropdown,
  Label,
  StateLabel,
  Input,
  TextArea,
  Button,
  ErrorText,
} from 'components/Forms'
import Text from 'components/Text'
import PhoneInput from 'sharedComponents/Input/Phone'
import BackLink from 'UserApp/components/BackLink'

import style from './style.module.scss'

AddressForm.propTypes = {
  isEditing: PropTypes.bool,
  isProcessing: PropTypes.bool,
  onGoBack: PropTypes.func,
  onSubmit: PropTypes.func,
  address: PropTypes.object,
}

function AddressForm(props) {
  const { address, isEditing, isProcessing, onSubmit, onGoBack } = props

  return (
    <div className={style.wrapper}>
      {onGoBack && (
        <BackLink onClick={onGoBack} className={style.backLink}>
          Back
        </BackLink>
      )}
      <HeaderOne>Where your pets live</HeaderOne>
      <Form values={address} onSubmit={onSubmit}>
        {({ onChange, values, error }) =>
          isProcessing ? (
            <Loader />
          ) : (
            <React.Fragment>
              <Label htmlFor='address-address'>Address</Label>
              <Input
                required
                name='address'
                value={values.address}
                onChange={onChange}
                id='address-address'
              />
              <Label htmlFor='address-address2'>Apartment/Suite</Label>
              <Input
                name='address2'
                value={values.address2}
                onChange={onChange}
                id='address-address2'
              />
              <Label htmlFor='address-city'>City</Label>
              <Input
                required
                name='city'
                value={values.city}
                onChange={onChange}
                id='address-city'
              />

              <Label htmlFor='address-country'>Country</Label>
              <Dropdown
                name='country'
                options={countries}
                id='address-country'
                value={values.country}
                onChange={onChange}
              />

              <StateLabel htmlFor='address-state' country={values.country} />
              {values.country === 'US' || values.country === 'CA' ? (
                <Dropdown
                  includeBlank
                  name='state'
                  options={
                    values.country === 'US' ? usStates : canadianProvinces
                  }
                  id='address-state'
                  value={values.state}
                  onChange={onChange}
                />
              ) : (
                <Input
                  type='text'
                  name='state'
                  id='address-state'
                  value={values.state}
                  onChange={onChange}
                />
              )}

              <Label htmlFor='address-zip'>
                {values.country === 'US' ? `Zip Code` : `Postal Code`}
              </Label>
              <Input
                type='text'
                name='zip'
                id='address-zip'
                value={values.zip}
                onChange={onChange}
              />
              <Label htmlFor='address-phone'>Mobile Phone</Label>
              <PhoneInput
                required
                name='phone'
                value={values.phone}
                onChange={onChange}
                id='address-phone'
              />
              <Label htmlFor='address-accessInformation'>
                Emergency access
              </Label>
              <TextArea
                name='accessInformation'
                value={values.accessInformation}
                onChange={onChange}
                id='address-accessInformation'
              />

              <Text variant='note'>
                This information is only shared with your emergency contacts
                when your alarm goes off. Include key locations, security codes,
                etc., to access your home for the care of your pets.
              </Text>
              {error && <ErrorText>{error}</ErrorText>}

              <Button type='submit'>Save</Button>
            </React.Fragment>
          )
        }
      </Form>
    </div>
  )
}

export default AddressForm
