import React from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

export type Props = {
  onChange?: (formField: FormField) => void
  className?: string
  type?: string
  value?: string
  placeholder?: string
  id?: string
  name?: string
  required?: boolean
}

function Input(props: Props) {
  const { type = 'text', value = '', onChange, className, ...rest } = props

  function handleChange(event: React.FormEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget

    if (onChange) {
      onChange({ name, value })
    }
  }

  return (
    <input
      className={classNames(style.wrapper, className)}
      onChange={handleChange}
      value={value}
      type={type}
      {...rest}
    />
  )
}

export default Input
