import React from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

type Props = React.HTMLProps<HTMLTextAreaElement> & {
  onValueChange: (formField: FormField) => void
  className?: string
}

function TextArea(props: Props) {
  const { className, onValueChange, ...rest } = props

  function handleChange(event: React.FormEvent<HTMLTextAreaElement>) {
    const { name, value } = event.currentTarget

    onValueChange({ name, value })
  }

  return (
    <textarea
      className={classNames(style.wrapper, className)}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default React.memo(TextArea)
