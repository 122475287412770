import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'
import Base, { BaseProps } from '../Base'

type Props = BaseProps<'div'>

function Fields({ className, mb = '4', ...rest }: Props) {
  return (
    <Base className={classNames(style.wrapper, className)} mb={mb} {...rest} />
  )
}

export default Fields
