import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import queryString from 'query-string'

import HeaderOne from 'sharedComponents/HeaderOne'
import Layout from 'components/Layout/SplitLayout'
import SetPasswordForm from 'UserApp/components/SetPasswordForm'
import { setPassword } from 'UserApp/store/signup/actions'

import style from './style.module.scss'

function SetPasswordScreen(props: RouteComponentProps) {
  const { location, history } = props
  const { token } = queryString.parse(location.search)
  const dispatch = useDispatch()

  async function handleSetPassword(data: SignupPassword) {
    await dispatch(setPassword({ ...data, token }))
    history.push('/')
  }

  return (
    <Layout>
      <HeaderOne>Set password</HeaderOne>
      <SetPasswordForm onSubmit={handleSetPassword} />
    </Layout>
  )
}

export default SetPasswordScreen
