import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Props = {
  size?: 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small'
}

function Header<C extends React.ElementType = 'h1'>({
  as,
  size = 'xlarge',
  className,
  ...props
}: Props & BaseProps<C>) {
  return (
    <Base
      as={as || 'h1'}
      className={classNames(style.wrapper, style[size], className)}
      {...props}
    />
  )
}
export default Header
