import React, { useContext, ReactNode } from 'react'
import classNames from 'classnames'
import { NavLink as RouterLink } from 'react-router-dom'

import { ColorContext } from 'ColorContext'
import style from './style.module.scss'

type Props = {
  children?: ReactNode
  className?: string
  href?: string
  to?: string
  onClick?: (...args: any[]) => any
  target?: string
}

function Link(props: Props) {
  const { children, className, href, to, target, onClick } = props
  const { color } = useContext(ColorContext)

  if (to) {
    return (
      <RouterLink
        className={classNames(style.wrapper, style[color], className)}
        to={to}
      >
        {children}
      </RouterLink>
    )
  } else if (onClick) {
    return (
      <button
        type='button'
        className={classNames(style.wrapper, style[color], className)}
        onClick={onClick}
      >
        {children}
      </button>
    )
  } else {
    return (
      <a
        className={classNames(style.wrapper, style[color], className)}
        href={href}
        target={target}
      >
        {children}
      </a>
    )
  }
}

export default Link
