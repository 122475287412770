import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

TextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  mustMatch: PropTypes.string,
  className: PropTypes.string,
}

TextArea.defaultProps = {
  type: 'text',
  value: '',
}

function TextArea(props) {
  const { value, className, onChange, mustMatch, ...rest } = props

  function handleChange(event) {
    const { name, value, validity } = event.target
    const isValid = validity.valid && (!mustMatch || mustMatch === value)

    onChange({ name, value, isValid })
  }

  return (
    <textarea
      className={classNames(style.wrapper, className)}
      value={value || ''}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default React.memo(TextArea)
