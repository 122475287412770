import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

HeaderTwo.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

function HeaderTwo(props) {
  const { children, className } = props
  return <h2 className={classNames(style.wrapper, className)}>{children}</h2>
}

export default HeaderTwo
