import React,{useEffect,useState} from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dropdown,
  ErrorText,
  Form,
  Input,
  Label,
  TextArea,
} from 'components/Forms'
import HeaderOne from 'components/Headers/HeaderOne'
import style from './style.module.scss'
import Text from 'components/Text'
import { useParams } from 'react-router'
import useUser from 'UserApp/hooks/useUser'
import api from 'services/pet-notify-api'

const TYPE_OPTIONS = [``, `GPS Tracker`, `Auto-feeder`, `Camera`, `Other`]

TechDeviceForm.propTypes = {
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
}

function TechDeviceForm(props) {
  const { onSubmit, values, onRemove } = props
  const isEditing = !!values
  // console.log(`VALUES tech device :: `,values)
  const [pet, setPet] = useState()
  const { id } = useParams()
  // console.log(`PET ID : `,id)
  const user = useUser();

  async function handleSubmit(data) {
    const { type, brand, url, username, password } = data

    if (!type || type === '') {
      throw new Error(`Type required`)
    }

    if (!brand || brand === '') {
      throw new Error(`Brand required`)
    }
    if (!url || url === '') {
      throw new Error(`URL required`)
    }
    if (!username || username === '') {
      throw new Error(`Username required`)
    }
    if (!password || password === '') {
      throw new Error(`Password required`)
    }

    await onSubmit(data)
  }

    // Function to get pet data
    const getUserPetData = async (id) => {
      try {
        const petData = await api.pets.fetchOne(id); // Fetch pet data
        // console.log(`PET : `,petData)
        setPet(petData); // Set pet data in state
      } catch (error) {
        console.error('Error fetching pet data:', error);
      }
    };
  
    useEffect(() => {
      // Fetch pet data when the component mounts
      if (id) {
        getUserPetData(id);
      }
    }, [id]); // Dependency array includes `id` to re-run if `id` changes
  
    useEffect(() => {
      // console.log(`USER care provider:`, user);
      // console.log(`PET care provider:`, pet);
    }, [user, pet]); // Logs when user or petData changes

  function handleRemove(event) {
    event.preventDefault()
    onRemove()
  }

  const handleShare = (values) => {
    const techDeviceData = {
      petParent: user?.user.name,
      petName: pet?.name,
      type: (values?.type) ? values.type : '',
      brand: (values?.brand) ? values.brand : '',
      url: (values?.url) ? values.url : '',
      username: (values?.username) ? values.username : '',
      password: (values?.password) ? values.password : '',
      notes: (values?.notes) ? values.notes : '',
    };
  
    const data = `Pet parent: ${techDeviceData.petParent}\n Pet name: ${techDeviceData.petName}\n Device type: ${techDeviceData.type}\n Brand: ${techDeviceData.brand}\n URL: ${techDeviceData.url}\n Username: ${techDeviceData.username}\n Password: ${techDeviceData.password}\n Instructions: ${techDeviceData.notes}\n\n Note:'Credentials to access the pet's account in case of emergency.'`;
  
    const blob = new Blob([data], { type: 'text/plain' });
  
    // Create and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Tech-Device-info.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <HeaderOne>{isEditing ? `Edit` : `Add`} tech device</HeaderOne>
      <Form onSubmit={handleSubmit} values={values}>
        {({ values, onChange, error }) => (
          <React.Fragment>
            <Label htmlFor='tech-device-type'>Type</Label>
            <Dropdown
              name='type'
              value={values.type}
              onChange={onChange}
              options={TYPE_OPTIONS}
              id='tech-device-type'
            />

            <Label htmlFor='tech-device-brand'>Brand</Label>
            <Input
              name='brand'
              value={values.brand}
              onChange={onChange}
              id='tech-device-brand'
            />

            <Label htmlFor='tech-device-url'>URL</Label>
            <Input
              name='url'
              value={values.url}
              onChange={onChange}
              id='tech-device-url'
            />

            <Label htmlFor='tech-device'>Username</Label>
            <Input
              name='username'
              value={values.username}
              onChange={onChange}
              id='tech-device-username'
            />

            <Label htmlFor='tech-device'>Password</Label>
            <Input
              name='password'
              value={values.password}
              onChange={onChange}
              id='tech-device-password'
            />

            <Label htmlFor='tech-device-notes'>Notes</Label>
            <TextArea
              placeholder='Optional'
              name='notes'
              value={values.notes}
              onChange={onChange}
              id='tech-device-notes'
            />
            <Text>
              We will only share this with your trusted contacts in the event of
              an alarm.
            </Text>
            {error && <ErrorText>{error}</ErrorText>}
            <Button type='submit' small>
              {isEditing ? `Save` : `Add tech device`}
            </Button>
            {isEditing && (
              <Button
                onClick={()=>handleShare(values)}
                small
                className={style.removeButton}
              >
                Share
              </Button>
            )}
            {isEditing && (
              <Button
                onClick={handleRemove}
                variant='hollowRed'
                small
                className={style.removeButton}
              >
                Remove
              </Button>
            )}
          </React.Fragment>
        )}
      </Form>
    </div>
  )
}

export default TechDeviceForm
