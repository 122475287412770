import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import {
  Header,
  Loader,
  Input,
  Label,
  FieldGroup,
  Text,
  Button,
  Fields,
} from '@pet-notify/ui'

import api from 'lib/api'
import useNotification from 'UserApp/hooks/useNotification'
import useUser from 'UserApp/hooks/useUser'

type FormData = {
  email: string
  confirmEmail: string
}

type Props = {
  onClose: () => void
}

function ChangeEmailForm({ onClose }: Props) {
  const { setNotification } = useNotification()
  const { user, update } = useUser()
  const { register, ...form } = useForm<FormData>({
    defaultValues: {
      email: user.email,
    },
  })

  const { mutate, isError, isLoading, error } = useMutation(
    (data: FormData) => api.put('/api/users/me/email', data),
    {
      onSuccess: (response) => {
        update(response.data)
        setNotification('Email changed')
        onClose()
      },
    },
  )

  const handleSubmit = form.handleSubmit((values) => {
    mutate(values)
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit}>
      <Header mb='6'>Change email</Header>
      <Fields>
        <FieldGroup>
          <Label htmlFor='email'>Email</Label>
          <Input
            type='email'
            id='email'
            {...register('email', { required: true })}
          />
        </FieldGroup>

        <FieldGroup>
          <Label htmlFor='confirmEmail'>Confirm email</Label>
          <Input
            type='email'
            id='confirm-change-email'
            {...register('confirmEmail', { required: true })}
          />

          <Text variant='note'>
            We’ll send you a new verification email to this address.
          </Text>
        </FieldGroup>
      </Fields>

      {isError && <Text variant='error'>{error}</Text>}

      <Button type='submit'>Change email</Button>
    </form>
  )
}
export default ChangeEmailForm
