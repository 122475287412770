import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import pluralize from 'pluralize'

import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Text from 'components/Text'
import { Button, ErrorText } from 'components/Forms'

import style from './style.module.scss'
import possessive from 'lib/possessive'

PetSafetyScreen.propTypes = {
  answer: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onDecline: PropTypes.func,
  onSafe: PropTypes.func,
  token: PropTypes.string,
  user: PropTypes.object,
}

function PetSafetyScreen(props) {
  const { answer, error, isLoading, onDecline, onSafe, token, user } = props

  if (answer === 'safe') {
    return <Redirect to={`/s/${token}/success`} push />
  }

  if (answer === 'decline') {
    return <Redirect to={`/c/${token}/declined`} push />
  }

  if (isLoading) {
    return <Loader />
  }

  const { petCount, firstName } = user

  return (
    <Layout showNav={false}>
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <React.Fragment>
          <HeaderOne className={style.header}>
            {/* Are ${firstName} {pluralize(`pet`, petCount)} safe? */}
            Are {firstName}’s pets safe?
          </HeaderOne>
          {/* <Text className={style.text}>
            Are you sure you want to mark the pets as safe? Once confirmed, we
            will no longer reach out to additional contacts to see if they can
            care for the pets.
          </Text> */}
          <Text className={style.text}>
            This is Pet Notify reaching out to you on behalf of {firstName}. Could you please confirm 
            if {firstName}’s pets are safe? Once we receive your response, we'll update {firstName}’s
            other emergency contacts as needed.
          </Text>
          <Text className={style.text}>
            Thank you for helping to ensure the safety and well-being of {firstName}’s pets.
          </Text>
          <Text className={style.text}>
            Sincerely,
          </Text>
          <Text className={style.boldtext}>
            The Pet Notify Team
          </Text>
          <Button onClick={onSafe} className={style.button}>
            Yes, the pets are safe
          </Button>
          <Button onClick={onDecline} variant='hollowRed'>
            Sorry, I’m now unavailable
          </Button>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default PetSafetyScreen
