import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import style from './style.module.scss'

PetPhoto.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
}

function PetPhoto(props) {
  const { src, className } = props

  return (
    <div className={classNames(style.wrapper, className)}>
      <img src={src} />
    </div>
  )
}

export default PetPhoto
