import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'

import { applyPromoCode } from 'UserApp/store/promoCode/actions'
import { saveUserDetails } from 'UserApp/store/signup/actions'

function useCaptureSignupParams(locationSearch) {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    async function captureSignupParams() {
      const {
        promo,
        email,
        firstName,
        lastName,
        sendNewsAndUpdates,
        redemptionCode,
      } = queryString.parse(locationSearch)

      if (promo) {
        try {
          await dispatch(applyPromoCode(promo))
        } catch (e) {
          console.log('invalid promo code')
        }
      }

      if (redemptionCode) {
        localStorage.setItem('redemptionCode', redemptionCode)
      }

      if (email || firstName || lastName || sendNewsAndUpdates) {
        dispatch(
          saveUserDetails(
            { email, firstName, lastName, sendNewsAndUpdates, promo },
            true,
          ),
        )
      }

      setIsLoading(false)
    }

    captureSignupParams()
  }, [locationSearch])

  return isLoading
}

export default useCaptureSignupParams
