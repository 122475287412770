import mixpanel from 'mixpanel-browser'

import { MIXPANEL_TOKEN } from 'config'

export const EVENTS = {
  LOGIN: 'Login',
  CONTACT_ADDED: 'Contact added',
  PET_ADDED: 'Pet added',
  TASK_ADDED: 'Task added',
  CANCEL_SUBSCRIPTION: 'Cancel subscription',
  DOWNGRADE_SUBSCRIPTION: 'Downgrade subscription',
  DELETE_ACCOUNT: 'Delete account',
  ALARM_SET: 'Alarm set',
  INTELLITAG_PURCHASE: 'Intellitag purchase',
  GOOGLE_PLAY_STORE: 'Click on Android app store button',
  APPLE_APP_STORE: 'Click on iOS app store button',
  PREMIUM_PRICING_PAGE_SIGN_UP_CLICK: 'Pricing page - Premium',
  PREMIUM_CREATE_ACCOUNT_CLICK: 'Create account - Premium',
  BUY_INTELLITAG_CLICK: 'Buy Intellitag',
  SHIPPING_ADDRESS_CLICK: 'Shipping address',
  CONFIRM_PAYMENT_CLICK: 'Confirm payment',
  FREE_PRICING_PAGE_SIGN_UP_CLICK: 'Pricing page - free',
  FREE_CREATE_ACCOUNT_CLICK: 'Create account - free',
  SET_PASSWORD_CLICK: 'Set password',
}

if (MIXPANEL_TOKEN) {
  mixpanel.init(MIXPANEL_TOKEN)
}

export default {
  identify: (id) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.identify(id)
    }
  },
  alias: (id) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.alias(id)
    }
  },
  track: (name, props) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.track(name, props)
    }
  },
  register: (props) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.register(props)
    }
  },
  people: {
    set: (props) => {
      if (MIXPANEL_TOKEN) {
        mixpanel.people.set(props)
      }
    },
  },
  reset: (props) => {
    if (MIXPANEL_TOKEN) {
      mixpanel.reset(props)
    }
  },
}
