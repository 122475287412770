import React from 'react'
import classNames from 'classnames'

import Base, { BaseProps } from '../Base'
import style from './style.module.css'

type Props = {
  variant?: 'default' | 'cancel'
}

function Link<C extends React.ElementType = 'a'>({
  as,
  className,
  variant = 'default',
  ...rest
}: Props & BaseProps<C>) {
  return (
    <Base
      as={as || 'a'}
      className={classNames(style.wrapper, style[variant], className)}
      {...rest}
    />
  )
}

export default Link
