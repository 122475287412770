import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import logo from './images/logo.svg'
import style from './style.module.scss'

Logo.propTypes = {
  className: PropTypes.string,
}

function Logo(props) {
  const { className } = props
  return (
    <img
      src={logo}
      className={classNames(style.wrapper, className)}
      alt='Pet Notify'
      height='37'
    />
  )
}

export default Logo
