import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

interface Props<T> {
  initialValues?: Partial<T>
  onSubmit: (values: T, clearForm?: (values: T) => void) => Promise<void>
  children: (props: {
    values: T
    onChange: (formField: FormField) => void
    error?: string
  }) => JSX.Element
  className?: string
}

function Form<T>(props: Props<T>) {
  const { onSubmit, children, initialValues = {}, className } = props
  const [values, setValues] = useState(initialValues)
  const [error, setError] = useState(null)

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault()
    event.stopPropagation()
    setError(null)
    try {
      await onSubmit(values, setValues)
    } catch (e) {
      setError(e.message)
    }
  }

  // useEffect(() => {
  //   setValues(initialValues)
  // }, [initialValues])

  function handleChange({ name, value }: FormField) {
    setValues((values) => {
      const updatedValues = {
        ...values,
      }

      if (typeof value !== 'undefined') {
        updatedValues[name] = value
      }

      return updatedValues
    })
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(style.wrapper, className)}
    >
      {children({
        values,
        onChange: handleChange,
        error,
      })}
    </form>
  )
}

export default Form
