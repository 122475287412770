import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import style from './style.module.scss'

const ListItem = React.forwardRef((props, ref) => {
  const { children, className, ...rest } = props

  return (
    <li className={classNames(style.item, className)} ref={ref} {...rest}>
      {children}
    </li>
  )
})

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const List = React.forwardRef((props, ref) => {
  const { children, className } = props

  return (
    <ul className={classNames(style.wrapper, className)} ref={ref}>
      {children}
    </ul>
  )
})

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export { List, ListItem }
