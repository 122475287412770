import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { set as setNotification } from 'store/notification/actions'
import { TaskType } from 'types'

import { fetchAll, update, clearTaskHistory } from 'UserApp/store/tasks/actions'
import { selectAllDone, selectAllActive } from 'UserApp/store/tasks/selectors'
import TasksScreen from 'UserApp/screens/Tasks'

class TasksScreenContainer extends React.PureComponent {
  static propTypes = {
    activeTasks: PropTypes.arrayOf(PropTypes.shape(TaskType)),
    doneTasks: PropTypes.arrayOf(PropTypes.shape(TaskType)),
    fetchAll: PropTypes.func,
    update: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    match: PropTypes.shape({
      path: PropTypes.string,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    setNotification: PropTypes.func,
  }

  state = { isLoading: true }

  async componentDidMount() {
    const { fetchAll } = this.props

    await fetchAll()

    this.setState({
      isLoading: false,
    })
  }

  handleClose = () => this.props.history.push('/tasks')

  handleCheck = async (id, isDone) => {
    const { update, setNotification } = this.props

    this.setState({
      isLoading: true,
    })

    await update(id, { isDone })

    setNotification(isDone ? `Task completed` : `Task changed to incomplete`)

    this.setState({
      isLoading: false,
    })
  }

  handleClearTaskHistory = async () => {
    const { clearTaskHistory, setNotification } = this.props

    this.setState({
      isLoading: true,
    })

    await clearTaskHistory()

    setNotification('Task history cleared')

    this.setState({
      isLoading: false,
    })
  }

  generateProps = () => {
    const { match, doneTasks, activeTasks } = this.props
    const { path, params } = match

    return {
      ...this.props,
      ...this.state,
      taskId: params.id,
      isAddingTask: path === '/tasks/new',
      isEditingTask: path === '/tasks/:id',
      onClose: this.handleClose,
      onCheck: this.handleCheck,
      hasTasks: doneTasks.length > 0 || activeTasks.length > 0,
      onClearTaskHistory: this.handleClearTaskHistory,
    }
  }

  render() {
    const props = this.generateProps()

    return <TasksScreen {...props} />
  }
}

export default connect(
  (state) => ({
    doneTasks: selectAllDone(state),
    activeTasks: selectAllActive(state),
  }),
  {
    clearTaskHistory,
    fetchAll,
    update,
    setNotification,
  },
)(TasksScreenContainer)
