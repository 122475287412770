import React from 'react'
import PropTypes from 'prop-types'

import Text from 'components/Text'
import { UpdatedButton as Button } from 'components/Forms'
import style from './style.module.scss'

FoundPetAlert.propTypes = {}

function FoundPetAlert(props) {
  const { foundPet } = props

  if (!foundPet) {
    return null
  }

  return (
    <div className={style.wrapper}>
      <Text className={style.text}>{foundPet.pet.name} has been found!</Text>
      <Button href={`/found/claim/${foundPet.token}`} className={style.button}>
        Get contact information
      </Button>
    </div>
  )
}

export default FoundPetAlert
