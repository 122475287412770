import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/Layout/SplitLayout'
import Loader from 'sharedComponents/Loader'
import HeaderOne from 'sharedComponents/HeaderOne'
import Text from 'sharedComponents/Text'
import Button from 'sharedComponents/Button'

import style from './style.module.scss'

InvitationAcceptedScreen.propTypes = {
  isLoading: PropTypes.bool,
  user: PropTypes.object,
  contact: PropTypes.object,
}

function InvitationAcceptedScreen(props) {
  const { isLoading, user, contact } = props

  if (isLoading) {
    return <Loader />
  }

  const { isEmergencyContact } = contact
  const { name, firstName } = user

  return (
    <Layout showNav={false}>
      <HeaderOne className={style.header}>
        {isEmergencyContact ? (
          <>You’ve been added as an emergency contact for {name}</>
        ) : (
          <>You’ve been added as a contact for {name}</>
        )}
      </HeaderOne>

      <div className={style.textBlock}>
        {isEmergencyContact ? (
          <>
            <Text className={style.text}>
              You’ll now receive notifications when {firstName} or the pet(s)
              need help.
            </Text>
            <Text className={style.text}>
              If you’re available to check on the pet(s), we’ll provide you with
              a link to {firstName}’s Pet Vault which will contain information
              to access {firstName}’s home and care for the pets.
            </Text>
          </>
        ) : (
          <>
            <Text className={style.text}>
              As a Pet Notify contact, you’re now able to have tasks assigned to
              you. We’ll notify you of any tasks via SMS to your mobile phone.
            </Text>
          </>
        )}
      </div>
      <Button href='https://www.petnotify.com/' target='_blank'>
        Learn more about Pet Notify
      </Button>
    </Layout>
  )
}

export default InvitationAcceptedScreen
