import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ContactType } from 'types'

import { selectCount as selectPetCount } from 'UserApp/store/pets/selectors'
import { fetchAll, setOrder } from 'UserApp/store/contacts/actions'
import {
  selectAll,
  selectAllEmergency,
  selectAllOther,
} from 'UserApp/store/contacts/selectors'
import ContactsScreen from 'UserApp/screens/Contacts'

class ContactsScreenContainer extends React.PureComponent {
  static propTypes = {
    hasContacts: PropTypes.bool,
    emergencyContacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
    otherContacts: PropTypes.arrayOf(PropTypes.shape(ContactType)),
    setOrder: PropTypes.func,
    fetchAll: PropTypes.func,
    match: PropTypes.shape({
      path: PropTypes.string,
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  }

  state = { isLoading: true, isProcessingSort: false }

  async componentDidMount() {
    const { fetchAll } = this.props

    await fetchAll()

    this.setState({
      isLoading: false,
    })
  }

  handleClose = () => this.props.history.push('/contacts')

  handleSort = async (reordered) => {
    const { setOrder } = this.props

    this.setState({
      isProcessingSort: true,
    })

    await setOrder(reordered)

    this.setState({
      isProcessingSort: false,
    })
  }

  generateProps = () => {
    const { match } = this.props
    const { path, params } = match

    return {
      ...this.props,
      ...this.state,
      contactId: params.id,
      isAddingContact: path === '/contacts/new',
      isEditingContact: path === '/contacts/:id',
      onClose: this.handleClose,
      onSort: this.handleSort,
    }
  }

  render() {
    const props = this.generateProps()

    return <ContactsScreen {...props} />
  }
}

export default connect(
  (state) => ({
    hasContacts: selectAll(state).length > 0,
    emergencyContacts: selectAllEmergency(state),
    otherContacts: selectAllOther(state),
    petCount: selectPetCount(state),
  }),
  {
    fetchAll,
    setOrder,
  },
)(ContactsScreenContainer)
