import {
  FETCHED,
  LOGOUT,
  UPDATE_PAYMENT,
  RENEW_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION,
  DOWNGRADE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  UPGRADE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
  REACTIVATE_ACCOUNT,
} from './actions'

import { ADD as ADD_PET, REMOVE as REMOVE_PET } from '../pets/actions'
import { SET_PASSWORD } from '../signup/actions'

export default function reducer(state = null, action) {
  const { payload = {}, type } = action
  const { result, entities } = payload

  switch (type) {
    case REMOVE_PET:
      return {
        ...state,
        petCount: state.petCount - 1,
      }
    case ADD_PET:
      return {
        ...state,
        petCount: state.petCount + 1,
      }
    case REACTIVATE_ACCOUNT:
    case CANCEL_SUBSCRIPTION:
    case RENEW_SUBSCRIPTION:
    case UPGRADE_SUBSCRIPTION:
    case CHANGE_SUBSCRIPTION:
    case DOWNGRADE_SUBSCRIPTION:
    case REACTIVATE_SUBSCRIPTION:
    case FETCHED:
    case SET_PASSWORD:
      return {
        ...entities.user[result],
      }
    case UPDATE_PAYMENT:
      return {
        ...state,
        card: {
          ...payload,
        },
      }
    case LOGOUT:
      return null
    default:
      return state
  }
}
