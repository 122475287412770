import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/Link'

import Row from '../Row'

import style from './style.module.scss'

VaultItemDocuments.propTypes = {
  documents: PropTypes.array,
}

function VaultItemDocuments(props) {
  const { documents } = props

  return (
    <div className={style.wrapper}>
      {documents.map(({ id, name, ext, url }) => (
        <Row key={url}>
          <Link href={url} target='_blank'>
            {name}.{ext}
          </Link>
        </Row>
      ))}
    </div>
  )
}

export default VaultItemDocuments
