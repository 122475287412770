import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { remove } from 'store/notification/actions'
import { selectOne } from 'store/notification/selectors'
import Notification from 'components/Notification'

class NotificationContainer extends React.Component {
  static propTypes = {
    remove: PropTypes.func,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.notification && props.notification !== state.notification) {
      const notification = {
        ...props.notification,
      }

      props.remove()

      return {
        isShowingNotification: true,
        notification,
      }
    }

    return null
  }

  state = { isShowingNotification: false, notification: null }

  componentDidMount() {
    if (this.state.notification) {
      setTimeout(this.hideNotification, 3000)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.notification &&
      this.state.notification !== prevState.notification
    ) {
      setTimeout(this.hideNotification, 3000)
    }
  }

  hideNotification = () => this.setState({ isShowingNotification: false })

  handleClearNotification = () => this.setState({ notification: null })

  generateProps = () => {
    return {
      ...this.props,
      ...this.state,
      onClearNotification: this.handleClearNotification,
    }
  }

  render() {
    const props = this.generateProps()
    return <Notification {...props} />
  }
}

export default connect(
  (state) => ({
    notification: selectOne(state),
  }),
  {
    remove,
  },
)(NotificationContainer)
