import React, { ReactNode } from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

function HeaderTwo(props: Props) {
  const { children, className } = props
  return <h2 className={classNames(style.wrapper, className)}>{children}</h2>
}

export default HeaderTwo
