import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import HeaderOne from 'components/Headers/HeaderOne'
import HeaderTwo from 'components/Headers/HeaderTwo'
import Link from 'components/Link'
import { Dropdown } from '@pet-notify/ui'
import INTELLITAG_SIZE_OPTIONS from 'lib/intellitag-size-options'
import {
  ErrorText,
  Label,
  Input,
  Button,
  Selection,
  SelectionOption,
} from 'components/Forms'
import Text from 'components/Text'
import PhoneInput from 'sharedComponents/Input/Phone'
import mixPanel, { EVENTS } from 'services/mix-panel'
import style from './style.module.scss'
import useUser from 'UserApp/hooks/useUser'
import DowngradePopup from '../DowngradeMessage'
import PastDuePopup from 'UserApp/components/OrderIntellitagModal/PastDueMessage'

function Intellitag(props) {

  const [userData, setUserData] = useState({})

  const {
    pets,
    onSubmit,
    onChange,
    wasSubmitted,
    isLoading,
    intellitags,
    onAdd,
    onRemove,
    isMobile,
  } = props

  const {user} = useUser()

  useEffect(() => {
    setUserData(user)
  }, [])

  if (wasSubmitted) {
    return (
      <Redirect
        to={
          isMobile
            ? '/order-intellitag/shipping?mobile=true'
            : '/order-intellitag/shipping'
        }
        push
      />
    )
  }

  function handleSubmit(event) {
    event.preventDefault()
    onSubmit()
  }

  const nextAvailablePet = pets.findIndex(
    (_pet, index) => !intellitags.find((i) => i.pet === index),
  )

  const hasNameThatIsTooLong = intellitags.some((i) => i.nameTooLong)

  return (
    userData.status === 'premium' ?
    (<div className={style.wrapper}>
      <HeaderOne className={style.header}>Add an Intellitag</HeaderOne>

      <form onSubmit={handleSubmit} className={style.form}>
        {intellitags.map((intellitag, index) => (
          <React.Fragment key={`intellitag${index}`}>
            {index > 0 && (
              <div className={style.subHeader}>
                <HeaderTwo>Additional Intellitag</HeaderTwo>
                <Link
                  className={style.removeLink}
                  onClick={() => onRemove(index)}
                >
                  Remove
                </Link>
              </div>
            )}
            <div className={style.petOptions}>
              <Label htmlFor='intellitag-pet'>Which pet is this for?</Label>
              <Dropdown
                name='pet'
                options={pets.map((pet, petIndex) => {
                  const disabled = !!intellitags.find(
                    (intellitag2, i) =>
                      intellitag2.pet === petIndex && i !== index,
                  )

                  return {
                    value: petIndex,
                    label: pet.name,
                    disabled,
                  }
                })}
                id='intellitag-pet'
                value={intellitag.pet}
                // onChange={(props) => onChange({ ...props, index })}
                onChange={(event,props) => onChange({ name:'pet', value:event.target.value, ...props, index })}
                placeholder='Choose your pet'
              />
            </div>
            <div className={style.sizeOptions}>
              <Label htmlFor='intellitag-size'>Intellitag size</Label>
              <Selection>
                {INTELLITAG_SIZE_OPTIONS.map((option) => (
                  <SelectionOption
                    key={option.value}
                    name='size'
                    value={option.value}
                    title={option.label}
                    subTitle={option.subTitle}
                    isSelected={option.value === intellitag.size}
                    onChange={(props) => onChange({ ...props, index })}
                  />
                ))}
              </Selection>
            </div>

            <Label htmlFor='weight'>Pet weight (in pounds)</Label>
            <Input
              name='weight'
              value={intellitag.weight}
              onChange={(props) => onChange({ ...props, index })}
              id='weight'
            />

            <Label
              htmlFor='name'
              subTitle={`What name do you want engraved on your Intellitag? ${intellitag.maxCharacters} character limit.`}
            >
              Intellitag engraving
            </Label>
            <Input
              type='name'
              name='name'
              value={intellitag.name}
              onChange={(props) =>
                onChange({ ...props, value: props.value.toUpperCase(), index })
              }
              id='name'
              invalid={intellitag.nameTooLong}
            />

            {intellitag.nameTooLong && (
              <ErrorText>
                Name must not be over {intellitag.maxCharacters} characters long
              </ErrorText>
            )}

            <Label
              htmlFor='phone'
              subTitle='This number is printed on your Intelitag and is optional for Intellitag will call, email, and text you when someone finds your lost pet.'
            >
              Mobile phone number
            </Label>
            <PhoneInput
              name='phone'
              value={intellitag.phone}
              onChange={(props) => onChange({ ...props, index })}
              placeholder='Optional'
              id='phone'
            />

            {intellitag.error && <ErrorText>{intellitag.error}</ErrorText>}
          </React.Fragment>
        ))}

        <Text className={style.disclaimer}>
          Your pet's Intellitag ID is directly tied to your Pet Notify user
          account and will not provide lost pet communications if gifted,
          distributed, or transferred to any 3rd party.
        </Text>

        <div className={style.buttons}>
          <Button
            type='submit'
            className={style.submitButton}
            disabled={hasNameThatIsTooLong}
            onClick={() => mixPanel.track(EVENTS.BUY_INTELLITAG_CLICK)}
          >
            Continue
          </Button>
          {nextAvailablePet > -1 && (
            <Button
              variant='hollow'
              className={style.addAnotherButton}
              onClick={() => onAdd(nextAvailablePet)}
            >
              Add another Intellitag
            </Button>
          )}
        </div>
      </form>
      </div>
      )
      :
      ((userData.status === 'downgraded') ? <DowngradePopup/> : <PastDuePopup />)
  )
}

export default Intellitag
