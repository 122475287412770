import React from 'react'
import PropTypes from 'prop-types'

import HeaderThree from 'components/Headers/HeaderThree'
import TaskItem from './Item'
import { TaskType } from 'types'
import style from './style.module.scss'

TasksList.propTypes = {
  header: PropTypes.string,
  tasks: PropTypes.arrayOf(PropTypes.shape(TaskType)),
  onCheck: PropTypes.func,
}

function TasksList(props) {
  const { tasks, onCheck, header } = props

  return (
    <React.Fragment>
      <HeaderThree className={style.header}>{header}</HeaderThree>
      <ul className={style.wrapper}>
        {tasks.map((task, index) => (
          <TaskItem key={task.id} onCheck={onCheck} {...task} />
        ))}
      </ul>
    </React.Fragment>
  )
}

export default TasksList
