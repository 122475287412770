import React, { useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga'
import smartlookClient from 'smartlook-client'

import UserApp from 'UserApp'
import ContactApp from 'ContactApp'
import { SENTRY_DSN, GOOGLE_ANALYTICS, SMARTLOOK_KEY, VERSION } from 'config'
import { ColorContext } from 'ColorContext'

import 'stylesheets/reset.scss'
import 'stylesheets/base.scss'
import 'react-datepicker/dist/react-datepicker.css'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: VERSION,
  })
}

if (GOOGLE_ANALYTICS) {
  ReactGA.initialize(GOOGLE_ANALYTICS)
}

if (SMARTLOOK_KEY) {
  smartlookClient.init(SMARTLOOK_KEY)
}

function App() {
  const [color, setColor] = useState(null)

  return (
    <ColorContext.Provider value={{ color, setColor }}>
      <Router>
        <Switch>
          <Route path='/t' component={ContactApp} />
          <Route path='/i' component={ContactApp} />
          <Route path='/c' component={ContactApp} />
          <Route path='/s' component={ContactApp} />
          <Route path='/' component={UserApp} />
        </Switch>
      </Router>
    </ColorContext.Provider>
  )
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )
}
