import { schema } from 'normalizr'

import pets from '../pets/schema'

const processStrategy = (entity) => {
  const { firstName, lastName } = entity

  let subscription = entity.subscription

  if (!subscription) {
    subscription = {}
  }

  return {
    ...entity,
    name: `${firstName} ${lastName}`,
    subscription: {
      ...subscription,
      currentPeriodStart: new Date(subscription.currentPeriodStart),
      lastDayOfBillingPeriod: new Date(subscription.lastDayOfBillingPeriod),
    },
  }
}

export default new schema.Entity('user', { pets: [pets] }, { processStrategy })
