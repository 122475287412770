export default function formatPhone(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)

  if (match && match[2]) {
    if (match[3]) {
      return [match[1], '-', match[2], '-', match[3]].join('')
    } else {
      return [match[1], '-', match[2]].join('')
    }
  } else {
    return cleaned
  }
}
