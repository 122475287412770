import React from 'react'

import HeaderOne from 'sharedComponents/HeaderOne'
import Button from 'sharedComponents/Button'
import Text from 'sharedComponents/Text'
import style from './style.module.scss'

type Props = {
  // isMobile: boolean
  onClose: ()=> void
}

function Success(props: Props) {
  const { isMobile, setPaymentRedirect } = props

  return (
    <>
      <HeaderOne className={style.header}>
        Intellitag Activation successful
      </HeaderOne>

      <Text className={style.text}>
        Thank you for activating intellitag. You can now continue to
        protect your household pets with intellitag.
      </Text>

      <div className={style.buttons}>
        <Button className={style.button} to='/pets' variant='hollow'>
          Back to Pet Vault
        </Button>
      </div>
    </>
  )
}

export default Success
