import React, { useState } from 'react'
import classNames from 'classnames'
import { NavLink as Link, useLocation } from 'react-router-dom'

import Button from 'sharedComponents/Button'
import style from './style.module.scss'

type Props = {
  className?: string
}

function Navigation(props: Props) {
  const { className } = props
  const location = useLocation()
  const isInSignupFlow = location.pathname.startsWith('/signup')
  const [isExpanded, setIsExpaneded] = useState(false)

  function handleToggleMenu() {
    setIsExpaneded((isExpanded) => !isExpanded)
  }

  function handleClickLink() {
    setIsExpaneded(false)
  }

  return (
    <nav
      className={classNames(style.wrapper, className, {
        [style.isExpanded]: isExpanded,
      })}
    >
      <button className={style.hamburger} onClick={handleToggleMenu} />
      <div className={style.links}>
        <a href='https://www.petnotify.com/' className={style.link}>
          Home
        </a>
        <a href='https://www.petnotify.com/how-it-works' className={style.link}>
          Product Suite
        </a>
        <a href='https://www.petnotify.com/pricing' className={style.link}>
          Pricing
        </a>
        <a href='https://www.petnotify.com/faq' className={style.link}>
          FAQ
        </a>
        <>
          {!isInSignupFlow && (
            <Button
              onClick={handleClickLink}
              to='/signup'
              className={style.button}
            >
              Sign up
            </Button>
          )}
          <Link
            onClick={handleClickLink}
            className={classNames(style.link, style.signIn)}
            to='/signin'
          >
            Sign in
          </Link>
        </>
      </div>
    </nav>
  )
}

export default Navigation
