import React, { useState } from 'react';
import Modal from 'sharedComponents/Modal'
import style from './style.module.scss'
import classNames from 'classnames';
import { UpdatedButton as Button } from 'components/Forms'
import { useHistory } from 'react-router';

function PastDuePopup(props) {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory()

  const handleClose = () => {
    setIsOpen(false);
    history.push('/')
  };

  if (!isOpen) return null;

  return (
      <div>
        <div className={classNames(style.modal_content)}>
          <p>Your Pet Notify premium account is past due. Please update your billing information to complete your Intellitag order</p>
          <Button 
            to='/settings/payment'
            className={style.button}
          >
            Update billing info
          </Button>
        </div>
      </div>
  );
}

export default PastDuePopup;