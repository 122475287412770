import React, { useState } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import HeaderOne from 'components/Headers/HeaderOne'
import Text from 'components/Text'
import { Button } from 'components/Forms'
import style from './style.module.scss'
import Checkbox from 'sharedComponents/Checkbox'
import Error from 'sharedComponents/Error'
import useNotification from 'UserApp/hooks/useNotification'
import api from 'services/pet-notify-api'

function SmsAlertsFunction(props) {
  // const { onConfirm  } = props
  const [smsAlerts, setSmsAlerts] = useState(false);
  const [error, setError] = useState('');
  const { setNotification } = useNotification()
  const history = useHistory()

  const handleChange = ({value}) => {
    setSmsAlerts(value);
    if (error) {
      setError(''); // Clear the error when checkbox value changes
    }
  };

  const handleSubmit = async () => {
    if (!smsAlerts) {
      setError('Turn On SMS alerts');
      return;
    }
    try{
      await api.users.allowSmsConsent({smsAlerts})
      history.push('/settings')
      setNotification('SMS alerts turned On')
    }catch(error){
      // console.error('Error submitting SMS consent:', error);
      setError('Failed to turn On SMS alerts. Please try again.');
    }
  };

  return (
    <div className={classNames(style.wrapper, style.expired)}>
      <HeaderOne className={style.header}>SMS Alerts</HeaderOne>

      <Text className={style.text}>
      You opted to STOP your SMS alerts. Turn them On to start receiving SMS alerts to keep your pet(s) safe and notify you if your lost pet is found.
      </Text>

      <Checkbox
        name='smsAlerts'
        value={smsAlerts}
        onChange={handleChange}
        label='SMS Alerts'
        id='singup-sms-permission'
      />
                  
      {error && <Error>{error}</Error>}

      <Button onClick={handleSubmit}  variant='secondary' className={style.button}>
        Submit
      </Button>
    </div>
  )
}

export default SmsAlertsFunction
