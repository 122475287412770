import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PetType } from 'types'

import { fetchAll } from 'UserApp/store/pets/actions'
import { selectAll } from 'UserApp/store/pets/selectors'
import PetsList from 'UserApp/components/Pets/List'

class PetsListContainer extends React.PureComponent {
  static propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape(PetType)),
    fetchAll: PropTypes.func,
  }

  state = { isLoading: true }

  async componentDidMount() {
    const { fetchAll } = this.props

    await fetchAll()

    this.setState({
      isLoading: false,
    })
  }

  generateProps = () => ({
    ...this.props,
    ...this.state,
  })

  render() {
    const props = this.generateProps()

    return <PetsList {...props} />
  }
}

export default connect(
  (state) => ({
    rows: selectAll(state),
  }),
  {
    fetchAll,
  },
)(PetsListContainer)
