import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link } from 'react-router-dom'

import Logo from 'components/Logo'

import style from './style.module.scss'

Header.propTypes = {
  showNavigation: PropTypes.bool,
  onToggleMenu: PropTypes.func,
}

function Header(props) {
  const { onToggleMenu, showNavigation } = props

  return (
    <header className={style.wrapper}>
      <Link to='/'>
        <Logo className={style.logo} />
      </Link>
      {showNavigation && (
        <button className={style.hamburger} onClick={onToggleMenu} />
      )}
    </header>
  )
}

export default Header
