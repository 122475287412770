import React from 'react'

import style from './style.module.css'

type Props = {
  as: React.ElementType
}

function StripeInput({ as: Component }: Props) {
  return (
    <Component
      options={{
        placeholder: '',
        style: {
          base: {
            color: '#4f4f4f',
            fontFamily: `'Nunito Sans', sans-serif`,
            fontSize: '14px',
          },
        },
        classes: {
          base: style.wrapper,
          invalid: style.invalid,
          focus: style.focus,
        },
      }}
    />
  )
}

export default StripeInput
