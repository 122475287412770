import React from 'react'
import { Redirect } from 'react-router-dom'

import Loader from 'components/Loader'
import Text from 'components/Text'
import {
  NewForm as Form,
  Label,
  Input,
  Button,
  ErrorText,
} from 'components/Forms'
import AddressFormFields from '../AddressFormFields'
import PhoneInput from 'sharedComponents/Input/Phone'
import HeaderOne from 'components/Headers/HeaderOne'
import mixPanel, { EVENTS } from 'services/mix-panel'

import style from './style.module.scss'

function Shipping(props) {
  const {
    onSubmit,
    isMobile,
    wasSubmitted,
    isLoading,
    defaultValues = {},
  } = props

  if (wasSubmitted) {
    return (
      <Redirect
        to={
          isMobile
            ? '/order-intellitag/payment?mobile=true'
            : '/order-intellitag/payment'
        }
        push
      />
    )
  }

  return (
    <div className={style.wrapper}>
      <HeaderOne className={style.header}>Shipping address</HeaderOne>
      <Text>
        Your Intellitag will arrive within 5-7 business days. Please allow 10-14
        business days for international orders.
      </Text>

      <Form
        onSubmit={onSubmit}
        defaultValues={{ country: 'US', ...defaultValues }}
      >
        {({ onChange, values, error }) =>
          isLoading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <AddressFormFields values={values} onChange={onChange} />

              <Label htmlFor='address-phone'>Phone</Label>
              <PhoneInput
                name='phone'
                id='address-phone'
                value={values.phone}
                onChange={onChange}
              />

              {error && <ErrorText>{error}</ErrorText>}

              <Button
                type='submit'
                className={style.button}
                onClick={() => mixPanel.track(EVENTS.SHIPPING_ADDRESS_CLICK)}
              >
                Continue
              </Button>
            </React.Fragment>
          )
        }
      </Form>
    </div>
  )
}

export default Shipping
