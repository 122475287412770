import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'

type Props = React.ComponentPropsWithRef<'input'> & {
  label: string
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ className, label, ...rest }: Props, ref) => {
    return (
      <div className={classNames(style.wrapper, className)}>
        <input className={style.checkbox} {...rest} type='checkbox' ref={ref} />
        {label && (
          <label htmlFor={rest.id} className={style.label}>
            {label}
          </label>
        )}
      </div>
    )
  },
)

export default Checkbox
