import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

function formatPercentage(percent) {
  return Math.ceil(percent * 100)
}

Loader.propTypes = {
  percent: PropTypes.number,
}

function Loader(props) {
  const { percent } = props

  return (
    <div className={style.wrapper}>
      {percent && (
        <div className={style.percent}>{formatPercentage(percent)}%</div>
      )}
    </div>
  )
}

export default Loader
