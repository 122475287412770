import React, { useState, ComponentPropsWithRef, forwardRef } from 'react'

import Input from '../Input'
import style from './style.module.css'

type Props = ComponentPropsWithRef<'input'>

const PasswordInput = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref) => {
    const [isPressed, setIsPressed] = useState(false)

    return (
      <div className={style.wrapper}>
        <Input
          type={isPressed ? 'text' : 'password'}
          className={style.input}
          {...props}
          ref={ref}
        />
        <button
          type='button'
          role='switch'
          aria-pressed={isPressed ? 'true' : 'false'}
          className={style.button}
          onClick={() => setIsPressed((s) => !s)}
        >
          {isPressed ? (
            <svg
              width='24'
              height='18'
              viewBox='0 0 24 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <title>Hide password</title>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12 18C4.205 18 0 11.612 0 9C0 6.388 4.205 0 12 0C19.795 0 24 6.388 24 9C24 11.612 19.795 18 12 18ZM12 2C5.216 2 2 7.615 2 9C2 10.385 5.216 16 12 16C18.784 16 22 10.385 22 9C22 7.615 18.784 2 12 2ZM12 14C9.243 14 7 11.757 7 9C7 6.243 9.243 4 12 4C14.757 4 17 6.243 17 9C17 11.757 14.757 14 12 14ZM12 6C10.346 6 9 7.346 9 9C9 10.654 10.346 12 12 12C13.654 12 15 10.654 15 9C15 7.346 13.654 6 12 6Z'
                fill='currentColor'
              />
            </svg>
          ) : (
            <svg
              width='24'
              height='22'
              viewBox='0 0 24 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              role='img'
            >
              <title>Show password</title>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.718 21.6031C2.523 21.7981 2.267 21.8951 2.011 21.8951C1.755 21.8951 1.499 21.7981 1.304 21.6031C0.913 21.2151 0.913 20.5841 1.304 20.1951L4.385 17.1261C1.116 15.0351 0 12.1421 0 10.9391C0 8.33813 4.205 1.97613 12 1.97613C15.002 1.97613 16.895 2.88313 17.942 3.62413L21.304 0.276125C21.694 -0.112875 22.327 -0.112875 22.718 0.276125C23.108 0.665125 23.108 1.29613 22.718 1.68413L2.718 21.6031ZM9.312 12.2191L13.291 8.25612C12.89 8.06512 12.453 7.95213 12 7.95213C10.346 7.95213 9 9.29312 9 10.9401C9 11.3881 9.121 11.8191 9.312 12.2191ZM12 3.96812C5.216 3.96812 2 9.56013 2 10.9391C2 11.3831 2.714 13.8581 5.843 15.6731L7.881 13.6441C7.811 13.5791 7.729 13.5311 7.678 13.4451C7.234 12.6881 7 11.8221 7 10.9401C7 8.19512 9.243 5.96112 12 5.96112C12.989 5.96112 13.94 6.26213 14.753 6.80013L16.495 5.06512C15.67 4.55713 14.243 3.96812 12 3.96812ZM11.999 15.9181C11.477 15.9181 11.037 15.5151 11.002 14.9881C10.966 14.4391 11.383 13.9651 11.935 13.9291C13.576 13.8221 14.895 12.5091 15.002 10.8741C15.038 10.3261 15.528 9.90812 16.065 9.94613C16.616 9.98112 17.034 10.4551 16.998 11.0041C16.826 13.6321 14.705 15.7441 12.065 15.9171C12.043 15.9181 12.021 15.9181 11.999 15.9181ZM9.241 17.5811C10.113 17.7991 11.042 17.9101 12 17.9101C18.784 17.9101 22 12.3191 22 10.9391C22 10.5231 21.567 9.17013 20.228 7.64712C19.864 7.23212 19.906 6.60313 20.322 6.24113C20.737 5.87913 21.369 5.92112 21.733 6.33512C23.11 7.90112 24 9.70913 24 10.9391C24 13.5401 19.795 19.9021 12 19.9021C10.877 19.9021 9.784 19.7711 8.752 19.5121C8.216 19.3781 7.892 18.8361 8.026 18.3031C8.161 17.7691 8.704 17.4461 9.241 17.5811Z'
                fill='currentColor'
              />
            </svg>
          )}
        </button>
      </div>
    )
  },
)

export default PasswordInput
