import Cookies from 'js-cookie'
import { normalize } from 'normalizr'
import mixpanel from 'services/mix-panel'

import schema from '../user/schema'

export const SET_PLAN = 'SIGNUP/SET_PLAN'
export const SAVE_USER_DETAILS = 'SIGNUP/SAVE_USER_DETAILS'
export const SAVE_SHIPPING_ADDRESS = 'SIGNUP/SAVE_SHIPPING_ADDRESS'
export const SAVE_INTELLITAGS = 'SIGNUP/SAVE_INTELLITAGS'
export const CREATE_TEMP_USER = 'SIGNUP/CREATE_TEMP_USER'
export const COMPLETE_SIGNUP = 'SIGNUP/COMPLETE_SIGNUP'
export const SET_PASSWORD = 'SIGNUP/SET_PASSWORD'

export function createTempUser(data) {
  return async (dispatch, getState, api) => {
    const signupSource = Cookies.get('signupSource')
    Cookies.remove('signupSource')

    const user = await api.signup.createTempUser({
      ...data,
      signupSource,
    })

    dispatch({
      type: SET_PLAN,
      payload: data.plan,
    })

    dispatch({
      type: CREATE_TEMP_USER,
      payload: user,
    })
  }
}

export function saveUserDetails(userDetails, shouldCapture) {
  return async (dispatch, getState, api) => {
    if (shouldCapture) {
      const state = getState()

      if (!state.signup.user || !state.signup.user.captured) {
        await api.signup.lead(userDetails)
        userDetails.captured = true
      }
    }

    dispatch({
      type: SAVE_USER_DETAILS,
      payload: userDetails,
    })
  }
}

export function saveShippingAddress(shippingAddress) {
  return (dispatch) => {
    dispatch({
      type: SAVE_SHIPPING_ADDRESS,
      payload: shippingAddress,
    })
  }
}

export function saveIntellitags(intellitags) {
  return (dispatch) => {
    dispatch({
      type: SAVE_INTELLITAGS,
      payload: intellitags,
    })
  }
}

export function completeSignup(data) {
  return async (dispatch, getState, api) => {
    const { signup } = getState()
    const { intellitags, shippingAddress, plan } = signup

    const response = await api.signup.complete({
      ...data,
      intellitags,
      shippingAddress,
    })

    const { user } = response

    mixpanel.alias(user.id)
    mixpanel.register({ plan: 'paid' })

    mixpanel.track('Signup', {
      Name: user.name,
      Email: user.email,
      'Promo code': user.promoCode,
    })

    dispatch({
      type: COMPLETE_SIGNUP,
      payload: response,
    })
  }
}

export function setPassword(data) {
  return async (dispatch, getState, api) => {
    const user = await api.signup.setPassword(data)

    mixpanel.identify(user.id)

    return dispatch({
      type: SET_PASSWORD,
      payload: normalize(user, schema),
    })
  }
}

export function createFreeSignup(data) {
  return async (dispatch, getState, api) => {
    const response = await api.signup.createFreeSignup(data)

    const { user } = response
    mixpanel.register({ plan: 'free' })
    mixpanel.track('Free Signup', {
      Name: user.name,
      Email: user.email,
    })

    dispatch({
      type: COMPLETE_SIGNUP,
      payload: response,
    })
  }
}
