import { schema } from 'normalizr'
import { isToday } from 'date-fns'

export default new schema.Entity(
  'alarms',
  {},
  {
    processStrategy: (entity) => {
      const startTime = new Date(entity.startTime)
      const { alarmEvents = [] } = entity
      return {
        ...entity,
        alarmEvents: alarmEvents.map((alarmEvent) => {
          return {
            ...alarmEvent,
            createdAt: new Date(alarmEvent.createdAt),
          }
        }),
        isToday: isToday(startTime),
        startTime,
        isFiring: entity.status === 'firing',
        isExpired: entity.status === 'expired',
        isComplete:
          entity.status === 'complete' ||
          entity.status === 'completeUnconfirmed',
      }
    },
  },
)
