import React from 'react'
import classNames from 'classnames'

import style from './style.module.css'

type Props = React.HTMLProps<HTMLSelectElement>

const Select = React.forwardRef<HTMLSelectElement, Props>(
  ({ className, ...rest }: Props, ref) => {
    return (
      <select
        className={classNames(style.wrapper, className)}
        {...rest}
        ref={ref}
      />
    )
  },
)

export default Select
