import React from 'react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

import { FULL_DATE_AND_TIME } from 'constants/dateFormats'
import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import Loader from 'components/Loader'
import Text from 'components/Text'
import { Button } from 'components/Forms'

import style from './style.module.scss'

TasksScreen.propTypes = {
  onToggleDone: PropTypes.func,
  isLoading: PropTypes.bool,
  task: PropTypes.object,
}

function TasksScreen(props) {
  const { onToggleDone, task, isLoading } = props

  if (isLoading) {
    return <Loader />
  }

  const { name, instructions, startTime, isDone, isDueInFuture } = task

  return (
    <Layout showNav={false}>
      <HeaderOne>{name}</HeaderOne>
      {instructions && (
        <React.Fragment>
          <Text className={style.text} variant='strong'>
            Instructions
          </Text>
          <Text className={style.text}>{instructions}</Text>
        </React.Fragment>
      )}
      {startTime && (
        <React.Fragment>
          <Text className={style.text} variant='strong'>
            Due Date
          </Text>
          <Text className={style.text}>
            {format(startTime, ` ${FULL_DATE_AND_TIME}`)}
          </Text>
        </React.Fragment>
      )}

      {isDone ? (
        <Button
          onClick={onToggleDone}
          variant='hollow'
          className={style.button}
        >
          Mark as incomplete
        </Button>
      ) : (
        <Button
          onClick={onToggleDone}
          className={style.button}
          disabled={isDueInFuture}
        >
          Mark as complete
        </Button>
      )}

      {isDueInFuture && (
        <p className={style.futureNote}>
          You can mark this task complete up to 1 hour before it is due.
        </p>
      )}
    </Layout>
  )
}
export default TasksScreen
