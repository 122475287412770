import React from 'react'
import classNames from 'classnames'

import style from './style.module.scss'
import { placeholder } from 'sequelize/types/lib/operators'

type Option = {
  value: string
  label: string
}

type Props = {
  onChange?: (formField: FormField) => void
  className?: string
  value?: string
  id?: string
  name?: string
  options: Option[]
  placeholder?: string
}

function Dropdown(props: Props) {
  const {
    onChange,
    className,
    options,
    value = '',
    placeholder,
    ...rest
  } = props

  function handleChange(event: React.FormEvent<HTMLSelectElement>) {
    const { name, value } = event.currentTarget

    if (onChange) {
      onChange({ name, value })
    }
  }

  return (
    <select
      className={classNames(style.wrapper, className)}
      onChange={handleChange}
      value={value}
      {...rest}
    >
      <option value='' disabled>
        {placeholder}
      </option>
      {options.map((option) => {
        const { value, label } = option

        return (
          <option key={value} value={value}>
            {label}
          </option>
        )
      })}
    </select>
  )
}

export default Dropdown
