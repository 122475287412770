import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Layout from 'components/Layout/SplitLayout'
import HeaderOne from 'components/Headers/HeaderOne'
import { ErrorText } from 'components/Forms'
import Loader from 'components/Loader'

VerifyAccountScreen.propTypes = {
  isProcessing: PropTypes.bool,
  isVerified: PropTypes.bool,
  error: PropTypes.string,
}

function VerifyAccountScreen(props) {
  const { isProcessing, isVerified, error } = props

  if (isVerified) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { notice: 'Account verified' },
        }}
      />
    )
  }

  return (
    <Layout>
      <HeaderOne>Verify Account</HeaderOne>
      {error && <ErrorText>{error}</ErrorText>}
      {isProcessing && <Loader />}
    </Layout>
  )
}
export default VerifyAccountScreen
