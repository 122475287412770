import React from 'react'
import PropTypes from 'prop-types'

import api from 'services/pet-notify-api'

import VaultAccessScreen from 'ContactApp/screens/Vault/Access'

class VaultAccessScreenContainer extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
      }),
    }),
  }

  state = { isLoading: true, isProcessing: false, didDecline: false }

  componentDidMount() {
    this.fetch()
  }

  fetch = async () => {
    const { match } = this.props
    const { params } = match
    const { token } = params

    try {
      const { user, contact, vaultAccess } =
        await api.alarmContactRequests.fetchOne(token)
      this.setState({
        isLoading: false,
        user,
        contact,
        vaultAccess: {
          ...vaultAccess,
          accessExpires: new Date(vaultAccess.accessExpires),
        },
      })
    } catch (error) {
      this.setState({ isLoading: false, error })
    }
  }

  handleSubmit = async (data) => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { accessPin } = data

    this.setState({ isProcessing: true })

    try {
      await api.vaults.fetchOne(id, accessPin)

      this.setState({ isProcessing: false, accessPin })
    } catch (error) {
      this.setState({ isProcessing: false })
      throw error
    }
  }

  handleResendAccessPin = async (data) => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      await api.alarmContactRequests.resendAccessPin(token)
      this.setState({ isLoading: false, didResendAccessPin: true })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  handleCancel = async () => {
    const { match } = this.props
    const { params } = match
    const { token } = params
    this.setState({ isLoading: true })

    try {
      await api.alarmContactRequests.declined(token)
      this.setState({ didDecline: true })
    } catch (error) {
      this.setState({ isLoading: false })
      throw error
    }
  }

  generateProps = () => {
    const { match } = this.props
    const { params } = match
    const { token, id } = params
    return {
      ...this.props,
      ...this.state,
      token,
      id,
      onSubmit: this.handleSubmit,
      onResendAccessPin: this.handleResendAccessPin,
      onCancel: this.handleCancel,
    }
  }

  render() {
    const props = this.generateProps()

    return <VaultAccessScreen {...props} />
  }
}

export default VaultAccessScreenContainer
