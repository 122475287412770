import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.scss'

type Props = {
  children: ReactNode
}

function FieldGroup(props: props) {
  const { children } = props

  return <div className={style.wrapper}>{children}</div>
}

export default FieldGroup
