import React, { useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { applyPromoCode } from 'UserApp/store/promoCode/actions'
import Form from 'sharedComponents/Form'
import Input from 'sharedComponents/Input'
import Button from 'sharedComponents/Button'
import Error from 'sharedComponents/Error'
import Loader from 'sharedComponents/Loader'

import style from './style.module.scss'

function PromoCodeForm(props) {
  const { size = 'regular' } = props
  const [isLoading, setIsLoading] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch()

  async function handleSubmit() {
    setIsLoading(true)

    try {
      await dispatch(applyPromoCode(promoCode))
    } catch (e) {
      setError(e.message)
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={classNames(style.wrapper, style[size])}>
      <div className={style.fields}>
        <Input
          name='promoCode'
          onChange={({ value }) => setPromoCode(value)}
          value={promoCode}
          className={style.input}
        />
        <Button
          type='button'
          className={style.button}
          disabled={!promoCode}
          onClick={handleSubmit}
        >
          Apply
        </Button>
      </div>
      {error && <Error>{error}</Error>}
    </div>
  )
}

export default PromoCodeForm
