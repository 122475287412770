import PropTypes from 'prop-types'

export const PetType = {
  id: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
}

export const ContactType = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
}

export const UserType = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
}

export const AlarmType = {
  id: PropTypes.number,
  status: PropTypes.string,
  startTime: PropTypes.date,
}

export const SubscriptionPlanType = {
  id: PropTypes.string,
  nickname: PropTypes.string,
}

export const TaskType = {
  id: PropTypes.number,
  title: PropTypes.string,
}

export const UserAddressType = {
  address: PropTypes.string,
  city: PropTypes.string,
}

export const DocumentType = {
  id: PropTypes.string,
  name: PropTypes.string,
}

export const PlanType = {
  id: PropTypes.string,
  interval: PropTypes.string,
  amount: PropTypes.string,
  discount: PropTypes.string,
}

export const PromoCodeType = {
  promoCode: PropTypes.string,
}
